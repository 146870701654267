import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image_view = _resolveComponent("image-view")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_m_content = _resolveComponent("m-content")!
  const _component_r_content = _resolveComponent("r-content")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, {
    class: "inner-content",
    gutter: 20
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 16 }, {
        default: _withCtx(() => [
          _createVNode(_component_image_view, {
            ref: "image",
            src: _ctx.state.innersrc || _ctx.src,
            rotate: _ctx.state.angel,
            "onUpdate:rotate": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.angel) = $event)),
            "can-download": "",
            style: _normalizeStyle({
          paddingLeft: 0,
          paddingTop: 0,
          paddingRight: 0,
          height: _ctx.leftHeight,
        })
          }, null, 8, ["src", "rotate", "style"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 8 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_scrollbar, { height: _ctx.rightHeight }, {
            default: _withCtx(() => [
              (!_ctx.readOnly)
                ? (_openBlock(), _createBlock(_component_m_content, {
                    key: 0,
                    ref: "content",
                    lock: _ctx.lock,
                    onFilechange: _ctx.onFilechange,
                    data: _ctx.data,
                    "mark-edit": !_ctx.isNew,
                    rule: _ctx.rule
                  }, null, 8, ["lock", "onFilechange", "data", "mark-edit", "rule"]))
                : _createCommentVNode("", true),
              (_ctx.readOnly)
                ? (_openBlock(), _createBlock(_component_r_content, {
                    key: 1,
                    ref: "content",
                    data: _ctx.readData,
                    rule: _ctx.rule
                  }, null, 8, ["data", "rule"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["height"]),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}