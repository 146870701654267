
import * as echarts from "echarts";
import { defineComponent, reactive, ref, watch, onMounted } from "vue";
// import ModifyBusinesslicenseInnerContent from "@/components/ModifyBusinessLicense/InnerContent.vue";
import ModifyBusinesslicenseInnerContent from "@/components/ModifyBusinessLicense/InnerContent.vue";
import { getNewFormData } from "@/components/ModifyBusinessLicense/utils";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import { AnyObject, toNum, getSlice, isAbsHttp } from "@/utils";
import { fundMap } from "./FinancialIncentive/utils";
import { rsHost } from "@/api/utils";
import {
  ipark_servicepark_enterpriseTaxDetail,
  servicepark_enterpriseRegistInfo,
  servicepark_enterprisefinancialStatementsList,
  ipark_servicepark_enterpriseTaxInfo,
  ipark_servicepark_queryTaxpayerRewardDetailByBatch,
  ipark_servicepark_enterpriseRewardInfo,
} from "@/api/index";

export default defineComponent({
  components: {
    ModifyBusinesslicenseInnerContent,
    // ModifyBusinesslicenseInnerContent,
    DataTable,
    Page,
  },
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    getSummaries() {
      const sum: string[] = [
        this.state.sumRow.title,
        this.state.sumRow.rewardAmount,
      ];
      this.state.evenNumbers.forEach((val) => {
        sum.push(this.state.sumRow[val + "Tax"]);
        sum.push(this.state.sumRow[val + "Reward"]);
      });
      return sum;
    },
  },
  setup(props, { expose, emit }) {
    const TableEnterprisefinancialStatements = ref<typeof DataTable | null>(
      null
    );
    const TableEnterpriseTaxInfo = ref<typeof DataTable | null>(null);
    // let businesslicenseInner = ref<
    //   typeof ModifyBusinesslicenseInnerContent | null
    // >(null);
    const tableFourth = ref<typeof DataTable | null>(null);
    const state = reactive({
      // getDataThirdData: [] as AnyObject[],
      lastest: 0,
      getDataDialog: [] as AnyObject[],
      evenNumbers: [] as string[],
      elChartTable4: {},
      dialogTitle: "",
      dialogVisible: false,
      dialogTableVisible: false,
      fourthShow: props.params.fourthShow,
      loading4: false,
      title: "",
      activeName: "first",
      form: getNewFormData(),
      pdfListVisible: false,
      pdfTitle: "",
      pdfLists: {},
      sumRow: {} as AnyObject,
    });
    const getEchart3 = (dataEchart: any, elChartName: any) => {
      const data = dataEchart;
      const option = {
        grid: {
          containLabel: true,
          top: 40,
          left: 10,
          right: 10,
          bottom: 50,
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: data.taxRevenueList.map(
            (val: { taxPeriod: any }) => val.taxPeriod
          ),
          axisLabel: {
            showMaxLabel: true,
          },
        },
        yAxis: [
          {
            type: "value",
            name: "当月（万元）",
          },
          {
            type: "value",
            name: "累计（万元）",
          },
        ],
        legend: {
          data: [
            data.lastYear + "年",
            data.currentYear + "年",
            "上年累计",
            "本年累计",
          ],
          bottom: 10,
        },
        series: [
          {
            name: data.lastYear + "年",
            type: "bar",
            barWidth: "40%",
            data: data.taxRevenueList.map(
              (val: { lastYearMonthTax: string | number }) =>
                toNum(val.lastYearMonthTax)
            ),
            itemStyle: {
              color: "#C4CCD3",
            },
          },
          {
            name: data.currentYear + "年",
            type: "bar",
            barWidth: "40%",
            data: data.taxRevenueList.map(
              (val: { currentYearMonthTax: string | number }) =>
                toNum(val.currentYearMonthTax)
            ),
            itemStyle: {
              color: "#5470C6",
            },
          },
          {
            name: "上年累计",
            yAxisIndex: 1,
            data: data.taxRevenueList.map(
              (val: { lastYearTotalTax: string | number }) =>
                toNum(val.lastYearTotalTax)
            ),
            type: "line",
            itemStyle: {
              color: "#AEC2D5",
            },
          },
          {
            name: "本年累计",
            yAxisIndex: 1,
            data: data.taxRevenueList.map(
              (val: { currentYearTotalTax: string | number }) =>
                toNum(val.currentYearTotalTax)
            ),
            type: "line",
            itemStyle: {
              color: "#FBD785",
            },
          },
        ],
      };
      echarts.init(elChartName).setOption(option);
    };
    // 弹框切换
    const handleClick = () => {
      switch (state.activeName) {
        case "first":
          // 获取工商登记信息
          servicepark_enterpriseRegistInfo({
            taxpayerId: props.params.information.taxpayerId,
          }).then((row) => {
            if (row.data != null) {
              state.form = getNewFormData({
                enterprisefile: isAbsHttp(row.data.licenseFilestore)
                  ? row.data.licenseFilestore
                  : rsHost + row.data.licenseFilestore,
                enterprisename: row.data.taxpayerName,
                enterprisecode: row.data.taxpayerId,
                enterprisetype: row.data.enterpriseTypeCode,
                enterprisebusiness: row.data.industryCode,
                enterprisescope: row.data.activitiesScope,
                enterpriseaddress: row.data.registAddress,
                enterpriseregistrationDate: row.data.registionDate,
                establishmentDate: row.data.establishmentDate,
                legalpersonName: row.data.legalRepresentativeName,
                legalpersonNo: row.data.legalRepresentativeIdNo,
                legalpersonMobile: row.data.legalRepresentativeMobile,
                contactsName: row.data.enterpriseLiaisonName,
                contactsMobile: row.data.enterpriseLiaisonMobile,
                attractpartner: row.data.partnerId,
                parkaccesser: row.data.serviceParkLiaisonMobile,
              });
            }
          });
          break;
        case "second":
          TableEnterprisefinancialStatements.value?.reset();
          break;
        case "third":
          TableEnterpriseTaxInfo.value?.reset();
          break;
        case "fourth":
          state.loading4 = true;
          tableFourth.value?.reset();
          break;
        default:
          break;
      }
    };
    const getData2 = (param: Iparam) => {
      if (state.activeName == "second") {
        return servicepark_enterprisefinancialStatementsList({
          taxpayerId: props.params.information.taxpayerId,
          page: param.index,
          per_page: param.size,
        }).then((obj) => {
          return {
            data: obj.data.rows,
            total: obj.data.totalRows,
          };
        });
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    };
    const getData3 = (param: Iparam) => {
      state.lastest = param.index;
      const elChart3 = document.getElementById("companyDetailChart");
      const tableScrollTop = TableEnterpriseTaxInfo.value?.tableRef;
      if (state.activeName == "third") {
        tableScrollTop.$refs.bodyWrapper.scrollTop = 0;
        return ipark_servicepark_enterpriseTaxInfo({
          taxpayerId: props.params.information.taxpayerId,
          page: param.index,
          per_page: param.size,
        }).then((obj) => {
          state.elChartTable4 = obj.data.chat;
          if (!elChart3) return;
          getEchart3(state.elChartTable4, elChart3);
          return getSlice(param.index, param.size, obj.data.rows);
        });
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    };
    const getData4 = (param: Iparam) => {
      const elChart4 = document.getElementById("mychart01");
      if (state.activeName == "fourth") {
        if (!elChart4) return;
        ipark_servicepark_enterpriseRewardInfo({
          taxpayerId: props.params.information.taxpayerId,
        }).then((obj) => {
          echarts.init(elChart4).setOption(lineOption(obj.data.chat));
        });
        return ipark_servicepark_queryTaxpayerRewardDetailByBatch({
          taxpayerId: props.params.information.taxpayerId,
          page: param.index,
          per_page: param.size,
        }).then((obj) => {
          state.loading4 = false;
          state.evenNumbers = obj.data.showColumns;
          state.sumRow = obj.data.sumRow || {};
          return {
            data: obj.data.rows,
            total: obj.data.totalRows,
          };
        });
      } else {
        state.loading4 = false;
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    };
    // canvas图表通用设置
    const lineOption = (data: any[]) => {
      data = data.reverse();
      return {
        title: {
          text: "企业返税分批次统计（近十二期数据）",
          left: "center",
          textStyle: {
            fontSize: 14,
          },
          padding: [20, 40],
        },
        grid: {
          containLabel: true,
          top: 80,
          left: 20,
          right: 20,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        xAxis: [
          {
            type: "category",
            data: data.map(
              (val: { taxPeriodRange: string }) => val.taxPeriodRange
            ),
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
          },
          {
            type: "value",
            name: "单位：万元",
            offset: -20,
          },
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            barWidth: "60%",
            data: data.map((val: { rewardAmount: string | number }) =>
              toNum(val.rewardAmount)
            ),
          },
        ],
      };
    };
    const openPDF = (row: any) => {
      if (row.statements.length > 1) {
        state.pdfTitle = row.period + "财报";
        state.pdfLists = row.statements;
        state.pdfListVisible = true;
      } else {
        window.open(row.statements[0].filestore, "location=no, status=no");
      }
    };
    const openPDFOnly = (obj: any) => {
      window.open(obj.filestore, "location=no, status=no");
    };
    const todo = (row: { taxPeriodStr: string; taxPeriod: any }) => {
      state.dialogVisible = true;
      state.dialogTitle = props.title + " / " + row.taxPeriodStr + "缴税详情";
      ipark_servicepark_enterpriseTaxDetail({
        taxpayerId: props.params.information.taxpayerId,
        taxPeriod: row.taxPeriod,
      }).then((obj) => {
        state.getDataDialog = obj.data.rows || [];
      });
    };
    watch(
      () => props,
      () => {
        state.form = {
          ...state.form,
        };
      }
    );
    onMounted(() => {
      // 获取工商登记信息
      servicepark_enterpriseRegistInfo({
        taxpayerId: props.params.information.taxpayerId,
      }).then((row) => {
        if (row.data != null) {
          state.form = getNewFormData({
            enterprisefile: isAbsHttp(row.data.licenseFilestore)
              ? row.data.licenseFilestore
              : rsHost + row.data.licenseFilestore,
            enterprisename: row.data.taxpayerName,
            enterprisecode: row.data.taxpayerId,
            enterprisetype: row.data.enterpriseTypeCode,
            enterprisebusiness: row.data.industryCode,
            enterprisescope: row.data.activitiesScope,
            enterpriseaddress: row.data.registAddress,
            enterpriseregistrationDate: row.data.registionDate,
            establishmentDate: row.data.establishmentDate,
            legalpersonName: row.data.legalRepresentativeName,
            legalpersonNo: row.data.legalRepresentativeIdNo,
            legalpersonMobile: row.data.legalRepresentativeMobile,
            contactsName: row.data.enterpriseLiaisonName,
            contactsMobile: row.data.enterpriseLiaisonMobile,
            attractpartner: row.data.partnerId,
            parkaccesser: row.data.serviceParkLiaisonMobile,
          });
        }
      });
      window.addEventListener("resize", () => {
        const elChart3 = document.getElementById("companyDetailChart");
        const elChart4 = document.getElementById("mychart01");
        switch (state.activeName) {
          case "third":
            if (!elChart3) return;
            echarts.init(elChart3).resize();
            break;
          case "fourth":
            if (!elChart4) return;
            echarts.init(elChart4).resize();
            break;
          default:
            break;
        }
      });
    });
    return {
      state,
      fundMap,
      tableFourth,
      // businesslicenseInner,
      TableEnterprisefinancialStatements,
      TableEnterpriseTaxInfo,
      todo,
      getData4,
      lineOption,
      handleClick,
      getData2,
      getData3,
      openPDFOnly,
      openPDF,
    };
  },
});
