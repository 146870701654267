
import { defineComponent, ref, PropType, watchEffect } from "vue";
import Layer from "../Layer/Index.vue";
import Content from "./Content.vue";

// interface Iprops {
//   size?: [number, number];
//   show?: boolean;
// }
// const defaultProps = { size: [0, 0], show: false };
export default defineComponent({
  components: {
    Layer,
    ImageContent: Content,
  },
  emits: ["update:modelValue"],
  props: {
    size: {
      type: Array as PropType<string[]>,
      default: () => ["800px", "500px"],
    },
    download: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const content = ref<typeof Content | null>(null);
    const show = ref(props.modelValue);
    const rotate = ref(0);
    watchEffect(() => {
      emit("update:modelValue", show);
    });
    return {
      show,
      rotate,
      content,
      onResize() {
        content.value?.resize();
      },
    };
  },
});
