import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  NavigationGuard,
} from "vue-router";
import Home from "../views/Home.vue";
// import ForgotPassword from "../views/ForgotPassword.vue";
import Index from "../Index.vue";
import Content from "../Content.vue";
import Login from "../views/Login.vue";
import Bind from "../views/Bind.vue";
import Validate from "../views/Validate.vue";
import Page401 from "../views/401.vue";
import Page404 from "../views/404.vue";
import Page500 from "../views/500.vue";
import Demo from "../views/Demo.vue";
import industrialParkBrochure from "../views/attractInvestment/industrialParkBrochure.vue";
import industrialParkBrochureDetail from "../views/attractInvestment/industrialParkBrochureDetail/index.vue";
import enterpriseListForPark from "../views/statistics/enterpriseListForPark.vue";
import taxListForPark from "../views/statistics/taxListForPark.vue";
import financeDataQuery from "../views/statistics/financeDataQuery.vue";
import enterpriseRewardListForPark from "../views/statistics/enterpriseRewardListForPark.vue";
import setting from "../views/set/set-setting.vue";
import partnerRewardListForPark from "../views/statistics/partnerRewardListForPark.vue";
import LoginOrg from "../views/LoginOrg.vue";
import Register from "../views/FinanceTax/Register.vue";
import FinanceUpload from "../views/FinanceTax/FinanceUpload.vue";
import FinanceUploadDetail from "../views/FinanceTax/FinanceUploadDetail.vue";
import PaymentUpload from "../views/FinanceTax/PaymentUpload.vue";
import PaymentUploadDetail from "../views/FinanceTax/PaymentUploadDetail.vue";
import Migrate from "../views/FinanceTax/Migrate.vue";
import Writeoff from "../views/FinanceTax/Writeoff.vue";
import NaturalRegistion from "../views/FinanceTax/naturalRegistion.vue";
import Audit from "../views/FinancialIncentive/Audit.vue";
import AuditDetail from "../views/FinancialIncentive/AuditDetail.vue";
import UploadEnterprisedata from "../views/FinancialIncentive/UploadEnterprisedata.vue";
import UploadIncentivedata from "../views/FinancialIncentive/UploadIncentivedata.vue";
import enterprisedataDetail from "../views/FinancialIncentive/enterprisedataDetail.vue";
import incentivedataDetail from "../views/FinancialIncentive/incentivedataDetail.vue";
import EnterprisePayment from "../views/FinancialIncentive/EnterprisePayment.vue";
import EnterprisePaymentDetail from "../views/FinancialIncentive/EnterprisePaymentDetail.vue";
import investmentPartners from "../views/attractInvestment/investmentPartners.vue";
import serviceHotline from "../views/attractInvestment/serviceHotline.vue";
import { AnyObject } from "@/utils";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Index",
    component: Index,
    children: [
      {
        path: "",
        component: Content,
        children: [
          {
            path: "/",
            component: Home,
          },
          {
            path: "/industrialParkBrochure",
            component: industrialParkBrochure,
          },
          {
            path: "/industrialParkBrochure-detail",
            component: industrialParkBrochureDetail,
          },
          // {
          //   path: "/demo",
          //   component: Demo,
          // },
          {
            path: "/financetax-register",
            component: Register,
          },
          {
            path: "/financetax-financeUpload",
            component: FinanceUpload,
          },
          {
            path: "/financetax-financeUpload-detail",
            component: FinanceUploadDetail,
          },
          {
            path: "/financetax-paymentUpload",
            component: PaymentUpload,
          },
          {
            path: "/financetax-paymentUpload-detail",
            component: PaymentUploadDetail,
          },
          {
            path: "/financetax-migrate",
            component: Migrate,
          },
          {
            path: "/financetax-writeoff",
            component: Writeoff,
          },
          {
            path: "/financetax-naturalRegistion",
            component: NaturalRegistion,
          },
          {
            path: "/financialincentive-audit",
            component: Audit,
          },
          {
            path: "/financialincentive-auditdetail",
            component: AuditDetail,
          },
          {
            path: "/financialincentive-uploadenterprisedata",
            component: UploadEnterprisedata,
          },
          {
            path: "/financialincentive-enterprisedatadetail",
            component: enterprisedataDetail,
          },
          {
            path: "/financialincentive-uploadincentivedata",
            component: UploadIncentivedata,
          },
          {
            path: "/financialincentive-incentivedatadetail",
            component: incentivedataDetail,
          },
          {
            path: "/financialincentive-enterprisepayment",
            component: EnterprisePayment,
          },
          {
            path: "/financialincentive-enterprisepaymentdetail",
            component: EnterprisePaymentDetail,
          },
          {
            path: "/enterpriseListForPark",
            component: enterpriseListForPark,
          },
          {
            path: "/taxListForPark",
            component: taxListForPark,
          },
          {
            path: "/financeDataQuery",
            component: financeDataQuery,
          },
          {
            path: "/enterpriseRewardListForPark",
            component: enterpriseRewardListForPark,
          },
          {
            path: "/partnerRewardListForPark",
            component: partnerRewardListForPark,
          },
          {
            path: "/investment-partners",
            component: investmentPartners,
          },
          {
            path: "/service-hotline",
            component: serviceHotline,
          },
          {
            path: "/set-setting",
            component: setting,
          },
        ],
      },
    ],
  },
  // {
  //   path: "/forgot-password",
  //   name: "ForgotPassword",
  //   component: ForgotPassword,
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/login-org",
    name: "LoginOrg",
    component: LoginOrg,
  },
  {
    path: "/bind",
    name: "Bind",
    component: Bind,
  },
  {
    path: "/validate",
    name: "Validate",
    component: Validate,
  },
  {
    path: "/401",
    name: "Page401",
    component: Page401,
  },
  {
    path: "/500",
    name: "Page500",
    component: Page500,
  },
  {
    path: "/404",
    name: "Page404",
    component: Page404,
  },
  {
    path: "/:catchAll(.*)",
    component: Page404,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


router.beforeEach((to, from /*, next */) => {
  if (
    "/login;/bind;/validate;/404;".indexOf(to.path + ";") == -1 &&
    !sessionStorage.token
  ) {
    return { name: "Login" };
  } else if (
    process.env.NODE_ENV !== "development" &&
    sessionStorage.withpathmenus
  ) {
    let pathstr = "/login;/login-org;/bind;/validate;/404;/401;/500;";
    if (!sessionStorage._withpathmenus) {
      const tmp = JSON.parse(sessionStorage.withpathmenus)
        .map((val: AnyObject) => val.path)
        .filter((val: string) => val);
      if (tmp.indexOf("/industrialParkBrochure") != -1)
        tmp.push("/industrialParkBrochure-detail");
      if (tmp.indexOf("/financetax-financeUpload") != -1)
        tmp.push("/financetax-financeUpload-detail");
      if (tmp.indexOf("/financetax-paymentUpload") != -1)
        tmp.push("/financetax-paymentUpload-detail");
      if (tmp.indexOf("/financialincentive-audit") != -1)
        tmp.push("/financialincentive-auditdetail");
      if (tmp.indexOf("/financialincentive-uploadenterprisedata") != -1)
        tmp.push("/financialincentive-enterprisedatadetail");
      if (tmp.indexOf("/financialincentive-uploadincentivedata") != -1)
        tmp.push("/financialincentive-incentivedatadetail");
      if (tmp.indexOf("/financialincentive-enterprisepayment") != -1)
        tmp.push("/financialincentive-enterprisepaymentdetail");
      sessionStorage._withpathmenus = tmp.join(";");
    }
    pathstr += sessionStorage._withpathmenus + ";";

    if (pathstr.indexOf(to.path + ";") === -1) {
      return {
        name: "Page401",
      };
    } else {
      return true;
    }
  } else {
    return true;
  }
});

export default router;
