import Axios, { ResponseType } from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import { setCache, getCache, delCache, AnyObject, AnyFunction } from "@/utils";

const host = process.env.VUE_APP_API,
  websocketHost = process.env.VUE_APP_WSHOST,
  rsHost = process.env.VUE_APP_RSHOST;

console.log(`==============${process.env.NODE_ENV}==============`);
if (process.env.NODE_ENV !== "production") {
  console.table({
    host,
    websocketHost,
    rsHost,
  });
  // if (!sessionStorage.token) {
  //   sessionStorage.token = "1ed974fe127c4c6eb2bfe2be89bee023";
  // }
}
const axios = Axios.create({
  baseURL: host,
  timeout: 60 * 1000,
});

axios.interceptors.request.use(
  function (config) {
    if (config.method && /^post$/i.test(config.method)) {
      if (config.data && !(config.data instanceof FormData)) {
        const params = new URLSearchParams();
        for (const key in config.data) {
          params.append(key, config.data[key]);
        }
        config.data = params;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  function (config) {
    if (!config.method || /^get$/i.test(config.method)) {
      if (config.params) {
        if (config.params.__notoken__) {
          config.params.token = sessionStorage.token;
        }
        delete config.params.__notoken__;
      } else {
        config.params = { token: sessionStorage.token };
      }
    } else if (/^post$/i.test(config.method)) {
      if (config.data) {
        if (config.data instanceof FormData) {
          if (!config.data.get("__notoken__")) {
            config.data.append("token", sessionStorage.token);
          }
          config.data.delete("__notoken__");
        } else {
          if (!config.data.__notoken__) {
            config.data.token = sessionStorage.token;
          }
          delete config.data.__notoken__;
        }
      } else {
        config.data = { token: sessionStorage.token };
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export interface IRes {
  code: string;
  msg: string;
  data: any;
}
axios.interceptors.response.use<any>(
  function (response) {
    const { data, config, status, statusText, headers } = response;
    // SUCCESS
    // TIMEOUT
    // UNAUTHORIZED
    // ERROR
    if (status !== 200) {
      return Promise.reject(statusText);
    }
    if (config.responseType !== "json") {
      const disposition = headers["content-disposition"];
      return disposition
        ? {
            code: "SUCCESS",
            data,
            msg: decodeURIComponent(disposition).split(
              "attachment;filename="
            )[1],
          }
        : Promise.reject("下载出错了~");
    }

    const { rspCode, rspForwardUri, rspMsg, rspData, rspRedirectUrl } = data;
    if (rspCode !== "SUCCESS") {
      return Promise.reject(rspMsg);
    }
    return {
      code: rspCode,
      msg: rspMsg,
      data: rspData || rspRedirectUrl || rspForwardUri,
    };
  },
  function (error) {
    return Promise.reject(error);
  }
);

export function requestInfo(url: string, data?: AnyObject): Promise<IRes> {
  let showErrorAlert = true;
  if (data) {
    if (data._noShowError_) {
      showErrorAlert = false;
      delete data._noShowError_;
    }
  }
  const request = axios.get<AnyObject, IRes>(url, {
    params: data,
  });
  if (showErrorAlert) {
    request.catch((err) => {
      ElMessage({
        showClose: true,
        message: typeof err === "string" ? err : err.message,
        type: "error",
      });
      return Promise.reject(err);
    });
  }
  return request;
}

let _hasAlert = false;
export function postForm(
  url: string,
  data?: AnyObject,
  responseType: ResponseType = "json"
): Promise<IRes> {
  let showErrorAlert = true;
  if (data) {
    if (data instanceof FormData) {
      if (data.get("_noShowError_")) {
        showErrorAlert = false;
        data.delete("_noShowError_");
      }
    } else {
      if (data._noShowError_) {
        showErrorAlert = false;
        delete data._noShowError_;
      }
    }
  }
  const request = axios.post<AnyObject, IRes>(url, data, { responseType });
  if (showErrorAlert) {
    request.catch((err) => {
      return new Promise((reslove, reject) => {
        if (_hasAlert) {
          reject(err);
        } else {
          ElMessageBox.alert(
            typeof err === "string" ? err : err.message,
            "出错",
            {
              type: "error",
              confirmButtonText: "知道了",
              dangerouslyUseHTMLString: true,
              callback: () => {
                _hasAlert = false;
                if ("请重新登录" === err || "用户未登录" === err) {
                  delete sessionStorage.token;
                  window.dispatchEvent(new Event("relogin"));
                }
                reject(err);
              },
            }
          );
          _hasAlert = true;
        }
      });
    });
  }
  return request;
}

export function upload(
  url: string,
  data?: AnyObject,
  responseType: ResponseType = "json"
): Promise<IRes> {
  const fd = new FormData();
  data = data || {};
  for (const key in data) {
    fd.append(key, data[key]);
  }
  return postForm(url, fd, responseType);
}

const cache: AnyObject = {};
export function URLcache_post(url: string, data?: AnyObject): Promise<IRes> {
  let refresh = false;
  if (data && data.__refresh) {
    refresh = true;
    delete data.__refresh;
  }
  const res: IRes = getCache(url);
  if (!refresh && res) {
    return Promise.resolve(res);
  } else {
    return postForm(url, data).then((res) => {
      setCache(url, res);
      cache[url] = true;
      return res;
    });
  }
}
export function clearURLcache(): void {
  for (const key in cache) {
    delCache(key);
  }
}

export function getPercentage(
  onOpen: AnyFunction<Promise<IRes | void>>,
  onChange: AnyFunction<void>
): void {
  const ws = new WebSocket(
    websocketHost + "/ipark/webmsg.do?token=" + sessionStorage.token
  );
  ws.addEventListener("error", function () {
    onChange({
      type: "status",
      msg: "error",
    });
  });

  ws.addEventListener("message", function (event) {
    if (event.data === "success") {
      ws.close();
      return onChange({
        type: "status",
        msg: "success",
      });
    }
    onChange({
      type: "percentage",
      msg: parseInt(event.data),
    });
    setTimeout(() => {
      ws.send("percentage");
    }, 100);
  });
  ws.addEventListener("open", function () {
    onOpen(ws);
    ws.send("percentage");
  });
}

export { axios, Axios, rsHost, host, websocketHost };
