import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a92aec60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "industrialPark-brochure-detail-projcectionscreen" }
const _hoisted_2 = { class: "industrialPark-brochure-detail-projcectionscreen__preview" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { style: {"margin":"0 10px","height":"100%","overflow":"auto","min-width":"500px"} }
const _hoisted_5 = ["tabindex"]
const _hoisted_6 = { style: {"height":"100%","display":"flex","align-items":"center"} }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "el-upload-list__item-actions" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "el-upload-list__item-move" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "el-upload el-upload--picture-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_zoom_in = _resolveComponent("zoom-in")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_rank = _resolveComponent("rank")!
  const _component_delete = _resolveComponent("delete")!
  const _component_plus = _resolveComponent("plus")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_layout = _resolveComponent("layout")!
  const _component_image_view = _resolveComponent("image-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_layout, null, {
      left: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_carousel, {
            trigger: "click",
            height: "300px",
            "indicator-position": "none"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, (itm, idx) => {
                return (_openBlock(), _createBlock(_component_el_carousel_item, {
                  key: idx + '_key'
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: itm.url,
                      style: {"width":"100%"}
                    }, null, 8, _hoisted_3)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ])
      ]),
      right: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_draggable, _mergeProps({
            class: "list-group el-upload-list el-upload-list--picture-card",
            tag: "ul",
            modelValue: _ctx.fileList,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fileList) = $event))
          }, _ctx.dragOptions, {
            onStart: _cache[2] || (_cache[2] = ($event: any) => (_ctx.state.drag = true)),
            onEnd: _cache[3] || (_cache[3] = ($event: any) => (_ctx.state.drag = false)),
            handle: ".el-upload-list__item-move",
            "item-key": "order"
          }), {
            item: _withCtx(({ element, index }) => [
              (_openBlock(), _createElementBlock("li", {
                key: 'key_' + index,
                class: "list-group-item el-upload-list__item is-success",
                tabindex: index
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("img", {
                    class: "el-upload-list__item-thumbnail",
                    src: element.url,
                    style: {"height":"auto"},
                    alt: ""
                  }, null, 8, _hoisted_7),
                  _createElementVNode("span", _hoisted_8, [
                    _createElementVNode("span", {
                      class: "el-upload-list__item-preview",
                      onClick: ($event: any) => (_ctx.handlePictureCardPreview(element.url))
                    }, [
                      _createVNode(_component_el_icon, { size: 88 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_zoom_in)
                        ]),
                        _: 1
                      })
                    ], 8, _hoisted_9),
                    _createElementVNode("span", _hoisted_10, [
                      _createVNode(_component_el_icon, { size: 88 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_rank)
                        ]),
                        _: 1
                      })
                    ]),
                    (!_ctx.state.disabled)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "el-upload-list__item-delete",
                          onClick: ($event: any) => (_ctx.handleRemove(index))
                        }, [
                          _createVNode(_component_el_icon, { size: 88 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_delete)
                            ]),
                            _: 1
                          })
                        ], 8, _hoisted_11))
                      : _createCommentVNode("", true)
                  ])
                ])
              ], 8, _hoisted_5))
            ]),
            footer: _withCtx(() => [
              _createElementVNode("label", _hoisted_12, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_plus)
                  ]),
                  _: 1
                }),
                (_openBlock(), _createElementBlock("input", {
                  class: "el-upload__input",
                  key: _ctx.state.fileKey,
                  multiple: "",
                  type: "file",
                  name: "file",
                  accept: "image/*",
                  onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleUpload && _ctx.handleUpload(...args)))
                }, null, 32))
              ])
            ]),
            _: 1
          }, 16, ["modelValue"])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_image_view, {
      src: _ctx.state.image,
      size: ['1200px', '700px'],
      modelValue: _ctx.state.showImage,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.showImage) = $event)),
      download: false
    }, null, 8, ["src", "modelValue"])
  ]))
}