
import {
  defineComponent,
  reactive,
  onUnmounted,
  onMounted,
  ref,
  inject,
} from "vue";
import { ArrowRight } from "@element-plus/icons-vue";
// import { InternalRuleItem, ValidateOption } from "async-validator";
// import { AnyObject, qqRegex, mobileRegex, emailRegex } from "@/utils/index";
import { useRouter } from "vue-router";
import { uias_getUser } from "@/api/index";
import Layer from "@/components/Layer/Index.vue";
import defaultImg from "@/assets/image/default.jpg";
import LoginOrg from "./views/LoginOrg.vue";
import { AnyObject } from "./utils";
import { loginKey } from "./const";

export default defineComponent({
  components: {
    IcArrowRight: ArrowRight,
    Layer,
    LoginOrg,
  },
  setup(/* props, context */) {
    const state = reactive<{
      personal: AnyObject;
      activeOrg: string;
      show: boolean;
      loading: boolean;
    }>({
      personal: {},
      activeOrg: sessionStorage.activeOrg,
      show: false,
      loading: false,
    });
    const layer = ref<typeof Layer | null>(null);
    const router = useRouter();
    const login = inject(loginKey);
    const handle = (evt: MessageEvent) => {
      const { data, origin } = evt;
      if (origin !== location.origin || !data.isLoginValidate) return;
      if (data.isController) {
        if (data.message === "再次可以登录") {
          state.activeOrg = data.activeOrg;
        }
      }
    };
    const relogin = () => {
      router.replace("/login");
    };
    window.addEventListener("relogin", relogin, false);
    window.addEventListener("message", handle);
    onMounted(() => {
      uias_getUser().then(({ data }) => {
        state.personal = data.userInfo;
      });
    });
    onUnmounted(() => {
      window.removeEventListener("message", handle);
      window.removeEventListener("relogin", relogin);
    });
    return {
      state,
      layer,
      defaultImg,
      logout() {
        // delete sessionStorage.token;
        delete sessionStorage.activeOrg;
        delete sessionStorage.appid;
        // delete sessionStorage.homeCard;
        // delete sessionStorage.menu;
        // delete sessionStorage.withpathmenus;
        // delete sessionStorage._withpathmenus;
        location.href = location.origin + "/#/login";
      },
      login() {
        sessionStorage.activeOrg = state.activeOrg;
        state.loading = true;
        login &&
          login()
            .then((userInfo: AnyObject) => {
              state.personal = userInfo;
              layer.value?.close();
            })
            .finally(() => {
              state.loading = false;
            });
      },
    };
  },
});
