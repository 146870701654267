
import { computed, defineComponent, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessageBox } from "element-plus";
import { Delete } from "@element-plus/icons-vue";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import FileSaver from "file-saver";
import { AnyObject, getURLName } from "@/utils";
import {
  ipark_servicepark_queryEnterpriseTaxDetailList,
  ipark_servicepark_deleteTaxByPeriod,
  servicepark_enterpriseSearch,
  ipark_servicepark_downloadTaxData,
} from "@/api";

export default defineComponent({
  components: {
    Page,
    DataTable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      dataFilter: {
        keyword: route.query?.keyword as string,
      },
      enterprises: [] as AnyObject[],
      loading: false,
    });
    const table = ref<typeof DataTable | null>(null);
    function remoteMethod(keyword: string) {
      state.loading = true;
      servicepark_enterpriseSearch({
        keyword,
      })
        .then(({ data }) => {
          state.enterprises = data;
        })
        .finally(() => {
          state.loading = false;
        });
    }
    remoteMethod(route.query?.keyword as string);
    return {
      state,
      table,
      Delete,
      path: route.query?.taxPeriodStr,
      onDownload(obj: AnyObject) {
        ipark_servicepark_downloadTaxData({
          taxPeriodEnd: route.query?.taxPeriod,
          taxPeriodStart: route.query?.taxPeriod,
        }).then(({ data, msg }) => {
          FileSaver.saveAs(data, msg);
        });
      },
      onSeach() {
        table.value?.reset();
      },
      remoteMethod,
      onDel() {
        ElMessageBox.confirm("删除后不可恢复，是否继续?", "删除提醒", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          state.loading = true;
          ipark_servicepark_deleteTaxByPeriod({
            taxPeriod: route.query?.taxPeriod,
          })
            .then(() => {
              router.back();
            })
            .finally(() => {
              state.loading = false;
            });
        });
      },
      getData(param: Iparam) {
        return ipark_servicepark_queryEnterpriseTaxDetailList({
          searchKeyword: state.dataFilter.keyword,
          taxPeriodEnd: route.query?.taxPeriod,
          taxPeriodStart: route.query?.taxPeriod,
          page: param.index,
          per_page: param.size,
        }).then(({ data }) => {
          return {
            data: data.rows,
            total: data.totalRows,
          };
        });
      },
    };
  },
});
