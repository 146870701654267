
import {
  defineComponent,
  reactive,
  ref,
  watch,
  computed,
  watchEffect,
  nextTick,
} from "vue";
import { ElForm } from "element-plus";
import { UUIDGenerator } from "@/utils";
import FileSelect from "@/components/FileSelect/Index.vue";
import { IRule } from "../../components/ModifyBusinessLicense/utils";
import { ocr_businessLicense } from "@/api";
export default defineComponent({
  components: {
    FileSelect,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isNew: {
      type: Boolean,
      default: true,
    },
    isAdd: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["filechange"],
  setup(props, { emit }) {
    const state = reactive({
      form: props.data,
      loading: false,
      show: false,
      incomeKey: UUIDGenerator(),
      ocr: false,
    });
    const lock = ref(!props.isNew);
    const formRef = ref<typeof ElForm | null>(null);
    watch(
      () => state.form.file,
      () => {
        if (!state.ocr && state.form.file && state.form.file instanceof File) {
          state.form.operatorCorpCode = "";
          state.form.operatorCorpName = "";
          state.ocr = true;
          ocr_businessLicense({
            file: state.form.file,
          })
            .then(({ data }) => {
              // state.form.enterprisescope = data.activitiesScope;
              // state.form.enterprisetype = data.enterpriseTypeCode;
              // state.form.establishmentDate = data.establishmentDate
              //   .replace("日", "")
              //   .replace(/[年月]/g, "-");
              // state.form.legalpersonName = data.legalRepresentativeName;
              // state.form.enterpriseaddress = data.registAddress;
              state.form.operatorCorpCode = data.taxpayerId;
              state.form.operatorCorpName = data.taxpayerName;
            })
            .finally(() => {
              state.ocr = false;
            });
        }
        emit("filechange", state.form.file);
      },
      {
        immediate: true,
      }
    );
    watchEffect(() => {
      state.form = props.data;
      state.incomeKey = UUIDGenerator();
      nextTick(() => {
        formRef.value?.clearValidate();
      });
    });
    return {
      state,
      lock,
      formRef,
      rules: computed(() => {
        return {
          serviceParkName: [
            {
              required: true,
              message: "请输入产业园名称！",
              trigger: "blur",
            },
          ],
          serviceParkCode: [
            {
              required: true,
              message: "请输入产业园代码（运营管理公司税号）！",
              trigger: "blur",
            },
          ],
          serviceParkAddress: [
            {
              required: true,
              message: "请输入产业园详细地址！",
              trigger: "blur",
            },
          ],
          serviceParkGps: [
            {
              required: true,
              message: "请输入腾讯地图GPS坐标，方便手机用户导航到产业园！",
              trigger: "blur",
            },
          ],
          file: [
            {
              validator: (rule: any, value: any, callback: any) => {
                if (value) {
                  const result = /\.[\w]*$/.exec(value.name);
                  if (
                    result &&
                    ".jpeg,.jpg,.png"
                      .toLowerCase()
                      .indexOf(result[0].toLowerCase()) !== -1
                  ) {
                    callback();
                  } else {
                    callback(new Error("请选择jpg格式的图片"));
                  }
                } else {
                  callback(new Error("请选择营业执照！"));
                }
              },
              trigger: "change",
            },
          ],
          operatorCorpName: [
            {
              required: true,
              message: "请输入产业园运营管理公司工商登记名称！",
              trigger: ["blur", "change"],
            },
          ],
          operatorCorpCode: [
            {
              required: true,
              message: "请输入产业园运营管理公司的税号！",
              trigger: ["blur", "change"],
            },
          ],
          director: [
            {
              required: true,
              message: "请输入产业园负责人姓名！",
              trigger: "blur",
            },
          ],
          directorMobile: [
            {
              required: true,
              message: "请输入产业园负责人手机！",
              trigger: "blur",
            },
          ],
        };
      }),
      onInput(field: keyof IRule) {
        if ("operatorCorpName;operatorCorpCode".indexOf(field) !== -1) {
          state.form.file = "";
        }
      },
    };
  },
});
