
import { defineComponent, reactive, ref, nextTick, onMounted } from "vue";
import { ElAutocomplete } from "element-plus";
import * as echarts from "echarts";
import companyDetailThirdTab from "./companyDetail.vue";
import { ElSelect } from "element-plus";
import PageHome from "@/components/Page/Index.vue";
import { useRouter } from "vue-router";
// import DataTable from "@/components/DataTable/Index.vue";
import {
  servicepark_enterpriseSearch,
  ipark_servicepark_registrationStatistics,
  ipark_servicepark_growthtrend,
  ipark_servicepark_taxRevenueCard,
  ipark_servicepark_enterpriseRewardCard,
  ipark_servicepark_partnerRewardCard,
  ipark_servicepark_enterpriseRewardList,
  ipark_servicepark_partnerRewardListByPeroid,
} from "@/api/index";
import { AnyObject } from "@/utils";
import { toNum } from "@/utils";

export default defineComponent({
  components: {
    companyDetailThirdTab,
    PageHome,
  },
  setup() {
    const rotate = ref(0);
    const router = useRouter();
    const autoInput0 = ref<typeof ElAutocomplete | null>(null);
    const selectForm = ref<typeof ElSelect | null>(null);
    const dialogThirdCompany = reactive({
      dialogTableVisible: false,
      title: "",
      information: {
        taxpayerName: "",
        taxpayerId: "",
      },
    });
    const state = reactive({
      notice: "测试头部走马灯，点击取消按钮可以删除",
      size: "24",
      color: "#333",
      keyWord: "",
      loading: false,
      enterprises: [] as AnyObject[],
      searchShow: false,
      registrationStatistics: {},
      taxRevenueCard: {},
      enterpriseRewardCard: {},
      partnerRewardCard: {},
      cards: [
        {
          title: "入驻企业",
          color: "#447DEA",
          showIcon: true,
        },
        {
          title: "园区产税",
          color: "#E6A23C",
          showIcon: false,
        },
        {
          title: "扶持企业",
          color: "#99CC00",
          showIcon: false,
        },
        // {
        //   title: "招商奖励",
        //   color: "#FF0066",
        //   showIcon: false,
        // },
      ],
    });
    const remoteMethod = (keyword: string) => {
      state.loading = true;
      servicepark_enterpriseSearch({
        keyword,
      })
        .then(({ data }) => {
          state.enterprises = data;
        })
        .finally(() => {
          state.loading = false;
        });
    };
    // 点击搜索按钮或者回车
    const handleFormSelect = (queryString: any) => {
      if (state.keyWord == "") {
        return;
      }
      selectForm.value?.blur();
      nextTick(() => {
        let queryItem = {} as any;
        state.enterprises.map((val, index) => {
          if (queryString == val.taxpayerId) {
            queryItem = state.enterprises[index];
          }
        });
        dialogThirdCompany.title = queryItem.taxpayerName;
        dialogThirdCompany.information = queryItem;
        state.keyWord = queryItem.taxpayerName;
        dialogThirdCompany.dialogTableVisible = true;
      });
    };
    const handleSelect = (queryString: any) => {
      if (state.keyWord == "") {
        return;
      }
      let queryItem = {} as any;
      dialogThirdCompany.dialogTableVisible = true;
      state.enterprises.map((val, index) => {
        if (state.keyWord == val.taxpayerName) {
          queryItem = state.enterprises[index];
        }
      });
      dialogThirdCompany.title = queryItem.taxpayerName;
      return (dialogThirdCompany.information = queryItem);
    };
    // ===========canvas图表通用设置
    const lineOption = (data: any) => {
      return {
        grid: {
          containLabel: true,
          top: 40,
          left: 20,
          right: 20,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        xAxis: [
          {
            type: "category",
            data: data.map((val: { taxPeriodStr: any }) => val.taxPeriodStr),
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
          },
          {
            type: "value",
            name: "单位：万元",
            offset: -20,
          },
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            barWidth: "60%",
            data: data.map((val: { rewardAmount: string | number }) =>
              toNum(val.rewardAmount)
            ),
          },
        ],
      };
    };

    // ===========柱状曲线图1
    const getEchart1 = (dataEchart: any, elChartName: any) => {
      const chat = dataEchart;
      const chartData = [];
      let xAxis: number[] = [],
        legend: string[] = [];
      for (let key in chat) {
        if (key === "yearOnYearMapList") continue;
        chat[key].forEach((val: { yearStr: string }) => {
          const yearStr = parseInt(val.yearStr);
          if (xAxis.indexOf(yearStr) === -1) {
            xAxis.push(yearStr);
          }
        });
      }
      xAxis.sort((p, n) => p - n);
      for (let key in chat) {
        if (key === "growthTrendList") continue;
        chartData.push({
          name: (chat[key][0] || {}).enterpriseTypeName,
          type: "line",
          // stack: "总量",
          data: xAxis.map((val) => {
            const obj =
              chat[key].filter(
                (v: { yearStr: string }) => val === parseInt(v.yearStr)
              )[0] || {};
            return obj.retainedCount;
          }),
        });
        legend.push((chat[key][0] || {}).enterpriseTypeName);
      }
      chat.growthTrendList.sort(
        (p: { yearStr: string }, n: { yearStr: string }) =>
          parseInt(p.yearStr) - parseInt(n.yearStr)
      );
      chartData.push({
        name: (chat.growthTrendList[0] || {}).enterpriseTypeName,
        type: "bar",
        barWidth: "60%",
        color: "#BCD2EE",
        data: chat.growthTrendList.map((val: { retainedCount: any }) => {
          return val.retainedCount;
        }),
      });
      legend.push((chat.growthTrendList[0] || {}).enterpriseTypeName);
      const option = {
        grid: {
          containLabel: true,
          top: 20,
          left: 30,
          right: 30,
          bottom: 70,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: legend,
          bottom: 10,
          textStyle: {
            fontSize: 12,
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xAxis,
        },
        yAxis: {
          type: "value",
        },
        series: chartData,
      };
      echarts.init(elChartName).setOption(option);
    };
    //=============柱状曲线图2
    const getEchart2 = (dataEchart: any, elChartName: any) => {
      const data = dataEchart;
      const option = {
        grid: {
          containLabel: true,
          top: 40,
          left: 10,
          right: 10,
          bottom: 50,
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: data.taxRevenueList.map(
            (val: { taxPeriod: any }) => val.taxPeriod
          ),
          axisLabel: {
            showMaxLabel: true,
          },
        },
        yAxis: [
          {
            type: "value",
            name: "当月（万元）",
          },
          {
            type: "value",
            name: "累计（万元）",
          },
        ],
        legend: {
          data: [
            data.lastYear + "年",
            data.currentYear + "年",
            "上年累计",
            "本年累计",
          ],
          bottom: 10,
        },
        series: [
          {
            name: data.lastYear + "年",
            type: "bar",
            barWidth: "40%",
            data: data.taxRevenueList.map(
              (val: { lastYearMonthAmount: string | number }) =>
                toNum(val.lastYearMonthAmount)
            ),
            itemStyle: {
              color: "#C4CCD3",
            },
          },
          {
            name: data.currentYear + "年",
            type: "bar",
            barWidth: "40%",
            data: data.taxRevenueList.map(
              (val: { currentYearMonthAmount: string | number }) =>
                toNum(val.currentYearMonthAmount)
            ),
            itemStyle: {
              color: "#5470C6",
            },
          },
          {
            name: "上年累计",
            yAxisIndex: 1,
            data: data.taxRevenueList.map(
              (val: { lastYearSumAmount: string | number }) =>
                toNum(val.lastYearSumAmount)
            ),
            type: "line",
            itemStyle: {
              color: "#AEC2D5",
            },
          },
          {
            name: "本年累计",
            yAxisIndex: 1,
            data: data.taxRevenueList.map(
              (val: { currentYearSumAmount: string | number }) =>
                toNum(val.currentYearSumAmount)
            ),
            type: "line",
            itemStyle: {
              color: "#FBD785",
            },
          },
        ],
      };
      echarts.init(elChartName).setOption(option);
    };

    const createFilter = (queryString: string) => {
      return (restaurant: { value: string }) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    };
    const detail = (i: number) => {
      let link = "";
      switch (i) {
        case 0:
          link = "/enterpriseListForPark";
          break;
        case 1:
          link = "/taxListForPark";
          break;
        case 2:
          link = "/enterpriseRewardListForPark";
          break;
        case 3:
          link = "/partnerRewardListForPark";
          break;
        default:
          break;
      }
      router.push({
        path: link,
      });
    };
    onMounted(() => {
      // 获取入驻企业统计
      ipark_servicepark_registrationStatistics().then((obj) => {
        if (obj.data != null) {
          state.registrationStatistics = obj.data;
        }
      });
      const elChart0 = document.getElementById("mychart0");
      if (!elChart0) return;
      echarts.init(elChart0).resize();
      // 入驻企业曲线图
      ipark_servicepark_growthtrend().then((serviceparkChart) => {
        getEchart1(serviceparkChart.data.chat, elChart0);
      });

      // 园区产税曲线图
      const elChart1 = document.getElementById("mychart1");
      if (!elChart1) return;
      echarts.init(elChart1).resize();
      ipark_servicepark_taxRevenueCard().then((serviceparkChart) => {
        getEchart2(serviceparkChart.data.chat, elChart1);
        if (serviceparkChart.data.summary != null) {
          state.taxRevenueCard = serviceparkChart.data.summary;
        }
      });
      // 扶持企业统计
      const elChart2 = document.getElementById("mychart2");
      if (!elChart2) return;
      echarts.init(elChart2).resize();
      ipark_servicepark_enterpriseRewardList().then((obj) => {
        echarts.init(elChart2).setOption(lineOption(obj.data.chat));
      });
      ipark_servicepark_enterpriseRewardCard().then((obj) => {
        if (obj.data.summary != null) {
          state.enterpriseRewardCard = obj.data.summary;
        }
      });
      // 招商奖励统计
      const elChart3 = document.getElementById("mychart3");
      if (!elChart3) return;
      echarts.init(elChart3).resize();
      ipark_servicepark_partnerRewardListByPeroid().then((obj) => {
        echarts.init(elChart3).setOption(lineOption(obj.data.chat));
      });
      ipark_servicepark_partnerRewardCard().then((obj) => {
        if (obj.data.summary != null) {
          state.partnerRewardCard = obj.data.summary;
        }
      });
      window.addEventListener("resize", () => {
        echarts.init(elChart0).resize({});
        echarts.init(elChart1).resize();
        echarts.init(elChart2).resize();
        echarts.init(elChart3).resize();
      });
    });
    return {
      state,
      detail,
      dialogThirdCompany,
      autoInput0,
      rotate,
      selectForm,
      createFilter,
      handleSelect,
      handleFormSelect,
      remoteMethod,
    };
  },
});
