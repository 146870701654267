
import { defineComponent, reactive, ref, onMounted, nextTick } from "vue";
import { InternalRuleItem } from "async-validator";
import { UserFilled } from "@element-plus/icons-vue";
import {
  uias_wechat_oauth2_sendVfCode,
  uias_wechat_oauth2_addUser,
} from "@/api";
import { mobileRegex } from "@/utils/index";

export default defineComponent({
  components: {
    UserFilled,
  },
  setup(/* props, context */) {
    const formRef = ref<any>(null);
    const state = reactive({
      times: 60,
      loading: false,
      form: {
        mobile: "",
        verification: "",
      },
    });
    let fromSubmit = false;
    function countDown() {
      state.times--;
      let count = setInterval(() => {
        if (state.times === 0) {
          clearInterval(count);
          state.times = 60;
        } else {
          state.times--;
        }
      }, 1000);
    }
    return {
      state,
      formRef,
      rules: {
        mobile: [
          {
            validator: (
              rule: InternalRuleItem,
              value: any,
              callback: (error?: string | Error) => void
            ) => {
              if (value) {
                if (mobileRegex.test(value)) {
                  if (fromSubmit) {
                    callback();
                  } else {
                    if (state.loading) {
                      callback(new Error("正在发送验证码！"));
                      return;
                    }
                    if (state.times !== 60) {
                      callback(new Error("请稍后再试！"));
                      return;
                    }
                    state.loading = true;
                    uias_wechat_oauth2_sendVfCode({
                      mobile: value,
                      __notoken__: true,
                      _noShowError_: true,
                    }).then(
                      () => {
                        state.loading = false;
                        countDown();
                        callback();
                      },
                      (err) => {
                        state.loading = false;
                        if (typeof err === "string") {
                          callback(new Error(err));
                        } else {
                          callback(err);
                        }
                      }
                    );
                  }
                } else {
                  callback(new Error("手机号格式不正确！"));
                }
              } else {
                callback(new Error("请输入手机号"));
              }
            },
            trigger: "none",
          },
        ],
        verification: [
          {
            required: true,
            trigger: "blur",
            message: "验证码不能为空！",
          },
        ],
      },
      getVerification() {
        nextTick(() => {
          fromSubmit = false;
          formRef.value.validateField("mobile");
        });
      },
      submitForm() {
        nextTick(() => {
          fromSubmit = true;
          formRef.value.validate((valid: any) => {
            if (valid) {
              uias_wechat_oauth2_addUser({
                mobile: state.form.mobile,
                vfcode: state.form.verification,
                code: sessionStorage.__code,
                __notoken__: true,
                _noShowError_: true,
              }).then(
                ({ data }) => {
                  if (window.top) {
                    delete sessionStorage.__code;
                    sessionStorage.token = data.loginUser.token;
                    window.top.postMessage(
                      {
                        isLoginValidate: true,
                        message: "登录",
                      },
                      window.top.location.origin
                    );
                  }
                },
                (err) => {
                  if (window.top) {
                    window.top.postMessage(
                      {
                        isLoginValidate: true,
                        isController: true,
                        isError: true,
                        message: err,
                      },
                      window.top.location.origin
                    );
                  }
                }
              );
            } else {
              return false;
            }
          });
        });
      },
    };
  },
});
