import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5df9e85d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = {
  key: 1,
  class: "page__header"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_page_header = _resolveComponent("el-page-header")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.useHeader)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.hasBack)
            ? (_openBlock(), _createBlock(_component_el_page_header, {
                key: 0,
                onBack: _ctx.goBack,
                title: "返回"
              }, {
                content: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "header", {}, undefined, true)
                ]),
                _: 3
              }, 8, ["onBack"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "header", {}, undefined, true)
              ]))
        ], 64))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}