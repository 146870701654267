
import { defineComponent, reactive, ref, onMounted, nextTick } from "vue";
import * as echarts from "echarts";
import ModifyBusinesslicenseInnerContent from "@/components/ModifyBusinessLicense/InnerContent.vue";
import DataTable from "@/components/DataTable/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import bg from "@/assets/image/bg.jpg";
import { ElSelect } from "element-plus";
import companyDetailThirdTab from "./../companyDetail.vue";
import { AnyObject, toNum, getSlice } from "@/utils";
import Page from "@/components/Page/Index.vue";
import { IData } from "@/components/DataTable/utils";
import FileSaver from "file-saver";
import {
  ipark_servicepark_taxContributionRanking,
  ipark_servicepark_enterprisetypeList,
  ipark_servicepark_queryEnterpriseTaxDetailList,
  ipark_servicepark_taxRevenueByMonth,
  ipark_servicepark_taxProportionByIndustry,
  ipark_servicepark_taxProportionByTaxCategory,
  ipark_servicepark_taxProportionByEnterpriseType,
  servicepark_enterpriseSearch,
  ipark_servicepark_downloadTaxData,
} from "@/api/index";
export default defineComponent({
  emits: ["keyup"],
  components: {
    DataTable,
    Page,
    companyDetailThirdTab,
  },
  methods: {
    detail(row: any, index: any) {
      this.dialogThirdCompany.dialogTableVisible = true;
      this.dialogThirdCompany.title = index.taxpayerName;
      return (this.dialogThirdCompany.information = index);
    },
    option(canvasSeries: {
      radius: string | string[] | number[];
      roseType?: string;
    }) {
      return {
        title: {
          text: "Referer of a Website",
          subtext: "Fake Data",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: canvasSeries.radius,
            roseType: canvasSeries.roseType,
            avoidLabelOverlap: false,
            data: [
              { value: 1048, name: "Search Engine" },
              { value: 735, name: "Direct" },
              { value: 580, name: "Email" },
              { value: 484, name: "Union Ads" },
              { value: 300, name: "Video Ads" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    getDataOne(param: Iparam): Promise<IData> {
      if (this.state.activeName == "one") {
        return ipark_servicepark_queryEnterpriseTaxDetailList({
          taxPeriodStart: this.formInline.registionDate
            ? this.formInline.registionDate[0]
            : "",
          taxPeriodEnd: this.formInline.registionDate
            ? this.formInline.registionDate[1]
            : "",
          enterpriseTypeCode: this.formInline.enterpriseTypeCode,
          searchKeyword: this.state.searchKeyWord,
          page: param.index,
          per_page: param.size,
        }).then((obj) => {
          this.state.sumRow = obj.data.sumRow || {};
          this.state.tableHeight1 = Number(
            this.tableHeightNum("table1", "data-table__header", 315)
          );
          return {
            data: obj.data.rows,
            total: obj.data.totalRows,
          };
        });
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    },
    getDataTwo(param: Iparam) {
      this.state.lastest = param.index;
      const tableScrollTop = this.twoTable?.tableRef;
      if (this.state.activeName == "two") {
        const elChart02 = document.getElementById("mychart02");
        tableScrollTop.$refs.bodyWrapper.scrollTop = 0;
        return ipark_servicepark_taxRevenueByMonth({
          page: param.index,
          per_page: param.size,
        }).then((obj) => {
          this.state.mychartData02 = obj.data.chat;
          this.state.tableHeight2 = Number(
            this.tableHeightNum("table2", "el-table__header", 421)
          );
          if (!elChart02) return;
          echarts.init(elChart02).resize();
          this.getEchart(this.state.mychartData02, elChart02);
          return getSlice(param.index, param.size, obj.data.rows);
        });
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    },
    getEchart(dataEchart: any, elChartName: any) {
      const data = dataEchart;
      const option = {
        grid: {
          containLabel: true,
          top: 40,
          left: 10,
          right: 10,
          bottom: 50,
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: data.taxRevenueList.map(
            (val: { taxPeriod: any }) => val.taxPeriod
          ),
          axisLabel: {
            showMaxLabel: true,
          },
        },
        yAxis: [
          {
            type: "value",
            name: "当月（万元）",
          },
          {
            type: "value",
            name: "累计（万元）",
          },
        ],
        legend: {
          data: [
            data.lastYear + "年",
            data.currentYear + "年",
            "当年累计",
            "本年累计",
          ],
          bottom: 10,
        },
        series: [
          {
            name: data.lastYear + "年",
            type: "bar",
            barWidth: "40%",
            data: data.taxRevenueList.map(
              (val: { lastYearMonthAmount: string | number }) =>
                toNum(val.lastYearMonthAmount)
            ),
            itemStyle: {
              color: "#C4CCD3",
            },
          },
          {
            name: data.currentYear + "年",
            type: "bar",
            barWidth: "40%",
            data: data.taxRevenueList.map(
              (val: { currentYearMonthAmount: string | number }) =>
                toNum(val.currentYearMonthAmount)
            ),
            itemStyle: {
              color: "#5470C6",
            },
          },
          {
            name: "当年累计",
            yAxisIndex: 1,
            data: data.taxRevenueList.map(
              (val: { lastYearSumAmount: string | number }) =>
                toNum(val.lastYearSumAmount)
            ),
            type: "line",
            itemStyle: {
              color: "#AEC2D5",
            },
          },
          {
            name: "本年累计",
            yAxisIndex: 1,
            data: data.taxRevenueList.map(
              (val: { currentYearSumAmount: string | number }) =>
                toNum(val.currentYearSumAmount)
            ),
            type: "line",
            itemStyle: {
              color: "#FBD785",
            },
          },
        ],
      };
      echarts.init(elChartName).setOption(option);
    },
    todo(index: any, row: { taxPeriod: string; taxPeriodStr: string }) {
      this.state.dialogVisible = true;
      this.state.activeDialogName = "first";
      this.state.taxPeriod = row.taxPeriod;
      this.state.taxPeriodStr = row.taxPeriodStr;
      this.state.dialogTitle = "园区税收统计 / " + this.state.taxPeriodStr;
    },
    getSummaries(param: { columns: any; data: any }) {
      const sums = ["合计", ""];
      param.columns.map((obj: any, index: number) => {
        if (index > 1) {
          sums.push(this.state.sumRow[obj.property]);
        }
      });
      return sums;
    },
  },
  setup() {
    const autoInput0 = ref<typeof DataTable | null>(null);
    const oneTable = ref<typeof DataTable | null>(null);
    const twoTable = ref<typeof DataTable | null>(null);
    const firstTable = ref<typeof DataTable | null>(null);
    const secondTable = ref<typeof DataTable | null>(null);
    const thirdTable = ref<typeof DataTable | null>(null);
    const fourthTable = ref<typeof DataTable | null>(null);
    const businesslicenseInner = ref<
      typeof ModifyBusinesslicenseInnerContent | null
    >(null);
    const select = ref<typeof ElSelect | null>(null);
    const state = reactive({
      tableHeight1: 0,
      tableHeight2: 0,
      lastest: 0,
      loading: false,
      enterprises: [] as AnyObject[],
      getDataDialogTwo: [] as AnyObject[],
      getDataDialogThree: [] as AnyObject[],
      getDataDialogFour: [] as AnyObject[],
      taxPeriod: "",
      taxPeriodStr: "",
      sumRow: {} as AnyObject,
      mychartData02: [],
      mychartDialog1: [],
      loadingTwoChart: true,
      loadingFirstChart: true,
      loadingSecondChart: true,
      loadingThirdChart: true,
      loadingFourthChart: true,
      searchKeyWord: "",
      activeDialogName: "",
      enterpriseType: [{ value: "", label: "全部" }],
      activeName: "one",
      dialogTitle: "",
      dialogVisible: false,
      title: "",
    });
    const dialogCompanyImg = reactive({
      dialogVisible: false,
      title: "",
      form: {},
    });
    const dialogThirdCompany = reactive({
      dialogTableVisible: false,
      title: "",
      activeName: "first",
      information: {
        taxpayerName: "",
        taxpayerId: "",
      },
    });
    const curMonth =
      new Date().getMonth() + 1 < 10
        ? "0" + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1;
    const preMonth =
      new Date().getMonth() < 10
        ? "0" + new Date().getMonth()
        : new Date().getMonth();
    const currentMonth = new Date().getFullYear() + "" + curMonth;
    const prevMonth =
      preMonth == "00"
        ? new Date().getFullYear() - 1 + "" + "12"
        : new Date().getFullYear() + "" + preMonth;
    const formInline = reactive({
      enterpriseTypeName: "",
      enterpriseTypeCode: "",
      state: "",
      registionDate: [prevMonth, currentMonth],
    });
    const remoteMethod = (keyword: string) => {
      state.loading = true;
      servicepark_enterpriseSearch({
        keyword,
      })
        .then(({ data }) => {
          state.enterprises = data;
        })
        .finally(() => {
          state.loading = false;
        });
    };
    function onSearch() {
      select.value?.blur();
      nextTick(() => {
        oneTable.value?.reset();
      });
    }
    // tab切换
    function handleClick() {
      switch (state.activeName) {
        case "one":
          oneTable.value?.reset();
          break;
        case "two":
          twoTable.value?.reset();
          break;
        default:
          break;
      }
    }
    function FirstOption(data: any[], params: any) {
      data = data.reverse();
      const tmp = data.map((val) => {
        return parseFloat(val.sumTotalTaxAmount.replace(/,/g, ""));
      });
      const total = tmp.reduce((r, e) => r + e, 0);
      return {
        title: {
          text: params.taxPeriodStr + "产税排名",
          left: "center",
          textStyle: {
            fontSize: 16,
          },
          padding: [20, 20],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          containLabel: true,
          top: 60,
          left: 0,
          right: 20,
          bottom: 20,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: data.map((val) => {
            return val.taxpayerName;
          }),
          nameTextStyle: {
            fontSize: 10,
          },
        },
        series: [
          {
            name: "金额",
            type: "bar",
            data: tmp.map((val) => {
              return {
                value: toNum(val),
                label: {
                  show: true,
                  position: val > total / 4 ? "insideRight" : "right",
                },
              };
            }),
          },
        ],
      };
    }
    function SecondOption(data: any[], params: any) {
      return {
        title: {
          text: params.taxPeriodStr + "产税分行业统计",
          left: "center",
          textStyle: {
            fontSize: 16,
          },
          padding: [10, 20],
        },
        grid: {
          containLabel: true,
          top: 60,
          left: 20,
          right: 20,
          bottom: 20,
        },
        series: [
          {
            name: "行业",
            type: "pie",
            radius: ["20%", "50%"],
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            label: {
              // formatter: "",
              emphasis: {
                label: {
                  show: true,
                  fontWeight: "bold",
                },
              },
            },
            data: data.map((val) => {
              return {
                value: toNum(val.totalTaxAmount),
                name: val.industryName + "\n" + val.prop,
              };
            }),
          },
        ],
      };
    }
    function ThirdOption(data: any[], params: any) {
      return {
        title: {
          text: params.taxPeriodStr + "产税分税种统计",
          left: "center",
          textStyle: {
            fontSize: 16,
          },
          padding: [10, 20],
        },
        grid: {
          containLabel: true,
          top: 60,
          left: 20,
          right: 20,
          bottom: 20,
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "税种",
            type: "pie",
            radius: ["20%", "50%"],
            avoidLabelOverlap: true,
            label: {
              show: true,
              position: "outside",
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              label: {
                show: true,
                fontWeight: "bold",
              },
            },
            data: data.map((val) => {
              return {
                value: toNum(val.sumTotalTaxAmount),
                name: val.taxCategoryName + "\n" + val.prop,
              };
            }),
          },
        ],
      };
    }
    function FourthOption(data: any[], params: any) {
      return {
        title: {
          text: params.taxPeriodStr + "产税分企业类型统计",
          left: "center",
          textStyle: {
            fontSize: 16,
          },
          padding: [20, 20],
        },
        grid: {
          containLabel: true,
          top: 60,
          left: 20,
          right: 20,
          bottom: 20,
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "企业类型",
            type: "pie",
            radius: "50%",
            data: data.map((val) => {
              return {
                name:
                  val.enterpriseTypeName + "\n" + val.prop.replace("占比", ""),
                value: toNum(val.sumTotalTaxAmount),
              };
            }),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              label: {
                show: true,
                fontWeight: "bold",
              },
            },
          },
        ],
      };
    }

    function getDataDialogOne(param: Iparam) {
      if (state.activeDialogName == "first") {
        return ipark_servicepark_taxContributionRanking({
          taxPeriod: state.taxPeriod,
          page: param.index,
          per_page: param.size,
        }).then((obj) => {
          const elChart1 = document.getElementById("mychart1");
          if (!elChart1) return;
          echarts.init(elChart1).resize();
          echarts.init(elChart1).setOption(FirstOption(obj.data.chat, state));
          return {
            data: obj.data.rows,
            total: obj.data.totalRows,
          };
        });
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    }

    // 弹框tab切换
    function handleDialogClick() {
      const elChart2 = document.getElementById("mychart2");
      const elChart3 = document.getElementById("mychart3");
      const elChart4 = document.getElementById("mychart4");
      switch (state.activeDialogName) {
        case "first":
          firstTable.value?.reset();
          break;
        case "second":
          ipark_servicepark_taxProportionByIndustry({
            taxPeriod: state.taxPeriod,
          }).then((obj) => {
            state.getDataDialogTwo = obj.data.rows;
            if (!elChart2) return;
            echarts.init(elChart2).resize();
            echarts
              .init(elChart2)
              .setOption(SecondOption(obj.data.chat, state));
          });
          break;
        case "third":
          ipark_servicepark_taxProportionByTaxCategory({
            taxPeriod: state.taxPeriod,
          }).then((obj) => {
            state.getDataDialogThree = obj.data.rows;
            if (!elChart3) return;
            echarts.init(elChart3).resize();
            echarts.init(elChart3).setOption(ThirdOption(obj.data.chat, state));
          });
          break;
        case "fourth":
          ipark_servicepark_taxProportionByEnterpriseType({
            taxPeriod: state.taxPeriod,
          }).then((obj) => {
            state.getDataDialogFour = obj.data.rows;
            if (!elChart4) return;
            echarts.init(elChart4).resize();
            echarts
              .init(elChart4)
              .setOption(FourthOption(obj.data.chat, state));
          });
          break;
        default:
          break;
      }
    }
    function tableHeightNum(
      tableClass: string,
      tableHeaderName: string,
      chartHeight: number
    ) {
      if (document.getElementsByClassName(tableClass).length > 0) {
        return (
          window.innerHeight -
          document
            .getElementsByClassName(tableClass)[0]
            .getElementsByClassName(tableHeaderName)[0].scrollHeight -
          chartHeight
        );
      }
    }
    onMounted(() => {
      // 企业类型
      ipark_servicepark_enterprisetypeList().then((array) => {
        array.data.rows.map((obj: any) => {
          state.enterpriseType.push({
            value: obj.enterpriseTypeCode,
            label: obj.enterpriseTypeName,
          });
        });
      });
      window.addEventListener("resize", () => {
        switch (state.activeName) {
          case "one":
            state.tableHeight1 = Number(
              tableHeightNum("table1", "data-table__header", 315)
            );
            break;
          case "two":
            state.tableHeight2 = Number(
              tableHeightNum("table2", "el-table__header", 421)
            );
            break;
          default:
            break;
        }
        const elChart02 = document.getElementById("mychart02");
        const elChart1 = document.getElementById("mychart1");
        const elChart2 = document.getElementById("mychart2");
        const elChart3 = document.getElementById("mychart3");
        const elChart4 = document.getElementById("mychart4");
        if (state.dialogVisible) {
          switch (state.activeDialogName) {
            case "first":
              if (!elChart1) return;
              echarts.init(elChart1).resize();
              break;
            case "second":
              if (!elChart2) return;
              echarts.init(elChart2).resize();
              break;
            case "third":
              if (!elChart3) return;
              echarts.init(elChart3).resize();
              break;
            case "fourth":
              if (!elChart4) return;
              echarts.init(elChart4).resize();
              break;
            default:
              break;
          }
        } else {
          if (!elChart02) return;
          echarts.init(elChart02).resize();
        }
      });
    });
    return {
      bg,
      state,
      formInline,
      autoInput0,
      oneTable,
      twoTable,
      firstTable,
      secondTable,
      thirdTable,
      fourthTable,
      // company,
      dialogThirdCompany,
      dialogCompanyImg,
      businesslicenseInner,
      onSearch,
      remoteMethod,
      getDataDialogOne,
      handleClick,
      handleDialogClick,
      tableHeightNum,
      onSaveInner() {
        businesslicenseInner.value?.validator().then((form: AnyObject) => {
          console.log(form);
        });
      },
      download() {
        debugger;
        return ipark_servicepark_downloadTaxData({
          taxPeriodStart: formInline.registionDate
            ? formInline.registionDate[0]
            : "",
          taxPeriodEnd: formInline.registionDate
            ? formInline.registionDate[1]
            : "",
        }).then(({ data, msg }) => {
          FileSaver.saveAs(data, msg);
        });
      },
    };
  },
});
