
import { defineComponent, ref, PropType, watchEffect } from "vue";
import { ElNotification } from "element-plus";
import { AnyObject } from "@/utils";
import {
  ipark_servicepark_saveEnterprise,
  uias_getOrgUserList,
  ipark_servicepark_partnerInvitationSigned,
} from "@/api";
import Layer from "../Layer/Index.vue";
import InnerContent from "./InnerContent.vue";
import { IForm } from "./utils";

export default defineComponent({
  components: {
    Layer,
    InnerContent,
  },
  emits: ["update:modelValue"],
  props: {
    data: {
      type: Object as PropType<Partial<IForm>>,
      default: () => ({}),
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    read: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const content = ref<typeof InnerContent | null>(null);
    const layer = ref<typeof Layer | null>(null);
    const show = ref(props.modelValue);
    const lock = ref(!props.isNew);
    const loading = ref(false);
    watchEffect(() => {
      emit("update:modelValue", show);
    });
    watchEffect(() => {
      lock.value = !props.isNew;
    });
    watchEffect(() => {
      if (props.data && !props.isNew) {
        lock.value = true;
      }
    });
    return {
      show,
      lock,
      loading,
      content,
      layer,
      onCancel() {
        if (!props.isNew && !lock.value) {
          lock.value = true;
        } else {
          layer.value?.close();
        }
      },
      onSave() {
        if (loading.value) {
          return;
        }
        if (!props.isNew && lock.value) {
          return (lock.value = false);
        }
        loading.value = true;
        Promise.all([
          uias_getOrgUserList({
            appid: "com.hive.ipark",
          }),
          ipark_servicepark_partnerInvitationSigned(),
          content.value?.validator().catch((obj: AnyObject) => {
            // for (let key in obj) {
            //   setTimeout(() => {
            //     ElNotification({
            //       title: "Error",
            //       message: `存在填写错误字段：${obj[key][0].field}，报错信息为：${obj[key][0].message}`,
            //       type: "error",
            //     });
            //   }, 100);
            // }
            ElNotification({
              title: "Error",
              message: `凡涉及营业执照要素修改的，要重新上传营业执照`,
              type: "error",
            });
            return Promise.reject();
          }),
        ])
          .then(([accesser, partner, form]) => {
            const data: AnyObject = {};
            if (props.isNew) {
              data.licensefile = form.enterprisefile;
              data.contractfile = form.attractprotocol;
              data.taxpayerName = form.enterprisename;
              data.taxpayerId = form.enterprisecode;
              data.enterpriseTypeCode = form.enterprisetype;
              data.industryCode = form.enterprisebusiness;
              data.activitiesScope = form.enterprisescope;
              data.registAddress = form.enterpriseaddress;
              data.establishmentDate = form.establishmentDate;
              data.registionDate = form.enterpriseregistrationDate;
              data.legalRepresentativeName = form.legalpersonName;
              data.legalRepresentativeIdNo = form.legalpersonNo;
              data.legalRepresentativeMobile = form.legalpersonMobile;
              data.enterpriseLiaisonName = form.contactsName;
              data.enterpriseLiaisonMobile = form.contactsMobile;
              data.partnerId = form.attractpartner;
              data.partnerName = (
                partner.data.registedList.filter(
                  (val: AnyObject) => val.partnerId === form.attractpartner
                )[0] || {}
              ).partnerName;
              data.serviceParkLiaisonMobile = form.parkaccesser;
              data.serviceParkLiaisonName = (
                accesser.data.userList.filter(
                  (val: AnyObject) => val.mobile === form.parkaccesser
                )[0] || {}
              ).userName;
            } else {
              for (const key in form) {
                if (form[key]) {
                  switch (key) {
                    case "enterprisefile":
                      data.licensefile =
                        typeof form[key] === "string" ? "" : form[key];
                      break;
                    case "attractprotocol":
                      data.contractfile =
                        typeof form[key] === "string" ? "" : form[key];
                      break;
                    case "enterprisename":
                      data.taxpayerName = form[key];
                      break;
                    case "enterprisecode":
                      data.taxpayerId = form[key];
                      break;
                    case "enterprisetype":
                      data.enterpriseTypeCode = form[key];
                      break;
                    case "enterprisebusiness":
                      data.industryCode = form[key];
                      break;
                    case "enterprisescope":
                      data.activitiesScope = form[key];
                      break;
                    case "enterpriseaddress":
                      data.registAddress = form[key];
                      break;
                    case "establishmentDate":
                      data.establishmentDate = form[key];
                      break;
                    case "enterpriseregistrationDate":
                      data.registionDate = form[key];
                      break;
                    case "legalpersonName":
                      data.legalRepresentativeName = form[key];
                      break;
                    case "legalpersonNo":
                      data.legalRepresentativeIdNo = form[key];
                      break;
                    case "legalpersonMobile":
                      data.legalRepresentativeMobile = form[key];
                      break;
                    case "contactsName":
                      data.enterpriseLiaisonName = form[key];
                      break;
                    case "contactsMobile":
                      data.enterpriseLiaisonMobile = form[key];
                      break;
                    case "attractpartner":
                      data.partnerId = form[key];
                      data.partnerName = (
                        partner.data.registedList.filter(
                          (val: AnyObject) => val.partnerId === form[key]
                        )[0] || {}
                      ).partnerName;
                      break;
                    case "parkaccesser":
                      data.serviceParkLiaisonMobile = form[key];
                      data.serviceParkLiaisonName = (
                        accesser.data.userList.filter(
                          (val: AnyObject) => val.mobile === form[key]
                        )[0] || {}
                      ).userName;
                      break;
                  }
                }
              }
            }
            return ipark_servicepark_saveEnterprise(data).then(() => {
              show.value = false;
              layer.value?.close();
            });
          })
          .finally(() => {
            loading.value = false;
          });
      },
      onResize() {
        content.value?.image?.resize();
      },
    };
  },
});
