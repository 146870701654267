
import { defineComponent, ref, reactive } from "vue";
// import { UploadFile } from "element-plus/es/components/upload/src/upload.type";

export default defineComponent({
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: ".xls;.xlsx",
    },
  },
  setup(props) {
    const show = ref(false);
    const state = reactive({
      msg: "",
    });
    return {
      show,
      state,
      beforeUpload(file: File) {
        if (!(file instanceof File)) return false;
        const result = /\.[\w]*$/.exec(file.name);
        if (
          result &&
          props.accept.toLowerCase().indexOf(result[0].toLowerCase()) !== -1
        ) {
          state.msg = "";
          return true;
        } else {
          state.msg = `仅支持${props.accept}格式文件！`;
          return false;
        }
        // show.value = true;
      },
    };
  },
});
