
import { defineComponent, reactive, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import * as echarts from "echarts";
import { ElSelect, ElForm } from "element-plus";
import FileSaver from "file-saver";
import FileSelect from "@/components/FileSelect/Index.vue";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
// import Layer from "@/components/Layer/Index.vue";
// import Upload from "@/components/Upload/Index.vue";
import { InternalRuleItem } from "async-validator";
import { AnyObject, toNum, getSlice } from "@/utils";
import {
  ipark_servicepark_taxRevenueByMonth,
  servicepark_enterpriseSearch,
  ipark_servicepark_getTaxesTemplateURL,
  ipark_servicepark_importTaxesData,
} from "@/api";
import { getPercentage } from "@/api/utils";

function initChart(
  canvas: HTMLDivElement,
  data: { taxRevenueList: AnyObject[]; lastYear: string; currentYear: string }
) {
  const chart = echarts.init(canvas);

  // /^(\d+)年/.exec(data[0].taxPeriod);
  // const year = parseInt(RegExp.$1);
  // debugger
  const option = {
    title: {
      text: "园区产税分月统计",
      left: "center",
      textStyle: {
        fontSize: 16,
      },
      padding: [20, 20],
    },
    grid: {
      containLabel: true,
      top: 60,
      left: 10,
      right: 10,
      bottom: 50,
    },
    tooltip: {
      show: true,
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: data.taxRevenueList.map(
        (val) => val.taxPeriod || val.taxPeriodRange
      ),
      axisLabel: {
        // showMinLabel: true,
        showMaxLabel: true,
        // width : 100,
        // overflow: "breakAll"
      },
    },
    yAxis: [
      {
        type: "value",
        name: "当月（万元）",
      },
      {
        type: "value",
        name: "累计（万元）",
      },
    ],
    legend: {
      data: [
        data.lastYear + "年",
        data.currentYear + "年",
        "上年累计",
        "本年累计",
      ],
      bottom: 10,
      // left: 0,
      // right: 0,
      // textStyle: {
      //   fontSize: 12
      // }
    },
    series: [
      {
        name: data.lastYear + "年",
        type: "bar",
        barWidth: "30%",
        data: data.taxRevenueList.map((val) => toNum(val.lastYearMonthAmount)),
        itemStyle: {
          color: "#C4CCD3",
        },
      },
      {
        name: data.currentYear + "年",
        type: "bar",
        barWidth: "30%",
        data: data.taxRevenueList.map((val) =>
          toNum(val.currentYearMonthAmount)
        ),
        itemStyle: {
          color: "#5470C6",
        },
      },
      {
        name: "上年累计",
        yAxisIndex: 1,
        data: data.taxRevenueList.map((val) => toNum(val.lastYearSumAmount)),
        type: "line",
        itemStyle: {
          color: "#AEC2D5",
        },
      },
      {
        name: "本年累计",
        yAxisIndex: 1,
        data: data.taxRevenueList.map((val) => toNum(val.currentYearSumAmount)),
        type: "line",
        itemStyle: {
          color: "#FBD785",
        },
      },
    ],
  };

  chart.setOption(option);
  return chart;
}

export default defineComponent({
  components: {
    Page,
    // Layer,
    // Upload,
    FileSelect,
    DataTable,
  },
  setup() {
    const state = reactive({
      dataFilter: {
        keyword: "",
      },
      show: false,
      loading: false,
      showProgress: true,
      enterprises: [] as AnyObject[],
      data: [] as AnyObject[],
      form: {
        taxPeriod: "",
        file: "",
      },
      percentage: 0,
      page: 0,
      status: "",
    });
    const table = ref<typeof DataTable | null>(null);
    // const chart = ref<HTMLDivElement | null>(null);
    const select = ref<typeof ElSelect | null>(null);
    const formRef = ref<typeof ElForm | null>(null);
    const router = useRouter();
    let haspaint = false;

    function onSeach() {
      select.value?.blur();
      state.data = [];
      haspaint = false;
      nextTick(() => {
        table.value?.reset();
      });
    }
    return {
      state,
      table,
      // chart,
      select,
      formRef,
      rules: {
        taxPeriod: [
          {
            required: true,
            message: "请选择税款所属期",
            trigger: "change",
          },
        ],
        file: [
          {
            required: true,
            validator: function (
              rule: InternalRuleItem,
              value: File | string,
              callback: (arg?: Error) => void
            ) {
              if (!rule.required) {
                return callback();
              }
              if (value) {
                if (typeof value === "string") return callback();
                const result = /\.[\w]*$/.exec(value.name);
                if (
                  result &&
                  ".xls;.xlsx"
                    .toLowerCase()
                    .indexOf(result[0].toLowerCase()) !== -1
                ) {
                  callback();
                } else {
                  callback(new Error("请选择Excel文件"));
                }
              } else {
                callback(new Error("请选择文件"));
              }
            },
            trigger: "change",
          },
        ],
      },
      onSeach,
      remoteMethod(keyword: string) {
        state.loading = true;
        servicepark_enterpriseSearch({
          keyword,
        })
          .then(({ data }) => {
            state.enterprises = data;
          })
          .finally(() => {
            state.loading = false;
          });
      },
      onDetail(row: AnyObject) {
        router.push({
          path: "/financialincentive-enterprisepaymentdetail",
          query: {
            taxPeriod: row.taxPeriod,
            taxPeriodStr: row.taxPeriodStr,
            keyword: state.dataFilter.keyword,
          },
        });
      },
      onDownload() {
        ipark_servicepark_getTaxesTemplateURL().then(({ data }) => {
          FileSaver.saveAs(data.downloadUrl);
        });
      },
      onSubmit() {
        // const ws = new WebSocket(
        //   host + "/ipark/webmsg.do?token=" + sessionStorage.token
        // );
        // ws.addEventListener("error", function () {
        //   state.status = "error";
        // });

        // // Listen for messages
        // ws.addEventListener("message", function (event) {
        //   console.log("Message from server ", event.data);
        // });
        formRef.value?.validate().then((valid: boolean) => {
          if (valid) {
            getPercentage(
              function (ws) {
                state.loading = true;
                state.status = "";
                state.percentage = 0;
                return ipark_servicepark_importTaxesData({
                  taxPeriod: state.form.taxPeriod,
                  file: state.form.file,
                })
                  .then(
                    () => {
                      state.show = false;
                      onSeach();
                    },
                    () => {
                      ws.close();
                    }
                  )
                  .finally(() => {
                    state.loading = false;
                  });
              },
              function (data) {
                if (data.type === "status") {
                  state.status = data.msg;
                } else {
                  state.percentage = data.msg;
                }
              }
            );
          } else {
            return false;
          }
        });
      },
      onUpload() {
        state.show = true;
      },
      request(options: AnyObject) {
        console.log(options);
        debugger;
      },
      getData(param: Iparam) {
        state.page = param.index;
        if (state.data.length > 0) {
          return new Promise<{ data: AnyObject[]; total: number }>(
            (resolve) => {
              setTimeout(() => {
                resolve(getSlice(param.index, param.size, state.data));
              }, 100);
            }
          );
        } else {
          return ipark_servicepark_taxRevenueByMonth({
            taxpayerId: state.dataFilter.keyword,
            page: param.index,
            per_page: param.size,
          }).then(({ data }) => {
            // if (chart.value && !haspaint) {
            //   initChart(chart.value, data.chat);
            //   haspaint = true;
            // }
            state.data = data.rows;
            return getSlice(param.index, param.size, data.rows);
          });
        }
      },
    };
  },
});
