
import {
  defineComponent,
  reactive,
  ref,
  PropType,
  watch,
  onMounted,
  onUpdated,
  computed,
  nextTick,
} from "vue";
import { InternalRuleItem } from "async-validator";
import { ElForm, ElInput } from "element-plus";
import { mobileRegex, idCard, chRegex, AnyObject } from "@/utils";
import {
  ocr_businessLicense,
  uias_getOrgUserList,
  ipark_servicepark_partnerInvitationSigned,
  ipark_dic_industrycategoryList,
  ipark_dic_enterprisetypeList,
} from "@/api";
import FileSelect from "../FileSelect/Index.vue";
import { getNewFormData, getNewFormRule, IForm, IRule } from "./utils";

export default defineComponent({
  components: {
    FileSelect,
  },
  props: {
    data: {
      type: Object as PropType<Partial<IForm>>,
      default: () => ({}),
    },
    lock: {
      type: Boolean,
      default: false,
    },
    markEdit: {
      type: Boolean,
      default: false,
    },
    rule: {
      type: Object as PropType<Partial<IRule>>,
      default: () => ({}),
    },
  },
  emits: ["filechange"],
  setup(props, { expose, emit }) {
    const formRef = ref<typeof ElForm | null>(null);
    const codeRef = ref<typeof ElInput | null>(null);
    const verifycodeRef = ref<typeof ElInput | null>(null);
    const state = reactive({
      ocr: false,
      warningTips: "",
      enterpriseType: [] as AnyObject[],
      industryOptions: {
        freqList: [
          { industryCode: "code", industryName: "name" },
        ] as AnyObject[],
        totalList: [
          { industryCode: "code", industryName: "name" },
        ] as AnyObject[],
      },
      attractpartners: [] as AnyObject[],
      parkaccessers: [] as AnyObject[],
      form: getNewFormData(props.data),
      noEdit: getNewFormRule({
        attractprotocol: true,
      }),
      enterprisefileMust: false,
      verifycodeType: "text",
    });
    function validator() {
      return new Promise((reslove, reject) => {
        formRef.value?.validate((isValid: boolean, obj: AnyObject) => {
          if (isValid) {
            reslove(state.form);
          } else {
            reject(obj);
            return false;
          }
        });
      });
    }
    watch(
      () => props.data,
      () => {
        state.form = {
          ...state.form,
          ...props.data,
        };
        state.warningTips = "";

        nextTick(() => {
          formRef.value?.clearValidate();
          state.noEdit = getNewFormRule({
            attractprotocol: true,
          });
        });
      },
      {
        immediate: true,
      }
    );
    watch(
      () => state.form.enterprisefile,
      () => {
        if (
          !state.ocr &&
          state.form.enterprisefile &&
          state.form.enterprisefile instanceof File
        ) {
          state.ocr = true;
          ocr_businessLicense({
            file: state.form.enterprisefile,
          })
            .then(({ data }) => {
              state.form.enterprisescope = data.activitiesScope;
              state.form.enterprisetype = data.enterpriseTypeCode;
              // state.form.enterpriseregistrationDate = data.establishmentDate
              //   .replace("日", "")
              //   .replace(/[年月]/g, "-");
              state.form.establishmentDate = data.establishmentDate
                .replace("日", "")
                .replace(/[年月]/g, "-");
              state.form.legalpersonName = data.legalRepresentativeName;
              state.form.enterpriseaddress = data.registAddress;
              state.form.enterprisecode = data.taxpayerId;
              state.form.enterprisename = data.taxpayerName;
            })
            .finally(() => {
              state.ocr = false;
            });
        }
        emit("filechange", state.form.enterprisefile);
      },
      {
        immediate: true,
      }
    );
    onMounted(() => {
      Promise.all([
        uias_getOrgUserList({
          appid: "com.hive.ipark",
        }),
        ipark_servicepark_partnerInvitationSigned(),
        ipark_dic_enterprisetypeList(),
        ipark_dic_industrycategoryList(),
      ]).then(([accesser, partner, enterprisetype, industrycategory]) => {
        state.attractpartners = partner.data.registedList;
        state.parkaccessers = accesser.data.userList;
        state.enterpriseType = enterprisetype.data.result;
        state.industryOptions = industrycategory.data;
      });
    });
    onUpdated(() => {
      const inputcode = codeRef.value?.$refs.input;
      const inputverify = verifycodeRef.value?.$refs.input;
      if (inputcode && !inputcode.oncopy) {
        inputcode.oncopy = function () {
          return false;
        };
        inputcode.oncut = function () {
          return false;
        };
      }
      if (inputverify && !inputverify.onpaste) {
        inputverify.onpaste = function () {
          return false;
        };
      }
    });
    expose({
      validator,
    });
    return {
      state,
      formRef,
      codeRef,
      verifycodeRef,
      onInput(field: keyof IRule) {
        if (!props.markEdit) return;
        if (
          "enterprisename;enterpriseregistrationDate;enterpriseaddress;enterprisescope;legalpersonName".indexOf(
            field
          ) !== -1
        ) {
          state.enterprisefileMust = true;
          if (!(state.form.enterprisefile instanceof File)) {
            state.form.enterprisefile = "";
          }
        }
        state.noEdit[field] = false;
      },
      onFocus() {
        state.verifycodeType = "password";
      },
      onBlur() {
        state.verifycodeType = "text";
      },
      rules: computed(() => {
        const rule = getNewFormRule(props.rule);
        nextTick(() => {
          formRef.value?.clearValidate();
          state.noEdit = getNewFormRule({
            attractprotocol: true,
          });
        });
        return {
          enterprisefile: [
            {
              required: state.enterprisefileMust || rule.enterprisefile,
              message: "请选择营业执照！",
              trigger: "change",
            },
            {
              // required: true,
              validator: function (
                rule: InternalRuleItem,
                value: File,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  const result = /\.[\w]*$/.exec(value.name);
                  if (
                    result &&
                    ".jpeg,.jpg"
                      .toLowerCase()
                      .indexOf(result[0].toLowerCase()) !== -1
                  ) {
                    callback();
                  } else {
                    callback(new Error("请选择jpg格式的图片"));
                  }
                } else {
                  callback(new Error("请选择营业执照！"));
                }
              },
              trigger: "change",
            },
          ],
          enterprisename: [
            {
              required: rule.enterprisename,
              message: "请输入企业名称！",
              trigger: "blur",
            },
          ],
          enterprisecode: [
            {
              required: rule.enterprisecode,
              validator: function (
                rule: InternalRuleItem,
                value: string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                state.warningTips = "";
                if (value) {
                  if (value.length !== 18) {
                    callback(new Error("企业税号必须18位字符！"));
                  } else {
                    if (/[IOVZS]/.test(value)) {
                      callback(
                        new Error("税号不能包含I、O、V、Z、S，请核对！")
                      );
                    } else {
                      if (/[B8]/.test(value)) {
                        state.warningTips = "B和8易混淆，请仔细核对税号！";
                      } else {
                        state.warningTips = "";
                      }
                      callback();
                    }
                  }
                } else {
                  callback(new Error("请输入企业税号！"));
                }
              },
              trigger: "blur",
            },
          ],
          verifycode: [
            {
              required: rule.verifycode,
              validator: function (
                rule: InternalRuleItem,
                value: string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  if (value.length !== 18) {
                    callback(new Error("企业税号必须18位字符！"));
                  } else {
                    if (
                      state.form.enterprisecode &&
                      state.form.enterprisecode !== value
                    ) {
                      callback(new Error("两次输入不一致！"));
                    } else {
                      callback();
                    }
                  }
                } else {
                  callback(new Error("请输入税号验证！"));
                }
              },
              trigger: "blur",
            },
          ],
          enterprisetype: [
            {
              required: rule.enterprisetype,
              message: "请选择企业类型！",
              trigger: "change",
            },
          ],
          enterprisebusiness: [
            {
              required: rule.enterprisebusiness,
              message: "请选择主营行业！",
              trigger: "change",
            },
          ],
          enterprisescope: [
            {
              required: rule.enterprisescope,
              message: "请输入经营范围！",
              trigger: "change",
            },
          ],
          enterpriseaddress: [
            {
              required: rule.enterpriseaddress,
              message: "请输入注册地址！",
              trigger: "change",
            },
          ],
          enterpriseregistrationDate: [
            {
              required: rule.enterpriseregistrationDate,
              message: "请选择注册日期！",
              trigger: "change",
            },
          ],
          establishmentDate: [
            {
              required: rule.enterpriseregistrationDate,
              message: "请选择成立日期！",
              trigger: "change",
            },
          ],
          legalpersonName: [
            {
              required: rule.legalpersonName,
              validator: function (
                rule: InternalRuleItem,
                value: string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  if (chRegex.test(value)) {
                    callback();
                  } else {
                    callback(new Error("姓名格式不正确！"));
                  }
                } else {
                  callback(new Error("请输入法人姓名"));
                }
              },
              trigger: "blur",
            },
          ],
          legalpersonNo: [
            {
              required: rule.legalpersonNo,
              validator: function (
                rule: InternalRuleItem,
                value: string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  if (idCard.test(value)) {
                    callback();
                  } else {
                    callback(new Error("身份证号格式不正确！"));
                  }
                } else {
                  callback(new Error("请输入法人身份证号"));
                }
              },
              trigger: "blur",
            },
          ],
          legalpersonMobile: [
            {
              required: rule.legalpersonMobile,
              validator: function (
                rule: InternalRuleItem,
                value: string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  if (mobileRegex.test(value)) {
                    callback();
                  } else {
                    callback(new Error("手机号格式不正确！"));
                  }
                } else {
                  callback(new Error("请输入法人手机号"));
                }
              },
              trigger: "blur",
            },
          ],
          contactsName: [
            {
              required: rule.contactsName,
              validator: function (
                rule: InternalRuleItem,
                value: string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  if (chRegex.test(value)) {
                    callback();
                  } else {
                    callback(new Error("姓名格式不正确！"));
                  }
                } else {
                  callback(new Error("请输入企业联系人姓名"));
                }
              },
              trigger: "blur",
            },
          ],
          contactsMobile: [
            {
              required: rule.contactsMobile,
              validator: function (
                rule: InternalRuleItem,
                value: string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  if (mobileRegex.test(value)) {
                    callback();
                  } else {
                    callback(new Error("手机号格式不正确！"));
                  }
                } else {
                  callback(new Error("请输入企业联系人手机号"));
                }
              },
              trigger: "blur",
            },
          ],
          attractpartner: [
            {
              required: rule.attractpartner,
              // validator: function (
              //   rule: InternalRuleItem,
              //   value: string,
              //   callback: (arg?: Error) => void
              // ) {
              //   if (value) {
              //     if (chRegex.test(value)) {
              //       callback();
              //     } else {
              //       callback(new Error("姓名格式不正确！"));
              //     }
              //   } else {
              //     callback(new Error("请输入企业联系人姓名"));
              //   }
              // },
              message: "请选择企业联系人姓名",
              trigger: "change",
            },
          ],
          parkaccesser: [
            {
              required: rule.parkaccesser,
              message: "请选择企业联系人姓名",
              trigger: "change",
            },
          ],
          attractprotocol: [
            {
              required: rule.attractprotocol,
              validator: function (
                rule: InternalRuleItem,
                value: File,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  const result = /\.[\w]*$/.exec(value.name);
                  if (
                    result &&
                    ".pdf".toLowerCase().indexOf(result[0].toLowerCase()) !== -1
                  ) {
                    callback();
                  } else {
                    callback(new Error("请选择pdf格式的协议文件"));
                  }
                } else {
                  callback(new Error("请选择协议文件"));
                }
              },
              trigger: "change",
            },
          ],
        };
      }),
    };
  },
});
