
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    hasBack: {
      type: Boolean,
      default: false,
    },
    useHeader: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    return {
      goBack() {
        router.back();
      },
    };
  },
});
