export const fundMap = {
  valueAddedTax: "增值税",
  valueAdded: "增值税",
  constructionTax: "城建税",
  construction: "城建税",
  educationSupplementaryTax: "教育费附加",
  educationSupplementary: "教育费附加",
  localEducationSupplementaryTax: "地方教育费附加",
  localEducationSupplementary: "地方教育费附加",
  individualIncomeTax: "个人所得税",
  individualIncome: "个人所得税",
  corporateIncomeTax: "企业所得税",
  corporateIncome: "企业所得税",
  deedTax: "契税",
  deed: "契税",
  stampTax: "印花税",
  stamp: "印花税",
  cultureFund: "文化建设事业费",
  culture: "文化建设事业费",
  handicappedSecurityFund: "残疾人就业保障金",
  handicappedSecurity: "残疾人就业保障金",
  pensionInsuranceFund: "基本养老保险",
  pensionInsurance: "基本养老保险",
  medicalInsuranceFund: "基本医疗保险",
  medicalInsurance: "基本医疗保险",
  workInjuryInsuranceFund: "工商保险",
  workInjuryInsurance: "工商保险",
  birthInsuranceFund: "生育保险",
  birthInsurance: "生育保险",
  unemploymentInsuranceFund: "失业保险",
  unemploymentInsurance: "失业保险",
} as Record<string, string>;

export const downloadMap = {
  valueAddedTax: "增值税",
  constructionTax: "城市建设维护费",
  educationSupplementaryTax: "教育费附加",
  localEducationSupplementaryTax: "地方教育费附加",
  individualIncomeTax: "个人所得税",
  corporateIncomeTax: "企业所得税",
  deedTax: "契税",
  stampTax: "印花税",
  cultureFund: "文化建设事业费",
  handicappedSecurityFund: "残疾人就业保障金",
  // pensionInsuranceFund: "基本养老保险",
  // medicalInsuranceFund: "基本医疗保险",
  // workInjuryInsuranceFund: "工商保险",
  // birthInsuranceFund: "生育保险",
  // unemploymentInsuranceFund: "失业保险",
} as Record<string, string>;
