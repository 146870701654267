export type AnyObject = Record<string, any>;

export type AnyFunction<T> = (...args: any[]) => T;

const _cache: AnyObject = {};

export function getCache(key: string): any {
  return _cache[key];
}
export function setCache(key: string, val: any): any {
  _cache[key] = val;
  return val;
}
export function delCache(key: string): any {
  const tmp = _cache[key];
  delete _cache[key];
  return tmp;
}

export function clone(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

export function toNum(val: string | number): number {
  return typeof val === "number"
    ? val
    : parseFloat((val || "").replace(/,/g, ""));
}

export function isAbsHttp(url: string): boolean {
  return /^(https?:\/\/)/.test(url);
}

export function getURLName(url: string): string {
  /\/*([\w.]+\.\w+)$/.test(url);
  return RegExp.$1 || "文件";
}

export function getSlice(
  idx = 1,
  size = 10,
  data: AnyObject[]
): { data: AnyObject[]; total: number } {
  return {
    data: data.slice((idx - 1) * size, idx * size),
    total: data.length,
  };
}

export const UUIDGenerator = (): string =>
  (String(1e7) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      parseInt(c) ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (parseInt(c) / 4)))
    ).toString(16)
  );

export const currencyFormat = new Intl.NumberFormat("cmn-Hans-CN", {
  style: "currency",
  currency: "CNY",
  currencySign: "accounting",
});

const alink = document.createElement("a");

export function getUrlOrigin(url: string): string {
  alink.href = url;
  return alink.origin;
}

export function wipeoffUrlOrigin(url: string): string {
  const origin = getUrlOrigin(url);
  return url.replace(origin, "");
}

export function delay(times = 300): Promise<number> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(times);
    }, times);
  });
}

export const chRegex = /^(?:[\u4e00-\u9fa5·]{2,16})$/;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const qqRegex = /^[1-9][0-9]{4,10}$/;

export const mobileRegex = /^(?:(?:\+|00)86)?1\d{10}$/;

/* 密码强度校验，最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符 */
export const passwordRegex =
  /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/;

/* 用户名校验，4到16位（字母，数字，下划线，减号） */
export const usernameRegex = /^[a-zA-Z0-9_-]{4,16}$/;

/* 身份证号, 支持1/2代(15位/18位数字) */
export const idCard =
  /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;
