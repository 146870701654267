import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01c620cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-org" }
const _hoisted_2 = { class: "login-org-list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "login-org-list__item-media" }
const _hoisted_5 = { class: "login-org-list__item-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_ic_select = _resolveComponent("ic-select")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_scrollbar, { height: _ctx.height }, {
      default: _withCtx(() => [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.orgList, (itm, idx) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass([
            'login-org-list__item',
            itm.orgId === _ctx.state.active ? 'active' : '',
          ]),
              key: 'key_' + idx,
              onClick: ($event: any) => (_ctx.selectOrg(itm))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_el_avatar, {
                  size: 50,
                  src: itm.orgLogo
                }, null, 8, ["src"])
              ]),
              _createElementVNode("div", _hoisted_5, _toDisplayString(itm.orgName), 1),
              _withDirectives(_createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ic_select)
                ]),
                _: 2
              }, 1536), [
                [_vShow, itm.orgId === _ctx.state.active]
              ])
            ], 10, _hoisted_3))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["height"])
  ]))
}