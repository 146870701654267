import { AnyObject, isAbsHttp } from "@/utils";
import { postForm, upload, IRes, rsHost, URLcache_post } from "./utils";

/* 首页 */
/**
 * 2. 切换公司登陆
 * */
export function uias_switchToCorp(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/loginAsOrgUser.do", data);
}
/**
 * 验证用户
 * @method POST
 * @param appid  云上产业园在微信开放平台上登记的appid，固定值
 * @param code 微信第三方登录的code值
 * */
export function uias_wechat_oauth2_checkUser(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/wechat/oauth2/checkUser.do", data);
}
/**
 * 绑定微信/发送短信验证码
 * @method POST
 * @param mobile  手机号
 * */
export function uias_wechat_oauth2_sendVfCode(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/wechat/oauth2/sendVfCode.do", data);
}
/**
 * 绑定微信/发送短信验证码
 * @method POST
 * @param mobile  手机号
 * @param vfcode  验证码
 * @param code  微信扫码登录，此处微信用户 openid
 * */
export function uias_wechat_oauth2_addUser(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/wechat/oauth2/addUser.do", data);
}
/**
 * 作为机构用户登录
 * */
export function uias_userCorps(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/getLoginUserOrgList.do", data).then((res) => {
    res.data.orgList.forEach((val: AnyObject) => {
      val.orgLogo = isAbsHttp(val.orgLogo) ? val.orgLogo : rsHost + val.orgLogo;
    });
    return res;
  });
}
/**
 * 获取机构用户功能列表
 * */
export function uias_getOrgUserFunctionList(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/getOrgUserFunctionList.do", data);
}
/**
 * 获取个人信息
 * */
export function uias_getUser(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/getUser.do", data).then((res) => {
    const info = res.data.userInfo;
    info.userLogo = isAbsHttp(info.userLogo)
      ? info.userLogo
      : rsHost + info.userLogo;
    info.orgLogo = isAbsHttp(info.orgLogo)
      ? info.orgLogo
      : rsHost + info.orgLogo;
    info.orgBanner = isAbsHttp(info.orgBanner)
      ? info.orgBanner
      : rsHost + info.orgBanner;
    return res;
  });
}
/**
 * 修改用户名
 * */
export function uias_modifyUserName(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/modifyUserName.do", data);
}
/**
 * 修改用户头像
 * */
export function uias_modifyUserLogo(data?: AnyObject): Promise<IRes> {
  return upload("/uias/modifyUserLogo.do", data);
}
/**
 * 查询机构信息
 * */
export function uias_getOrg(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/getOrg.do", data);
}
/**
 * 修改机构名
 * */
export function uias_modifyOrgName(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/modifyOrgName.do", data);
}
/**
 * 修改机构logo
 * */
export function uias_modifyOrgLogo(data?: AnyObject): Promise<IRes> {
  return upload("/uias/modifyOrgLogo.do", data);
}
/**
 * 修改机构banner
 * */
export function uias_modifyOrgBanner(data?: AnyObject): Promise<IRes> {
  return upload("/uias/modifyOrgBanner.do", data);
}
/**
 * 获取机构背景banner列表中选择一个作为机构背景
 * */
export function uias_choiceOrgBanner(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/choiceOrgBanner.do", data);
}
/**
 * 获取机构背景banner列表
 * */
export function uias_getOrgBannerList(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/getOrgBannerList.do", data);
}

/**
 * 添加用户-选择角色
 * */
export function uias_getOrgUserChildRoleList(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/getOrgUserChildRoleList.do", data);
}
/**
 * 添加用户-添加用户
 * */
export function uias_addOrgUser(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/saveOrgUser.do", data);
}
/**
 * 添加用户-添加用户
 * */
export function uias_deleteOrgUser(data?: AnyObject): Promise<IRes> {
  return postForm("/uias/deleteOrgUser.do", data);
}
/**
 * 添加用户-添加用户
 * */
export function ipark_getLoginBanner(data?: AnyObject): Promise<IRes> {
  return postForm("/ipark/getLoginBanner.do", data);
}

/**
 * 企业类型字典-下拉列表
 * */
export function ipark_dic_enterprisetypeList(data?: AnyObject): Promise<IRes> {
  return URLcache_post("/ipark/dic/enterprisetypeList.do", data);
}
/**
 * 经营行业字典-下拉列表
 * */
export function ipark_dic_industrycategoryList(
  data?: AnyObject
): Promise<IRes> {
  return URLcache_post("/ipark/dic/industrycategoryList.do", data);
}
/**
 * 招商合伙人-下拉列表
 * */
export function ipark_servicepark_partnerInvitationSigned(
  data?: AnyObject
): Promise<IRes> {
  return URLcache_post("/ipark/servicepark/partnerInvitationSigned.do", data);
}

/**
 * 产业园列表
 * @param searchKeyword （可空）搜索关键字
 * @param page （可空）页码
 * @param per_page （可空）每页记录条数
 * */
export function ipark_servicepark_queryServiceParkList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/queryServiceParkList.do", data);
}

/**
 * 自然人纳税人列表
 * */
export function ipark_servicepark_queryPersonList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/queryPersonList.do", data);
}

/**
 * 自然人纳税人列表/下载
 * @method POST
 * */
export function ipark_servicepark_downloadPersonList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("ipark/servicepark/downloadPersonList.do", data, "blob");
}

/**
 * 保存企业信息（新增/更新）
 * @param fullName （非空）姓名
 * @param idNo （非空）身份证
 * @param mobile （非空）手机号
 * @param mobile （可空）备注
 * */
export function ipark_servicepark_savePerson(data?: AnyObject): Promise<IRes> {
  return upload("/ipark/servicepark/savePerson.do", data);
}

/**
 * （新增/修改）产业园
 * @param serviceParkCode （非空）产业园代码
 * @param serviceParkName （非空）产业园名称
 * @param serviceParkIntroduction （可空）产业园介绍
 * @param serviceParkAddress （非空）产业园地址
 * @param serviceParkGps （非空）产业园地图坐标
 * @param operatorCorpCode （非空）产业园运营管理公司税号
 * @param operatorCorpName （非空）产业园运营管理公司名称
 * @param director （非空）产业园负责人
 * @param directorMobile （非空）产业园负责人电话
 * @param file （非空）营业执照
 * */
export function ipark_servicepark_saveServicePark(
  data?: AnyObject
): Promise<IRes> {
  return upload("/ipark/servicepark/saveServicePark.do", data);
}

/**
 * （删除）自然人纳税人 Copy
 * @param idNo （非空）身份证
 * */
export function ipark_servicepark_deletePerson(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/deletePerson.do", data);
}

/**
 * 删除产业园
 * @param serviceParkCode （非空）搜索关键字
 * */
export function ipark_servicepark_deleteServicePark(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/deleteServicePark.do", data);
}

/**
 * 产业园联系人-下拉列表
 * @param appid com.hive.ipark
 * */
export function uias_getOrgUserList(data?: AnyObject): Promise<IRes> {
  return URLcache_post("/uias/getOrgUserList.do", data);
}
/**
 * 营业执照ocr识别
 * @param file 营业执照照片
 * */
export function ocr_businessLicense(data?: AnyObject): Promise<IRes> {
  return upload("/ocr/businessLicense.do", data);
}
/* 入园企业登记/变更 */
/**
 * 企业查询列表
 * @param enterpriseTypeCode （可空）企业类型，有限责任公司:01  股份有限公司:03  合伙企业:06  个人独资企业:07 个体工商户:08
 * @param industryCode （可空）行业代码
 * @param operateStatus （可空）企业经营状态，1：续存、O：迁移、X：注销
 * @param beginDate （可空）起始日期，格式：yyyyMM
 * @param endDate （可空）结束日期，格式：yyyyMM
 * @param keyword （可空）搜索关键字
 * @param page （可空）页码
 * @param per_page （可空）每页记录条数
 * */
export function ipark_servicepark_enterpriseList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseList.do", data);
}
/**
 * 企业详情
 * @param taxpayerId （非空）企业税号、统一社会信用代码
 * */
export function ipark_servicepark_enterpriseRegistInfo(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseRegistInfo.do", data);
}

/**
 * 保存企业信息（新增/更新）
 * @param licensefile （可空）营业执照文件
 * @param contractfile （可空）企业入园协议
 * @param taxpayerName （非空）企业名
 * @param taxpayerId （非空）企业税号
 * @param enterpriseTypeCode （非空）企业类型code
 * @param industryCode （非空）主营行业code
 * @param activitiesScope （非空）经营范围
 * @param registAddress （非空）注册地址
 * @param establishmentDate （非空）成立日期
 * @param registionDate （非空）注册日期
 * @param legalRepresentativeName （非空）法人姓名
 * @param legalRepresentativeIdNo （非空）法人身份证
 * @param legalRepresentativeMobile （非空）法人手机号
 * @param enterpriseLiaisonName （非空）企业负责人姓名
 * @param enterpriseLiaisonMobile （非空）企业负责人电话
 * @param partnerId （非空）招商合伙人id
 * @param partnerName （新增时非空，更新时可空）招商合伙人姓名
 * @param serviceParkLiaisonMobile （非空）产业园联系人id
 * @param serviceParkLiaisonName （新增时非空，更新时可空）产业园联系人姓名
 * */
export function ipark_servicepark_saveEnterprise(
  data?: AnyObject
): Promise<IRes> {
  return upload("/ipark/servicepark/saveEnterprise.do", data);
}
/* 入园企业登记/变更 */
/**
 * 查询企业上传财务报表情况
 * @param page （可空）分页,页码
 * @param per_page （可空）分页，每页行数
 * @param taxpayerId （可空）企业税号、统一社会信用代码
 * */
export function ipark_servicepark_queryEnterpriseFinancialStatementsList(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/queryEnterpriseFinancialStatementsList.do",
    data
  );
}
/**
 * 查询某一税期内，企业需上传财务报表的列表
 * @param page （可空）分页,页码
 * @param per_page （可空）分页，每页行数
 * @param yearmonth （非空）税期 格式：yyyymm
 * @param keyword （可空）查询关键字
 * */
export function ipark_servicepark_queryEnterpriseFinancialStatementsListByTaxPeriod(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/queryEnterpriseFinancialStatementsListByTaxPeriod.do",
    data
  );
}
/**
 * 获取企业某一年月财务报表详情
 * @param yearmonth （非空）税期 格式：yyyymm
 * @param taxpayerId （非空）纳税人识别号
 * */
export function ipark_servicepark_getFinancialStatements(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/getFinancialStatements.do", data);
}
/**
 * 保存企业上传的财务报表（新增/修改）
 * @param yearmonth （非空）税期 格式：yyyymm
 * @param taxpayerId （非空）纳税人识别号
 * @param file0 （新增时非空，更新时可空） 合并的财务报表
 * @param file1 （新增时非空，更新时可空）资产负债表
 * @param file2 （新增时非空，更新时可空）损益表
 * @param file3 （新增时非空，更新时可空）现金流量表
 * @param businessIncome （新增时非空，更新时可空）营业收入
 * */
export function ipark_servicepark_saveFinancialStatements(
  data?: AnyObject
): Promise<IRes> {
  return upload("/ipark/servicepark/saveFinancialStatements.do", data);
}
/**
 * 删除企业某一年月的财务报表
 * @param statementsId （非空）财报记录id
 * */
export function ipark_servicepark_delFinancialStatements(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/delFinancialStatements.do", data);
}
/**
 * 企业查询列表/下载Excel
 * @method POST
 * @param enterpriseTypeCode （可空）企业类型，有限责任公司:01  股份有限公司:03  合伙企业:06  个人独资企业:07 个体工商户:08
 * @param industryCode （可空）行业代码
 * @param operateStatus （可空）企业经营状态，1：续存、O：迁移、X：注销
 * @param beginDate （可空）起始日期，格式：yyyyMM
 * @param endDate （可空）结束日期，格式：yyyyMM
 * @param keyword （可空）搜索关键字
 * */
export function ipark_servicepark_enterpriseList_download(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/enterpriseList/download.do",
    data,
    "blob"
  );
}
// 上传企业缴税数据
/**
 * 园区产税分月统计
 * @param page （可空）页码
 * @param per_page （非空）每页记录条数
 * */
export function ipark_servicepark_taxRevenueByMonth(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/taxRevenueByMonth.do", data);
}

/**
 * 园区产税分月详情
 * @method POST
 * @param taxPeriodStart（可空）税款所属期起
 * @param taxPeriodEnd（可空）税款所属期止
 * @param searchKeyword（可空）企业名称
 * @param page （可空）页码
 * @param per_page （可空）每页记录条数
 * */
export function ipark_servicepark_queryEnterpriseTaxDetailList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/queryEnterpriseTaxDetailList.do", data);
}
/**
 * 获取《企业缴税数据模板》下载链接
 * @method POST
 * */
export function ipark_servicepark_getTaxesTemplateURL(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/getTaxesTemplateURL.do", data);
}
/**
 * 上传企业缴税数据
 * @method POST
 * @param taxPeriod （非空）税款所属期
 * @param file （非空）企业缴税数据文件
 * */
export function ipark_servicepark_importTaxesData(
  data?: AnyObject
): Promise<IRes> {
  return upload("/ipark/servicepark/importTaxesData.do", data);
}
/**
 * 上传企业缴税数据
 * @method POST
 * @param taxPeriod （非空）税款所属期
 * */
export function ipark_servicepark_deleteTaxByPeriod(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/deleteTaxByPeriod.do", data);
}
/**
 * 园区产税分月详情/下载数据
 * @method POST
 * @param taxPeriodStart （非空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （非空）结束日期，格式：yyyyMM
 * */
export function ipark_servicepark_downloadTaxData(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/downloadTaxData.do", data, "blob");
}
/* 注销/迁出企业 */
/**
 * 注销/迁出企业列表
 * @method POST
 * @param enterpriseTypeCode （可空）企业类型，有限责任公司:01  股份有限公司:03  合伙企业:06  个人独资企业:07 个体工商户:08
 * @param industryCode （可空）行业代码
 * @param beginDate （可空）起始日期，格式：yyyyMM
 * @param endDate （可空）结束日期，格式：yyyyMM
 * @param keyword （可空）搜索关键字
 * @param page （可空）页码
 * @param per_page （可空）每页记录条数
 * */
export function ipark_servicepark_cancelEnterpriseList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/cancelEnterpriseList.do", data);
}
/**
 * 添加注销/迁出企业
 * @method POST
 * @param taxpayerId （非空）企业税号
 * @param operateStatus （非空）注销： X   |   迁出： O
 * @param cancelDate （非空）注销/迁出日期
 * @param file （可空）注销/迁出相关文件
 * */
export function ipark_servicepark_addCancelEnterprise(
  data?: AnyObject
): Promise<IRes> {
  return upload("/ipark/servicepark/addCancelEnterprise.do", data);
}
/**
 * 企业查询列表/下载Excel
 * @method POST
 * @param enterpriseTypeCode （可空）企业类型，有限责任公司:01  股份有限公司:03  合伙企业:06  个人独资企业:07 个体工商户:08
 * @param industryCode （可空）行业代码
 * @param beginDate （可空）起始日期，格式：yyyyMM
 * @param endDate （可空）结束日期，格式：yyyyMM
 * @param keyword （可空）搜索关键字
 * */
export function ipark_servicepark_cancelEnterpriseList_download(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/cancelEnterpriseList/download.do",
    data,
    "blob"
  );
}
/* 财政奖励 */
/**
 * 财政奖励基数列表
 * @method POST
 * @param page （非空）页码
 * @param per_page （非空）每页记录条数
 * @param keyword （可空）搜索关键字
 * */
export function ipark_servicepark_queryTaxRewardList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/queryTaxRewardList.do", data);
}
/**
 * 财政奖励基数批次详情（企业）
 * @method POST
 * @param page （非空）页码
 * @param per_page （非空）每页记录条数
 * @param taxPeriodStart （可空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （可空）结束日期，格式：yyyyMM
 * @param keyword （可空）搜索关键字
 * */
export function ipark_servicepark_queryCorpTaxRewardDetailByBatch(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/queryCorpTaxRewardDetailByBatch.do",
    data
  );
}
/**
 * 财政奖励基数批次详情（个体）
 * @method POST
 * @param page （非空）页码
 * @param per_page （非空）每页记录条数
 * @param taxPeriodStart （可空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （可空）结束日期，格式：yyyyMM
 * @param keyword （可空）搜索关键字
 * */
export function ipark_servicepark_queryIndividualTaxRewardDetailByBatch(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/queryIndividualTaxRewardDetailByBatch.do",
    data
  );
}
/**
 * 按批次删除财政奖励
 * @method POST
 * @param taxPeriodStart （可空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （可空）结束日期，格式：yyyyMM
 * */
export function ipark_servicepark_delTaxRewardByBatch(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/delTaxRewardByBatch.do", data);
}
/**
 * 下载企业缴税数据
 * @method POST
 * @param taxPeriodStart （可空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （可空）结束日期，格式：yyyyMM
 * @param taxTypes （可空）可返还的税种
 * */
export function ipark_servicepark_downloadTaxSumData(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/downloadTaxSumData.do", data, "blob");
}
/**
 * 上传奖励基数
 * @method POST
 * @param taxPeriodStart （可空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （可空）结束日期，格式：yyyyMM
 * @param file （可空）企业缴税数据文件
 * */
export function ipark_servicepark_importTaxRewardData(
  data?: AnyObject
): Promise<IRes> {
  return upload("/ipark/servicepark/importTaxRewardData.do", data);
}
/* 上传扶持企业数据 */
/**
 * 企业扶持批次列表
 * @method POST
 * @param page （非空）页码
 * @param per_page （非空）每页记录条数
 * @param keyword （可空）搜索关键字
 * */
export function ipark_servicepark_queryEnterpriseRewardList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/queryEnterpriseRewardList.do", data);
}
/**
 * 企业扶持批次详情
 * @method POST
 * @param page （非空）页码
 * @param per_page （非空）每页记录条数
 * @param taxPeriodStart （可空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （可空）结束日期，格式：yyyyMM
 * @param keyword （可空）搜索关键字
 * */
export function ipark_servicepark_queryEnterpriseRewardDetail(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/queryEnterpriseRewardDetail.do", data);
}
/**
 * 删除企业扶持批次详情
 * @method POST
 * @param taxPeriodStart （非空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （非空）结束日期，格式：yyyyMM
 * */
export function ipark_servicepark_deleteEnterpriseRewardDetail(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/deleteEnterpriseRewardDetail.do", data);
}
/**
 * 下载企业返税数据模板
 * @method POST
 * @param taxPeriodStart （非空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （非空）结束日期，格式：yyyyMM
 * */
export function ipark_servicepark_downLoadEnterpriseRewardTemplate(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/downLoadEnterpriseRewardTemplate.do",
    data,
    "blob"
  );
}
/**
 * 上传企业扶持数据
 * @method POST
 * @param taxPeriodStart （非空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （非空）结束日期，格式：yyyyMM
 * @param file （非空）企业缴税数据文件
 * */
export function ipark_servicepark_importEnterpriseRewardData(
  data?: AnyObject
): Promise<IRes> {
  return upload("/ipark/servicepark/importEnterpriseRewardData.do", data);
}
/**
 * 企业扶持批次详情/下载Excel
 * @method POST
 * @param taxPeriodStart （可空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （可空）结束日期，格式：yyyyMM
 * */
export function ipark_servicepark_queryEnterpriseRewardDetail_download(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/queryEnterpriseRewardDetail/download.do",
    data,
    "blob"
  );
}
/* 上传招商奖励数据 */
/**
 * 招商奖励批次列表
 * @method POST
 * @param page （非空）页码
 * @param per_page （非空）每页记录条数
 * @param keyword （可空）搜索关键字
 * */
export function ipark_servicepark_queryPartnerRewardList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/queryPartnerRewardList.do", data);
}
/**
 * 招商奖励批次详情
 * @method POST
 * @param page （非空）页码
 * @param per_page （非空）每页记录条数
 * @param taxPeriodStart （可空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （可空）结束日期，格式：yyyyMM
 * @param keyword （可空）搜索关键字
 * */
export function ipark_servicepark_queryPartnerRewardDetail(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/queryPartnerRewardDetail.do", data);
}
/**
 * 删除招商奖励批次详情
 * @method POST
 * @param taxPeriodStart （非空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （非空）结束日期，格式：yyyyMM
 * */
export function ipark_servicepark_deletePartnerRewardDetail(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/deletePartnerRewardDetail.do", data);
}
/**
 * 下载招商奖励数据模板
 * @method POST
 * @param taxPeriodStart （非空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （非空）结束日期，格式：yyyyMM
 * */
export function ipark_servicepark_downLoadPartnerRewardTemplate(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/downLoadPartnerRewardTemplate.do",
    data,
    "blob"
  );
}
/**
 * 上传招商奖励数据
 * @method POST
 * @param taxPeriodStart （非空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （非空）结束日期，格式：yyyyMM
 * @param file （非空）企业缴税数据文件
 * */
export function ipark_servicepark_importPartnerRewardData(
  data?: AnyObject
): Promise<IRes> {
  return upload("/ipark/servicepark/importPartnerRewardData.do", data);
}
/**
 * 招商奖励批次详情/下载Excel
 * @method POST
 * @param taxPeriodStart （可空）起始日期，格式：yyyyMM
 * @param taxPeriodEnd （可空）结束日期，格式：yyyyMM
 * */
export function ipark_servicepark_queryPartnerRewardDetail_download(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/queryPartnerRewardDetail/download.do",
    data,
    "blob"
  );
}
/* 招商宣传 */
/**
 * 宣传册列表
 * @method POST
 * @param page （非空）页码
 * @param per_page （非空）每页记录条数
 * */
export function ipark_servicepark_brochureList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/brochureList.do", data);
}
/**
 * 宣传册详情
 * @method POST
 * @param brochureId （非空）宣传册id
 * */
 export function ipark_servicepark_getBrochure(data?: AnyObject): Promise<IRes> {
  return postForm("/ipark/servicepark/getBrochure.do", data);
}
/**
 * 创建宣传册
 * @method POST
 * @param title （非空）宣传册标题
 * @param brochureType （非空）A:园区宣传册 B:招商伙伴招募宣传册 C: 解决方案
 * */
export function ipark_servicepark_createBrochure(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/createBrochure.do", data);
}
/**
 * 删除宣传册
 * @method POST
 * @param brochureId （非空）宣传册id
 * */
export function ipark_servicepark_deleteBrochure(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/deleteBrochure.do", data);
}
/**
 * (更新/保存)宣传册封面
 * @method POST
 * @param brochureId （非空）宣传册id
 * @param title （非空）宣传册标题
 * @param summary （非空）宣传册摘要
 * @param cover （非空）宣传图片封面
 * */
export function ipark_servicepark_updateCover(data?: AnyObject): Promise<IRes> {
  return upload("/ipark/servicepark/updateCover.do", data);
}
/**
 * (更新/保存)宣传册图文内容
 * @method POST
 * @param brochureId （非空）宣传册id
 * @param htmContent （非空）宣传册图文内容
 * */
export function ipark_servicepark_updateHtmlContent(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/updateHtmlContent.do", data);
}
/**
 * (更新/保存)宣传册投屏宣讲图片
 * @method POST
 * @param brochureId （非空）宣传册id
 * @param imgContent （非空）投屏宣讲图片Json数组格式
 * */
export function ipark_servicepark_updateImageContent(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/updateImageContent.do", data);
}
/**
 * (添加)投屏图片
 * @method POST
 * @param brochureId （非空）宣传册id
 * @param img （非空）图片
 * */
export function ipark_servicepark_uploadImgContent(
  data?: AnyObject
): Promise<IRes> {
  return upload("/ipark/servicepark/uploadImgContent.do", data);
}
/**
 * 发布宣传册
 * @method POST
 * @param brochureId （非空）宣传册id
 * */
export function ipark_servicepark_publishBrochure(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/publishBrochure.do", data);
}

/* 服务热线 */
/**
 * 服务热线列表
 * @method POST
 * */
export function ipark_servicepark_serviceParkCustomerServiceList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/serviceParkCustomerServiceList.do", data);
}
/**
 * （新增/修改）新增热线
 * @method POST
 * @param jsonStr （非空）json字符串
 * */
export function ipark_servicepark_saveServiceParkCustomerService(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/saveServiceParkCustomerService.do", data);
}
/**
 * （删除）新增热线
 * @method POST
 * @param title （非空）热线名称
 * */
export function ipark_servicepark_deleteServiceParkCustomerService(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/deleteServiceParkCustomerService.do",
    data
  );
}
/* 招商伙伴 */
/**
 * 招商伙伴列表
 * @method POST
 * */
export function ipark_servicepark_queryPartnerList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/queryPartnerList.do", data);
}
/**
 * 添加招商伙伴
 * @method POST
 * @param name （非空）招商伙伴名称
 * @param mobile （非空）招商伙伴手机
 * */
export function ipark_servicepark_addPartner(data?: AnyObject): Promise<IRes> {
  return postForm("/ipark/servicepark/addPartner.do", data);
}
/**
 * 删除招商伙伴
 * @method POST
 * @param mobile （非空）招商伙伴手机
 * */
export function ipark_servicepark_deletePartner(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/deletePartner.do", data);
}
/* 首页 */
/**
 * 园区企业关键字搜索-下拉列表
 * @method POST
 * @param keyword （非空）搜索关键字
 * */
export function servicepark_enterpriseSearch(data?: AnyObject): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseSearch.do", data);
}

/**
 * 园区负责人/企业详情/工商登记信息
 * @method POST
 * @param taxpayerId （非空）企业税号、统一社会信用代码
 * */
export function servicepark_enterpriseRegistInfo(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseRegistInfo.do", data);
}

/**
 * 企业负责人/企业详情/企业财报
 * @method POST
 * @param taxpayerId （非空）纳税人识别号
 * @param page （可空）页码
 * @param per_page （可空）每页记录条数
 * */
export function servicepark_enterprisefinancialStatementsList(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/enterprisefinancialStatementsList.do",
    data
  );
}
/**
 * 园区负责人/企业详情/缴税信息
 * @method POST
 * @param taxpayerId（非空）企业税号、统一社会信用代码
 * @param page （可空）页码
 * @param per_page （可空）每页记录条数
 * */
export function ipark_servicepark_enterpriseTaxInfo(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseTaxInfo.do", data);
}

/**
 * 园区负责人/企业详情/某期分税种缴税详情
 * @method POST
 * @param taxpayerId（非空）企业税号、统一社会信用代码
 * @param taxPeriod （非空）税款所属期
 * */
export function ipark_servicepark_enterpriseTaxDetail(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseTaxDetail.do", data);
}

/**
 * 园区负责人/企业名录（成功）
 * @method POST
 * @param enterpriseTypeCode （可空）企业类型，有限责任公司:01  股份有限公司:03  合伙企业:06  个人独资企业:07 个体工商户:08
 * @param operateStatus （可空）企业经营状态，1：续存、X：注销
 * @param beginDate （可空）起始日期，格式：yyyyMM
 * @param endDate （可空）结束日期，格式：yyyyMM
 * @param keyword （可空）搜索关键字
 * @param page （可空）页码
 * @param per_page （可空）每页记录条数
 * */
export function servicepark_enterpriseList(data?: AnyObject): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseList.do", data);
}

/**
 * 园区负责人/入驻企业统计/增长趋势（成功）
 * @method POST
 * @param page （可空）页码
 * @param per_page （可空）每页记录条数
 * */
export function ipark_servicepark_growthtrend(data?: AnyObject): Promise<IRes> {
  return postForm("/ipark/servicepark/growthtrend.do", data);
}

/**
 * 园区负责人/扶持企业/返税按月统计（有数据）
 * */
export function ipark_servicepark_enterpriseRewardList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseRewardList.do", data);
}
/**
 * 2. 园区产税（本年、同比、月税收统计）
 * */
export function ipark_servicepark_taxRevenueStatistics_month(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/taxRevenueStatistics/month.do", data);
}
/**
 * 园区负责人/入驻企业统计
 * */
export function ipark_servicepark_registrationStatistics(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/registrationStatistics.do", data);
}

/**
 * 园区负责人/入驻企业统计/无税户（成功）
 * */
export function ipark_servicepark_notaxEnterprise(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/notaxEnterprise.do", data);
}
/**
 * 园区负责人/入驻企业统计/分企业类型统计（成功）
 * */
export function ipark_servicepark_enterprisetypeList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterprisetypeList.do", data);
}

/**
 * 园区负责人/入驻企业搜索
 * */
export function ipark_servicepark_enterpriseSearch(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseSearch.do", data);
}

/**
 * 园区负责人/企业详情/招商信息
 * */
export function ipark_servicepark_enterprisePartnerInfo(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterprisePartnerInfo.do", data);
}
/**
 * 园区负责人/企业详情/返税信息
 * */
export function ipark_servicepark_enterpriseRewardInfo(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseRewardInfo.do", data);
}
/**
 * 园区负责人/企业详情/返税详情
 * */
export function ipark_servicepark_enterpriseRewardDetail(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseRewardDetail.do", data);
}
/**
 * 园区负责人/企业招商/已入驻列表
 * */
export function ipark_servicepark_enterpriseInvitationRegisted(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseInvitationRegisted.do", data);
}
/**
 * 园区负责人/企业招商/未入驻列表
 * */
export function ipark_servicepark_enterpriseInvitationNotRegist(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseInvitationNotRegist.do", data);
}
/**
 * 园区负责人/招商伙伴/详情/引荐企业
 * */
export function ipark_servicepark_partnerInviteEnterpriseList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/partnerInviteEnterpriseList.do", data);
}
/**
 * 园区负责人/招商伙伴/未签约列表
 * */
export function ipark_servicepark_partnerInvitationNotSign(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/partnerInvitationNotSign.do", data);
}
/**
 * 园区负责人/招商伙伴/确立合作关系
 * */
export function ipark_servicepark_createNewPartnershipByMobile(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/createNewPartnershipByMobile.do", data);
}
/**
 * 园区负责人/招商伙伴/确立合作关系/添加协议文件
 * */
export function ipark_servicepark_appendPartnershipContractFile(
  data?: AnyObject
): Promise<IRes> {
  return upload("/ipark/servicepark/appendPartnershipContractFile.do", data);
}

/**
 * 园区负责人/园区产税（卡片）
 * */
export function ipark_servicepark_taxRevenueCard(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/taxRevenueCard.do", data);
}
/**
 * 园区负责人/园区产税贡献排名(有数据)
 * */
export function ipark_servicepark_taxContributionRanking(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/taxContributionRanking.do", data);
}
/**
 * 园区负责人/园区产税分行业占比(有数据)
 * */
export function ipark_servicepark_taxProportionByIndustry(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/taxProportionByIndustry.do", data);
}
/**
 * 园区负责人/园区产税按税种占比(有数据)
 * */
export function ipark_servicepark_taxProportionByTaxCategory(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/taxProportionByTaxCategory.do", data);
}
/**
 * 园区负责人/园区产税按企业类型占比(有数据)
 * */
export function ipark_servicepark_taxProportionByEnterpriseType(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/taxProportionByEnterpriseType.do", data);
}

/**
 * 园区负责人/扶持企业（有数据）
 * */
export function ipark_servicepark_enterpriseRewardCard(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseRewardCard.do", data);
}

/**
 * 园区负责人/扶持企业/返税批次详情(有数据)
 * */
export function ipark_servicepark_enterpriseRewardBatch(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/enterpriseRewardBatch.do", data);
}

/**
 * 园区负责人/扶持企业/查询某个企业返税批次详情
 * */
export function ipark_servicepark_queryTaxpayerRewardDetailByBatch(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/queryTaxpayerRewardDetailByBatch.do",
    data
  );
}
/**
 * 园区负责人/招商奖励（有数据）
 * */
export function ipark_servicepark_partnerRewardCard(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/partnerRewardCard.do", data);
}
/**
 * 园区负责人/招商奖励按时间排序(有数据)
 * */
export function ipark_servicepark_partnerRewardListByPeroid(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/partnerRewardListByPeroid.do", data);
}
/**
 * 园区负责人/招商奖励按时间排序/按批次明细（有数据）
 * */
export function ipark_servicepark_partnerRewardDetailByPeroid(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/partnerRewardDetailByPeroid.do", data);
}
/**
 * 园区负责人/招商奖励按金额排序（有数据）
 * */
export function ipark_servicepark_partnerRewardListByPartner(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/partnerRewardListByPartner.do", data);
}
/**
 * 园区负责人/招商奖励按合伙人排序/批次明细(有数据)
 * */
export function ipark_servicepark_partnerRewardDetailByPartner(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/partnerRewardDetailByPartner.do", data);
}
/**
 * 园区负责人/招商合伙人某批次奖励来源(有数据)
 * */
export function ipark_servicepark_partnerRewardSource(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/partnerRewardSource.do", data);
}
/**
 * 园区负责人/获取招商奖励年份列表
 * */
export function ipark_servicepark_getPartnerRewardYearList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/getPartnerRewardYearList.do", data);
}
/**
 * 园区负责人/获取企业返税年份列表
 * */
export function ipark_servicepark_getEnterpriseRewardYearList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/getEnterpriseRewardYearList.do", data);
}
/**
 * 园区负责人/园区企业缴税查询
 * */
export function ipark_servicepark_queryEnterpriseTaxRevenue(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/queryEnterpriseTaxRevenue.do", data);
}

/**
 * 园区负责人/园区企业缴税查询/获取税款最近所属期
 * */
export function ipark_servicepark_getLatestTaxPeroid(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/getLatestTaxPeroid.do", data);
}

/**
 * 招商合伙人/引荐企业卡片（成功）
 * */
export function ipark_partner_enterpriseRegistionCount(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/enterpriseRegistionCount.do", data);
}
/**
 * 招商合伙人/企业名录/获取合作园区列表（有数据）
 * */
export function ipark_partner_getServiceParkList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/getServiceParkList.do", data);
}
/**
 * 招商合伙人/企业名录(有数据)
 * */
export function ipark_partner_enterpriseList(data?: AnyObject): Promise<IRes> {
  return postForm("/ipark/partner/enterpriseList.do", data);
}
/**
 * 招商合伙人/企业详情/工商登记信息（成功）
 * */
export function ipark_partner_enterpriseRegistInfo(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/enterpriseRegistInfo.do", data);
}
/**
 * 招商合伙人/企业详情/招商信息（成功）
 * */
export function ipark_partner_enterprisePartnerInfo(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/enterprisePartnerInfo.do", data);
}
/**
 * 招商合伙人/企业详情/缴税信息
 * */
export function ipark_partner_enterpriseTaxInfo(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/enterpriseTaxInfo.do", data);
}
/**
 * 招商合伙人/企业详情/缴税信息/分税种详情(成功)
 * */
export function ipark_partner_enterpriseTaxDetail(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/enterpriseTaxDetail.do", data);
}
/**
 * 招商合伙人/企业详情/返税信息（成功）
 * */
export function ipark_partner_enterpriseRewardInfo(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/enterpriseRewardInfo.do", data);
}
/**
 * 招商合伙人/企业详情/返税信息/分税种详情（成功）
 * */
export function ipark_partner_enterpriseRewardDetail(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/enterpriseRewardDetail.do", data);
}
/**
 * 招商合伙人/入驻企业统计/无税户（成功）
 * */
export function ipark_partner_notaxEnterprise(data?: AnyObject): Promise<IRes> {
  return postForm("/ipark/partner/notaxEnterprise.do", data);
}
/**
 * 招商合伙人/企业产税（有数据时）
 * */
export function ipark_partner_taxRevenueCard(data?: AnyObject): Promise<IRes> {
  return postForm("/ipark/partner/taxRevenueCard.do", data);
}
/**
 * 招商合伙人/企业产税分月统计(有数据)
 * */
export function ipark_partner_taxRevenueByMonth(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/taxRevenueByMonth.do", data);
}
/**
 * 招商合伙人/企业产税贡献排名(有数据)
 * */
export function ipark_partner_taxContributionRanking(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/taxContributionRanking.do", data);
}
/**
 * 招商合伙人/企业产税分行业占比(有数据)
 * */
export function ipark_partner_taxProportionByIndustry(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/taxProportionByIndustry.do", data);
}
/**
 * 招商合伙人/企业产税按税种占比(有数据)
 * */
export function ipark_partner_taxProportionByTaxCategory(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/taxProportionByTaxCategory.do", data);
}
/**
 * 招商合伙人/企业产税按企业类型占比(有数据)
 * */
export function ipark_partner_taxProportionByEnterpriseType(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/taxProportionByEnterpriseType.do", data);
}
/**
 * 招商合伙人/招商奖励（有数据）
 * */
export function ipark_partner_partnerRewardCard(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/partnerRewardCard.do", data);
}
/**
 * 招商合伙人/获取招商奖励年份列表（筛选条件）
 * */
export function ipark_partner_getRewardYearList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/getRewardYearList.do", data);
}
/**
 * 招商合伙人/招商奖励/按批次列表(有数据)
 * */
export function ipark_partner_partnerRewardList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/partnerRewardList.do", data);
}
/**
 * 招商合伙人/招商奖励/批次明细来源(有数据)
 * */
export function ipark_partner_partnerRewardSource(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/partnerRewardSource.do", data);
}
/**
 * 企业招商/已入驻列表
 * */
export function ipark_partner_enterpriseInvitationRegisted(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/enterpriseInvitationRegisted.do", data);
}
/**
 * 企业招商/未入驻列表
 * */
export function ipark_partner_enterpriseInvitationNotRegist(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/enterpriseInvitationNotRegist.do", data);
}
/**
 * 企业招商/邀请企业负责人
 * */
export function ipark_partner_inviteEnterprise(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/inviteEnterprise.do", data);
}
/**
 * 企业招商/邀请企业负责人/加载企业扶持宣传册
 * */
export function ipark_partner_inviteEnterprise_loadBrochure(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/inviteEnterprise/loadBrochure.do", data);
}

/* 企业负责人卡片 */
/**
 * 企业负责人/运营收入（卡片）
 * */
export function ipark_enterprise_financialStatementsCard(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/enterprise/financialStatementsCard.do", data);
}
/**
 * 企业负责人/运营分月列表（有数据）
 * */
export function ipark_enterprise_financialStatementsList(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/enterprise/financialStatementsList.do", data);
}
/**
 * 企业负责人/企业缴税（卡片）
 * */
export function ipark_enterprise_taxRevenueCard(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/enterprise/taxRevenueCard.do", data);
}
/**
 * 企业负责人/缴税分月统计
 * */
export function ipark_enterprise_enterpriseTaxInfo(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/enterprise/enterpriseTaxInfo.do", data);
}
/**
 * 企业负责人/缴税分税种详情
 * */
export function ipark_enterprise_enterpriseTaxDetail(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/enterprise/enterpriseTaxDetail.do", data);
}
/**
 * 企业负责人/扶持奖励（卡片）
 * */
export function ipark_enterprise_enterpriseRewardCard(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/enterprise/enterpriseRewardCard.do", data);
}
/**
 * 企业负责人/扶持奖励列表
 * */
export function ipark_enterprise_enterpriseRewardInfo(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/enterprise/enterpriseRewardInfo.do", data);
}
/**
 * 企业负责人/企业扶持奖励详情
 * */
export function ipark_enterprise_enterpriseRewardDetail(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/enterprise/enterpriseRewardDetail.do", data);
}
/* 企业负责人功能 */

/**
 * 工商信息/工商登记信息
 * */
export function ipark_enterprise_enterpriseRegistInfo(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/enterprise/enterpriseRegistInfo.do", data);
}
/**
 * 工商信息/招商信息
 * */
export function ipark_enterprise_enterprisePartnerInfo(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/enterprise/enterprisePartnerInfo.do", data);
}

/**
 * 功能/合作协议/合作协议(有数据)
 * */
export function ipark_partner_cooperationContracts(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/cooperationContracts.do", data);
}

/**
 * 工商信息/入驻协议(有数据)
 * */
export function ipark_enterprise_enterpriseRewardContract(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/enterprise/enterpriseRewardContract.do", data);
}

/**
 * 园区负责人/宣传册/手机浏览版
 * */
export function ipark_servicepark_industrialParkBrochureAForRelease(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/industrialParkBrochureAForRelease.do",
    data
  );
}

/**
 * 园区负责人/宣传册/会场演示版
 * */
export function ipark_servicepark_industrialParkBrochureBForRelease(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/industrialParkBrochureBForRelease.do",
    data
  );
}

/**
 * 宣传册/产业园介绍分享
 * */
export function ipark_servicepark_industrialIntroduce(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/industrialIntroduce.do", data);
}

/**
 * 宣传册/企业宣传资料册分享邀请/加载宣传册
 * */
export function ipark_servicepark_inviteEnterprise_loadBrochure(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/inviteEnterprise/loadBrochure.do", data);
}

/**
 * 宣传册/合伙人招募宣传册分享邀请/加载宣传册
 * */
export function ipark_servicepark_invitePartner_loadBrochure(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/invitePartner/loadBrochure.do", data);
}

/**
 * 宣传册/企业宣传资料册分享邀请
 * */
export function ipark_servicepark_inviteEnterprise(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/inviteEnterprise.do", data);
}

/**
 * 宣传册/合伙人招募宣传册分享邀请
 * */
export function ipark_servicepark_invitePartner(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/invitePartner.do", data);
}

/**
 * 解决方案/手机浏览版
 * */
export function ipark_servicepark_industrialParkSolutionAForRelease(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/industrialParkSolutionAForRelease.do",
    data
  );
}

/**
 * 解决方案/会场演示版
 * */
export function ipark_servicepark_industrialParkSolutionBForRelease(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/industrialParkSolutionBForRelease.do",
    data
  );
}

/**
 * 解决方案/分享邀请
 * */
export function ipark_servicepark_solutionInvite(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/solutionInvite.do", data);
}

/**
 * 联系客服
 * */
export function ipark_servicepark_customerService(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/customerService.do", data);
}

/**
 * 合作园区
 * */
export function ipark_partner_serviceParkInfo(data?: AnyObject): Promise<IRes> {
  return postForm("/ipark/partner/serviceParkInfo.do", data);
}

/**
 * 园区负责人/合作园区/宣传册
 * */
export function ipark_servicepark_industrialParkBrochureForRelease(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/servicepark/industrialParkBrochureForRelease.do",
    data
  );
}

/**
 * 招商合伙人/合作园区/宣传册
 * */
export function ipark_partner_industrialParkBrochureForRelease(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/industrialParkBrochureForRelease.do", data);
}

/**
 * 招商合伙人/合作园区/宣传册
 * */
export function ipark_partner_loadIndustrialBrochure(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/loadIndustrialBrochure.do", data);
}

/**
 * 企业负责人/合作园区/宣传册
 * */
export function ipark_enterprise_industrialParkBrochureForRelease(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/enterprise/industrialParkBrochureForRelease.do",
    data
  );
}

/**
 * 合作园区/宣传册/手机浏览版
 * */
export function ipark_partner_industrialParkBrochureAForRelease(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/industrialParkBrochureAForRelease.do", data);
}

/**
 * 合作园区/宣传册/会场演示版
 * */
export function ipark_partner_industrialParkBrochureBForRelease(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/industrialParkBrochureBForRelease.do", data);
}

/**
 * 合作园区/宣传册/产业园介绍分享
 * */
export function ipark_partner_industrialIntroduce(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/industrialIntroduce.do", data);
}

/**
 * 合作园区/解决方案/手机浏览版
 * */
export function ipark_partner_industrialParkSolutionAForRelease(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/industrialParkSolutionAForRelease.do", data);
}

/**
 * 合作园区/解决方案/会场演示版
 * */
export function ipark_partner_industrialParkSolutionBForRelease(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/partner/industrialParkSolutionBForRelease.do", data);
}

/**
 * 合作园区/解决方案/邀请企业负责人
 * */
export function ipark_partner_solutionInvite(data?: AnyObject): Promise<IRes> {
  return postForm("/ipark/partner/solutionInvite.do", data);
}

/**
 * 企业负责人/园区服务(有数据)
 * */
export function ipark_enterprise_serviceParkInfo(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/enterprise/serviceParkInfo.do", data);
}

/**
 * 园区服务/宣传册/手机浏览版
 * */
export function ipark_enterprise_industrialParkBrochureAForRelease(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/enterprise/industrialParkBrochureAForRelease.do",
    data
  );
}

/**
 * 园区服务/宣传册/会场演示版
 * */
export function ipark_enterprise_industrialParkBrochureBForRelease(
  data?: AnyObject
): Promise<IRes> {
  return postForm(
    "/ipark/enterprise/industrialParkBrochureBForRelease.do",
    data
  );
}

/**
 * 园区服务/宣传册/产业园介绍分享
 * */
export function ipark_enterprise_industrialIntroduce(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/enterprise/industrialIntroduce.do", data);
}

/**
 * 园区服务/宣传册/邀请企业负责人
 * */
export function ipark_enterprise_inviteEnterprise(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/enterprise/inviteEnterprise.do", data);
}

/**
 * 受邀成为招商合伙人/ 加载邀请函
 * */
export function ipark_servicepark_loadEnterpriseInvitation(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/loadEnterpriseInvitation.do", data);
}
/**
 * 受邀成为招商合伙人/ 加载邀请函
 * */
export function ipark_servicepark_loadPartnerInvitation(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/loadPartnerInvitation.do", data);
}
/**
 * 通过邀请函查看产业园宣传册
 * */
export function ipark_servicepark_viewIndustrialParkBrochure(
  data?: AnyObject
): Promise<IRes> {
  return postForm("/ipark/servicepark/viewIndustrialParkBrochure.do", data);
}
