
import { defineComponent, onMounted, reactive } from "vue";
import { Select } from "@element-plus/icons-vue";
import { uias_userCorps } from "@/api/index";
import { clearURLcache } from "@/api/utils";
import { AnyObject } from "@/utils";

type Iorg = {
  orgId: string;
  orgLogo: string;
  orgName: string;
};
export default defineComponent({
  components: {
    IcSelect: Select,
  },
  props: {
    cmd: {
      type: String,
      default: "可以登录",
    },
  },
  setup(props) {
    const state = reactive<{ orgList: Iorg[]; active: string }>({
      orgList: [],
      active: sessionStorage.activeOrg || "",
    });
    onMounted(() => {
      uias_userCorps({
        _noShowError_: true,
      }).then(
        ({ data }) => {
          state.orgList = data.orgList;
        },
        (err) => {
          if (window.top) {
            window.top.postMessage(
              {
                isLoginValidate: true,
                isController: true,
                isError: true,
                message: err,
              },
              window.top.location.origin
            );
          }
        }
      );
    });
    return {
      state,
      height: window.innerHeight + "px",
      selectOrg(org: AnyObject) {
        if (window.top) {
          /* 清空可访问页面列表 */
          delete sessionStorage.withpathmenus;
          delete sessionStorage._withpathmenus;
          clearURLcache();
          window.top.postMessage(
            {
              isLoginValidate: true,
              isController: true,
              message: props.cmd,
              activeOrg: org.orgId,
            },
            window.top.location.origin
          );
        }
        state.active = org.orgId;
      },
    };
  },
});
