<template>
  <section class="container">
    {{ msg }}
  </section>
</template>

<script>
export default {
  data() {
    return {
      msg: window._errorMsg,
    };
  },
  head() {
    return {
      title: "访问出错",
    };
  },
};
</script>

<style></style>
