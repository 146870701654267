import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_aside, { width: "230px" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_scrollbar, { height: "100%" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_menu, {
                "active-text-color": "#fff",
                "background-color": "#244d81",
                "default-active": _ctx.defaultActive,
                "text-color": "#fff",
                router: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.menus, (itm, idx) => {
                    return (_openBlock(), _createBlock(_component_el_menu_item, {
                      index: 'weiyi' + idx,
                      key: itm.code,
                      disabled: itm.isTitle,
                      class: _normalizeClass(itm.isTitle || itm.isIcon ? 'parent' : 'children'),
                      route: itm.path
                    }, {
                      default: _withCtx(() => [
                        (itm.isTitle || itm.isIcon)
                          ? (_openBlock(), _createElementBlock("i", {
                              key: 0,
                              class: _normalizeClass(['iconfont', itm.icon]),
                              style: {"font-size":"21px","margin-right":"4px"}
                            }, null, 2))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", null, _toDisplayString(itm.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["index", "disabled", "class", "route"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["default-active"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view, {
            key: _ctx.state.key
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}