
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  inject,
} from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import { clearURLcache } from "@/api/utils";
import wechatSVG from "@/assets/image/wechat.svg";
import { loginKey } from "../const";

const appid = process.env.VUE_APP_APPID;

function WXLOGIN() {
  let uri, href;
  if (process.env.NODE_ENV === "development") {
    uri = encodeURIComponent(
      "http://www.dashengcsy.wang/mobile/financial-dev/indexv3.html?host=" +
        location.origin
    );
    href = location.origin + "/weixin-login.css";
  } else {
    uri = encodeURIComponent(
      location.origin + location.pathname + "#/validate"
    );
    href = location.origin + location.pathname + "weixin-login.css";
  }
  new WxLogin({
    self_redirect: true,
    id: "loginQRcode",
    // appid: "wxeebc9cff9fc5cf5e",
    appid,
    scope: "snsapi_login",
    redirect_uri: uri,
    state: "",
    style: "white",
    href,
  });
}
export default defineComponent({
  components: {
    // IcBack: Back,
  },
  setup() {
    sessionStorage.appid = appid;
    const state = reactive({
      loginStateText: "扫码", // 扫码,验证,绑定,登录
      activeOrg: sessionStorage.activeOrg,
    });
    const router = useRouter();
    const handle = (evt: MessageEvent) => {
      const { data, origin } = evt;
      if (origin !== location.origin || !data.isLoginValidate) return;
      if (data.isController) {
        if (data.message === "可以登录") {
          state.activeOrg = data.activeOrg;
        }
        if (data.isError) {
          ElMessageBox.alert(
            typeof data.message === "string"
              ? data.message
              : data.message.message,
            "出错",
            {
              type: "error",
              confirmButtonText: "知道了",
            }
          );
        }
      } else {
        state.loginStateText = data.message;
      }
    };
    window.addEventListener("message", handle);
    const login = inject(loginKey);
    onMounted(() => {
      if (state.loginStateText === "扫码") {
        /* 清空上次用户信息 */
        delete sessionStorage.token;
        // delete sessionStorage.activeOrg;
        // delete sessionStorage.appid;
        delete sessionStorage.homeCard;
        delete sessionStorage.menu;
        delete sessionStorage.withpathmenus;
        delete sessionStorage._withpathmenus;
        clearURLcache();
        WXLOGIN();
      }
    });
    onUnmounted(() => {
      window.removeEventListener("message", handle);
    });
    return {
      state,
      wechatSVG,
      height: window.innerHeight + "px",
      login() {
        sessionStorage.activeOrg = state.activeOrg;
        login &&
          login().then(() => {
            router.replace("/");
          });
      },
      back() {
        state.loginStateText = "扫码";
        nextTick(() => {
          WXLOGIN();
        });
      },
    };
  },
});
