
import { defineComponent, reactive, computed, PropType } from "vue";
import Draggable from "vuedraggable";
import { ElNotification } from "element-plus";
import { Rank, Plus, ZoomIn, Check, Delete } from "@element-plus/icons-vue";
import ImageView from "@/components/ImageView/Index.vue";
import { AnyObject, UUIDGenerator, wipeoffUrlOrigin, delay } from "@/utils";
import { ipark_servicepark_uploadImgContent } from "@/api";
import { rsHost } from "@/api/utils";
import Layout from "./layout.vue";

export default defineComponent({
  components: {
    Layout,
    ImageView,
    Draggable,
    Plus,
    ZoomIn,
    Delete,
    // Check,
    Rank,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    id: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      disabled: false,
      showImage: false,
      image: "",
      fileKey: "key_" + UUIDGenerator(),
      drag: false,
    });
    return {
      state,
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },
      fileList: computed({
        get: () => {
          return props.modelValue.map((val, idx) => {
            return {
              order: idx + 1,
              url: rsHost + val,
            };
          });
        },
        set: (val) => {
          emit(
            "update:modelValue",
            val.map((v) => wipeoffUrlOrigin(v.url))
          );
        },
      }),
      handlePictureCardPreview(url: string) {
        state.image = url;
        state.showImage = true;
      },
      async handleUpload(evt: Event) {
        const files: File[] = (evt?.target as AnyObject)?.files;
        if (files) {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const result = /\.[\w]*$/.exec(file.name);
            if (
              result &&
              ".png,.jpg,.jpeg,.bmp"
                .toLowerCase()
                .indexOf(result[0].toLowerCase()) !== -1
            ) {
              await ipark_servicepark_uploadImgContent({
                brochureId: props.id,
                img: file,
              }).then(({ data }) => {
                emit("update:modelValue", [
                  ...props.modelValue,
                  wipeoffUrlOrigin(data.ImgUrl),
                ]);
              });
            } else {
              await delay().then(() => {
                ElNotification({
                  title: "文件类型错误",
                  message: `只能上传图片文件，已忽略：${file.name}`,
                  type: "warning",
                });
              });
            }
          }
        }
        state.fileKey = "key_" + UUIDGenerator();
      },
      handleRemove(idx: number) {
        const newValue = [...props.modelValue];
        newValue.splice(idx, 1);
        emit("update:modelValue", newValue);
      },
    };
  },
});
