
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { uias_wechat_oauth2_checkUser } from "@/api";

export default defineComponent({
  setup(/* props, context */) {
    console.log("============validate==============");
    if (window.top) {
      window.top.postMessage(
        {
          isLoginValidate: true,
          message: "验证",
        },
        window.top.location.origin
      );
    }
    const route = useRoute();
    const loading = ref(false);
    function validate() {
      loading.value = true;
      return uias_wechat_oauth2_checkUser({
        appid: sessionStorage.appid,
        code: route.query.code,
        __notoken__: true,
        _noShowError_: true,
      })
        .then(
          ({ data }) => {
            if (data.result) {
              if (window.top) {
                sessionStorage.token = data.loginUser.token;
                window.top.postMessage(
                  {
                    isLoginValidate: true,
                    message: "登录",
                  },
                  window.top.location.origin
                );
              }
            } else {
              if (window.top) {
                sessionStorage.__code = route.query.code;
                window.top.postMessage(
                  {
                    isLoginValidate: true,
                    message: "绑定",
                  },
                  window.top.location.origin
                );
              }
            }
          },
          (err) => {
            if (window.top) {
              window.top.postMessage(
                {
                  isLoginValidate: true,
                  isController: true,
                  isError: true,
                  message: err,
                },
                window.top.location.origin
              );
            }
          }
        )
        .finally(() => {
          loading.value = false;
        });
    }
    validate();
    return {
      loading,
      height: window.innerHeight + "px",
      reload() {
        validate();
      },
    };
  },
});
