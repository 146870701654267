
import { defineComponent } from "vue";
import img_404 from "@/assets/404_images/404.png";
import img_404_cloud from "@/assets/404_images/404_cloud.png";

export default defineComponent({
  name: "page404",
  data() {
    return {
      img_404,
      img_404_cloud,
    };
  },
  computed: {
    message() {
      return "冲哥说这个页面你不能进......";
    },
  },
});
