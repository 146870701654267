
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import Page from "@/components/Page/Index.vue";
import {
  Delete,
  Edit,
  Picture,
  Refresh,
  Loading,
} from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox, ElForm, ElCard } from "element-plus";
import {
  ipark_servicepark_brochureList,
  ipark_servicepark_deleteBrochure,
  ipark_servicepark_createBrochure,
} from "@/api";
import { useRouter } from "vue-router";
export default defineComponent({
  components: {
    Page,
    Picture,
    Delete,
    Edit,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      loading: true,
      currentPage: 1,
      pageSize: 8,
      pageSizes: [8, 16, 24, 32],
      total: 0,
      show: false,
      json: [],
    });
    const dialog = reactive({
      show: false,
      Loading: true,
      form: {
        brochureType: "A",
        title: "",
      },
    });
    // const formRef = ref<InstanceType<typeof ElForm>>();
    const formRef = ref<typeof ElForm | null>(null);
    const cardList = ref<typeof ElCard | null>(null);
    const rules = reactive({
      radio: [
        {
          required: true,
          message: "请选择宣传册类型",
          trigger: "change",
        },
      ],
      title: [
        {
          required: true,
          message: "请输入宣传册标题",
          trigger: "blur",
        },
      ],
    });
    watch(
      () => [state.currentPage, state.pageSize],
      () => {
        listRefresh();
      }
    );
    function listRefresh() {
      state.loading = true;
      ipark_servicepark_brochureList({
        page: state.currentPage,
        per_page: state.pageSize,
      }).then(({ data }) => {
        state.loading = false;
        state.total = data.totalRows;
        state.json = data.rows;
      });
    }
    onMounted(() => {
      listRefresh();
    });
    return {
      state,
      Refresh,
      Loading,
      dialog,
      formRef,
      cardList,
      rules,
      listRefresh,
      handleSizeChange(val: number) {
        console.log(`${val} items per page`);
      },
      handleCurrentChange(val: number) {
        console.log(`current page: ${val}`);
      },
      onSubmit() {
        formRef.value?.validate().then((valid: boolean) => {
          if (valid) {
            ipark_servicepark_createBrochure({
              title: dialog.form.title,
              brochureType: dialog.form.brochureType,
            }).then((obj) => {
              if (obj.code == "SUCCESS") {
                ElMessage.success("创建成功！");
                listRefresh();
                dialog.show = false;
              }
            });
          } else {
            return false;
          }
        });
      },
      onAdd() {
        dialog.form = {
          brochureType: "A",
          title: "",
        };
        dialog.show = true;
      },
      onEdit(o: any) {
        console.log(o);
        router.push({
          path: "/industrialParkBrochure-detail",
          query: {
            brochureId: o.brochureId,
          },
        });
      },
      onDel(o: any) {
        ElMessageBox.confirm("删除后不可恢复，是否继续?", "删除提醒", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          ipark_servicepark_deleteBrochure({ brochureId: o.brochureId }).then(
            () => {
              ElMessage.success("删除成功！");
              listRefresh();
            }
          );
        });
      },
    };
  },
});
