
import {
  defineComponent,
  reactive,
  ref,
  watchEffect,
  onMounted,
  computed,
} from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import { wipeoffUrlOrigin, AnyObject } from "@/utils";
import Page from "@/components/Page/Index.vue";
import Cover from "./cover.vue";
import RichtextContent from "./content.vue";
import ProjectionScreen from "./projectionscreen.vue";
import Publish from "./publish.vue";
import {
  ipark_servicepark_getBrochure,
  ipark_servicepark_updateCover,
  ipark_servicepark_updateHtmlContent,
  ipark_servicepark_updateImageContent,
  ipark_servicepark_publishBrochure,
} from "@/api";

export default defineComponent({
  components: {
    Page,
    Cover,
    RichtextContent,
    ProjectionScreen,
    Publish,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const state = reactive({
      active: 0,
      brochureId: route.query?.brochureId as string,
      // brochureId: "001d0b6e0b854e8685b16ac4dbee940b",
      loading: false,
      canNext: !!route.query?.brochureId,
      detail: {
        cover: "",
        file: "",
        title: "",
        summary: "",
        htmContent: "",
        imgContent: [] as string[],
      },
    });
    const setImgWidth = (htmlstr: string) => {
      const regex1 = new RegExp("(i?)(<img)([^>]+>)", "gmi");
      //给不含style="" 或 style='' 的img标签加上style=""
      htmlstr = htmlstr.replace(regex1, '$2 style=""$3');
      //正则匹配含有style的img标签
      var regex2 = new RegExp("(i?)(<img.*?style=['\"])([^>]+>)", "gmi");
      //在img标签的style里面增加css样式(这里增加的样式： max-width:100%;height:auto;)
      htmlstr = htmlstr.replace(regex2, "$2 max-width:100%;height:auto;$3");
      console.log("在img标签的style里面增加样式后的html字符串：" + htmlstr);
      return htmlstr;
    };
    onMounted(() => {
      // state.active = 0;
      // state.active = 2;
      ipark_servicepark_getBrochure({
        brochureId: state.brochureId,
      }).then(({ data }) => {
        state.detail.cover = data.cover;
        state.detail.title = data.title;
        state.detail.summary = data.summary;
        state.detail.htmContent = data.htmContent;
        state.detail.imgContent = (data.imgContent || []).map((val: string) =>
          wipeoffUrlOrigin(val)
        );
      });
    });
    watchEffect(() => {
      if ((!state.detail.cover || !state.detail.title) && state.active === 0) {
        state.canNext = false;
      } else {
        state.canNext = true;
      }
    });
    return {
      state,
      coverData: computed({
        get: () => {
          return {
            cover: state.detail.cover,
            file: state.detail.file,
            title: state.detail.title,
            summary: state.detail.summary,
          };
        },
        set: (val: AnyObject) => {
          state.detail.cover = val.cover;
          state.detail.file = val.file;
          state.detail.title = val.title;
          state.detail.summary = val.summary;
        },
      }),
      onDel() {
        ElMessageBox.confirm("删除后不可恢复，是否继续?", "删除提醒", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          ipark_servicepark_updateImageContent({
            mobile: "dialog.form.mobile",
          }).then(() => {
            ElMessage.success("删除成功！");
          });
        });
      },
      prev() {
        if (state.active > 0) {
          state.active--;
        }
      },
      next() {
        switch (state.active) {
          case 0:
            state.loading = true;
            ipark_servicepark_updateCover({
              brochureId: state.brochureId,
              title: state.detail.title,
              summary: state.detail.summary,
              cover: state.detail.file,
            })
              .then(() => {
                state.active++;
              })
              .finally(() => {
                state.loading = false;
              });
            break;
          case 1:
            state.loading = true;
            ipark_servicepark_updateHtmlContent({
              brochureId: state.brochureId,
              htmContent: setImgWidth(state.detail.htmContent),
            })
              .then(() => {
                state.active++;
              })
              .finally(() => {
                state.loading = false;
              });
            break;
          case 2:
            state.loading = true;
            ipark_servicepark_updateImageContent({
              brochureId: state.brochureId,
              imgContent: JSON.stringify(state.detail.imgContent),
            })
              .then(() => {
                state.active++;
              })
              .finally(() => {
                state.loading = false;
              });
            break;
          case 3:
            ipark_servicepark_publishBrochure({
              brochureId: state.brochureId,
            })
              .then(() => {
                router.back();
              })
              .finally(() => {
                state.loading = false;
              });
            return;
          default:
            console.log("无效状态");
        }
        console.log(state.active);
      },
    };
  },
});
