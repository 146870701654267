
import { defineComponent, reactive, onUnmounted, watch, onMounted } from "vue";
import { AnyObject } from "@/utils";
import Layout from "./layout.vue";

export default defineComponent({
  components: {
    Layout,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    renderue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    let ue: AnyObject;
    watch(
      () => {
        return Boolean(props.modelValue && ue && props.renderue);
      },
      () => {
        ue.ready(function () {
          const c = ue.getContent();
          if (!c) {
            ue.setContent(props.modelValue);
          }
        });
      }
    );
    onMounted(() => {
      ue = UE.getEditor("uecontainer", {
        autoHeight: false,
        autoHeightEnabled: false,
        initialFrameWidth: "100%",
        initialFrameHeight: window.innerHeight - 650,
      });
      ue.addListener("contentChange", function () {
        const c = ue.getContent();
        emit("update:modelValue", c);
      });
      ue.addListener("blur", function () {
        const c = ue.getContent();
        emit("update:modelValue", c);
      });
    });
    onUnmounted(() => {
      ue.destroy();
    });
    return {};
  },
});
