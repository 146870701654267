
import { defineComponent, nextTick, reactive, ref, computed } from "vue";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import { AnyObject } from "@/utils";
import { useRouter } from "vue-router";
import { ElSelect, ElMessageBox } from "element-plus";
import {
  ipark_servicepark_queryEnterpriseFinancialStatementsList,
  servicepark_enterpriseSearch,
  ipark_servicepark_getFinancialStatements,
  ipark_servicepark_saveFinancialStatements,
  ipark_servicepark_delFinancialStatements,
} from "@/api";
import FinanceFrom from "./FinanceFrom.vue";
import { IForm, getNewFormRule, getNewFormData } from "./utils";

export default defineComponent({
  components: {
    Page,
    DataTable,
    FinanceFrom,
  },
  setup() {
    const state = reactive({
      dataFilter: {
        keyword: "",
      },
      form: {} as IForm,
      show: false,
      loading: false,
      isEdit: false,
      path: "",
      pathstr: "",
      statementsId: "",
      page: 0,
      enterprises: [] as AnyObject[],
    });
    const router = useRouter();
    const table = ref<typeof DataTable | null>(null);
    const select = ref<typeof ElSelect | null>(null);
    const form = ref<typeof FinanceFrom | null>(null);
    const taxpayerName = computed(() => {
      return (
        state.enterprises.filter(
          (val: AnyObject) => val.taxpayerId === state.dataFilter.keyword
        )[0] || {}
      ).taxpayerName;
    });

    return {
      state,
      table,
      select,
      form,
      taxpayerName,
      rule: computed(() => {
        return getNewFormRule(
          state.isEdit
            ? state.form.reportType === "1"
              ? {
                  onlyFile: false,
                  asstesLiabilitiesFile: true,
                  profitLossFile: true,
                  cashFlowFile: true,
                }
              : {
                  onlyFile: true,
                  asstesLiabilitiesFile: false,
                  profitLossFile: false,
                  cashFlowFile: false,
                }
            : {}
        );
      }),
      remoteMethod(keyword: string) {
        state.loading = true;
        servicepark_enterpriseSearch({
          keyword,
        })
          .then(({ data }) => {
            state.enterprises = data;
          })
          .finally(() => {
            state.loading = false;
          });
      },
      onSeach() {
        select.value?.blur();
        nextTick(() => {
          table.value?.reset();
        });
      },
      onDetail(row: AnyObject) {
        router.push({
          path: "/financetax-financeUpload-detail",
          query: {
            yearmonthCN: row.yearmonthCN,
            yearmonth: row.yearmonth,
            keyword: state.dataFilter.keyword,
          },
        });
      },
      onDel() {
        ElMessageBox.confirm("删除后不可恢复，是否继续?", "删除提醒", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          state.loading = true;
          ipark_servicepark_delFinancialStatements({
            statementsId: state.statementsId,
          })
            .then(() => {
              state.show = false;
              table.value?.reset();
            })
            .finally(() => {
              state.loading = false;
            });
        });
      },
      onUpload(type: string, row: AnyObject) {
        state.path = row.yearmonth;
        state.pathstr = row.yearmonthCN;
        if (type === "new") {
          state.form = getNewFormData({
            enterpriseName: taxpayerName.value,
            enterpriseCode: state.dataFilter.keyword,
          });
          state.isEdit = false;
          state.show = true;
          state.statementsId = "";
        } else {
          ipark_servicepark_getFinancialStatements({
            yearmonth: row.yearmonth,
            taxpayerId: state.dataFilter.keyword,
          }).then(({ data }) => {
            state.statementsId = data.statementsId;
            if (data.statementsList.length > 1) {
              const file1 =
                data.statementsList.filter(
                  (val: AnyObject) => val.reportId === "1"
                )[0] || {};
              const file2 =
                data.statementsList.filter(
                  (val: AnyObject) => val.reportId === "2"
                )[0] || {};
              const file3 =
                data.statementsList.filter(
                  (val: AnyObject) => val.reportId === "3"
                )[0] || {};
              state.form = getNewFormData({
                enterpriseName: taxpayerName.value,
                enterpriseCode: state.dataFilter.keyword,
                income: data.businessIncome,
                reportType: data.statementsList.length > 1 ? "2" : "1",
                asstesLiabilitiesFile: file1.reportFileStore,
                asstesLiabilitiesFileLabel: file1.reportName,
                profitLossFile: file2.reportFileStore,
                profitLossFileLabel: file2.reportName,
                cashFlowFile: file3.reportFileStore,
                cashFlowFileLabel: file3.reportName,
              });
            } else {
              state.form = getNewFormData({
                enterpriseName: taxpayerName.value,
                enterpriseCode: state.dataFilter.keyword,
                income: data.businessIncome,
                reportType: data.statementsList.length > 1 ? "2" : "1",
                onlyFile: data.statementsList[0].reportFileStore,
                onlyFileLabel: data.statementsList[0].reportName,
              });
            }
            state.isEdit = true;
            state.show = true;
          });
        }
      },
      onSubmit() {
        form.value?.validate().then((data: IForm) => {
          state.loading = true;
          return ipark_servicepark_saveFinancialStatements({
            yearmonth: state.path,
            taxpayerId: data.enterpriseCode,
            file0:
              data.reportType === "1" && data.onlyFile instanceof File
                ? data.onlyFile
                : null,
            file1:
              data.reportType === "2" &&
              data.asstesLiabilitiesFile instanceof File
                ? data.asstesLiabilitiesFile
                : null,
            file2:
              data.reportType === "2" && data.profitLossFile instanceof File
                ? data.profitLossFile
                : null,
            file3:
              data.reportType === "2" && data.cashFlowFile instanceof File
                ? data.cashFlowFile
                : null,
            businessIncome: data.income,
          })
            .then(() => {
              state.show = false;
              table.value?.reset();
            })
            .finally(() => {
              state.loading = false;
            });
        });
      },
      getData(param: Iparam) {
        state.page = param.index;
        return ipark_servicepark_queryEnterpriseFinancialStatementsList({
          taxpayerId: state.dataFilter.keyword,
          page: param.index,
          per_page: param.size,
        }).then(({ data }) => {
          return {
            data: data.rows,
            total: data.totalRows,
          };
        });
      },
    };
  },
});
