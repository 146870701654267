
import { defineComponent, reactive, ref, nextTick } from "vue";
import { ElMessage, ElMessageBox, ElForm } from "element-plus";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
import addCompany from "./addCompany.vue";
import { Iparam } from "@/components/DataTable/utils";
import { AnyObject } from "@/utils";
import {
  ipark_servicepark_saveServicePark,
  ipark_servicepark_queryServiceParkList,
  ipark_servicepark_deleteServicePark,
} from "@/api";

export default defineComponent({
  components: {
    Page,
    DataTable,
    addCompany,
  },
  setup() {
    const dialog = reactive({
      title: "",
      disabled: false,
      show: false,
      isNew: false,
      isAdd: false,
      serviceParkCode: "",
      form: {},
    });
    const state = reactive({
      keyword: "",
      loading: false,
      enterprises: [] as AnyObject[],
    });
    const formDetail = (obj: any) => {
      dialog.show = true;
      switch (obj) {
        case "add":
          dialog.disabled = false;
          dialog.isNew = true;
          dialog.isAdd = true;
          break;
        case "edit":
          dialog.disabled = true;
          dialog.isNew = false;
          dialog.isAdd = false;
          break;
        default:
          break;
      }
    };
    const table = ref<typeof DataTable | null>(null);
    const formCompany = ref<typeof ElForm | null>(null);
    return {
      table,
      state,
      dialog,
      formCompany,
      formDetail,
      onCancel() {
        if (!dialog.isAdd && !dialog.disabled) {
          dialog.disabled = true;
          dialog.isNew = false;
        } else {
          dialog.show = false;
        }
      },
      onSubmit() {
        formCompany.value?.$refs.formRef.validate((valid: boolean) => {
          if (valid) {
            ipark_servicepark_saveServicePark(dialog.form).then((obj) => {
              if (obj.code == "SUCCESS") {
                ElMessage("保存成功！");
                table.value?.reset();
              }
              dialog.show = false;
            });
          } else {
            return false;
          }
        });
      },
      onEdit() {
        dialog.disabled = false;
        dialog.isNew = true;
        dialog.isAdd = false;
      },
      onDel() {
        ElMessageBox.confirm("删除后不可恢复，是否继续?", "删除提醒", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          ipark_servicepark_deleteServicePark({
            serviceParkCode: dialog.serviceParkCode || "",
          }).then((obj) => {
            if (obj.code == "SUCCESS") {
              ElMessage("已经删除成功！");
              dialog.show = false;
              table.value?.reset();
            }
            dialog.show = false;
          });
        });
      },
      onAdd() {
        dialog.title = "新增产业园";
        dialog.form = {};
        dialog.isNew = true;
        dialog.isAdd = true;
        formDetail("add");
      },
      onDetail(obj: { serviceParkCode: string }) {
        dialog.title = "产业园详情";
        dialog.form = obj;
        dialog.serviceParkCode = obj.serviceParkCode;
        formDetail("edit");
      },
      onSeach() {
        nextTick(() => {
          table.value?.reset();
        });
      },
      getData(param: Iparam) {
        return ipark_servicepark_queryServiceParkList({
          searchKeyword: state.keyword,
          page: param.index,
          per_page: param.size,
        }).then(({ data }) => {
          return {
            data: data.rows,
            total: data.totalRows,
          };
        });
      },
    };
  },
});
