import { clone } from "@/utils";

export interface IForm {
  enterpriseName: string; // 企业名
  enterpriseCode: string; // 税号
  income: string; // 营业收入
  reportType: string; // 报表类型
  onlyFile: string | File; // 三合一表报
  asstesLiabilitiesFile: string | File; // 资产负债表
  profitLossFile: string | File; // 损益表
  cashFlowFile: string | File; // 现金流量表
  onlyFileLabel: string; // 三合一表报
  asstesLiabilitiesFileLabel: string; // 资产负债表
  profitLossFileLabel: string; // 损益表
  cashFlowFileLabel: string; // 现金流量表
}

const form: IForm = {
  enterpriseName: "",
  enterpriseCode: "",
  income: "",
  reportType: "1",
  onlyFile: "",
  asstesLiabilitiesFile: "",
  profitLossFile: "",
  cashFlowFile: "",
  onlyFileLabel: "",
  asstesLiabilitiesFileLabel: "",
  profitLossFileLabel: "",
  cashFlowFileLabel: "",
};

export type IRule = {
  [P in keyof IForm]: boolean;
};

const rule: IRule = {
  enterpriseName: true,
  enterpriseCode: true,
  income: true,
  reportType: true,
  onlyFile: true,
  asstesLiabilitiesFile: true,
  profitLossFile: true,
  cashFlowFile: true,
  onlyFileLabel: false,
  asstesLiabilitiesFileLabel: false,
  profitLossFileLabel: false,
  cashFlowFileLabel: false,
};

export function getNewFormData(obj: Partial<IForm> = {}): IForm {
  return {
    ...clone(form),
    ...obj,
  };
}

export function getNewFormRule(obj: Partial<IRule> = {}): IRule {
  return {
    ...clone(rule),
    ...obj,
  };
}
