
import { computed, defineComponent, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { ElMessageBox } from "element-plus";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import FileSaver from "file-saver";
import { AnyObject, getURLName } from "@/utils";
import {
  ipark_servicepark_queryEnterpriseFinancialStatementsListByTaxPeriod,
  ipark_servicepark_getFinancialStatements,
  ipark_servicepark_saveFinancialStatements,
  ipark_servicepark_delFinancialStatements,
  servicepark_enterpriseSearch,
} from "@/api";
import FinanceFrom from "./FinanceFrom.vue";
import { IForm, getNewFormRule, getNewFormData } from "./utils";

export default defineComponent({
  components: {
    Page,
    DataTable,
    FinanceFrom,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      dataFilter: {
        keyword: route.query?.keyword as string,
      },
      show: false,
      loading: false,
      isEdit: false,
      statementsId: "",
      enterprises: [] as AnyObject[],
      form: {} as IForm,
    });
    const table = ref<typeof DataTable | null>(null);
    const form = ref<typeof FinanceFrom | null>(null);
    function remoteMethod(keyword: string) {
      state.loading = true;
      servicepark_enterpriseSearch({
        keyword,
      })
        .then(({ data }) => {
          state.enterprises = data;
        })
        .finally(() => {
          state.loading = false;
        });
    }
    remoteMethod(route.query?.keyword as string);
    return {
      state,
      table,
      form,
      path: route.query?.yearmonthCN,
      rule: computed(() => {
        return getNewFormRule(
          state.isEdit
            ? state.form.reportType === "1"
              ? {
                  onlyFile: false,
                  asstesLiabilitiesFile: true,
                  profitLossFile: true,
                  cashFlowFile: true,
                }
              : {
                  onlyFile: true,
                  asstesLiabilitiesFile: false,
                  profitLossFile: false,
                  cashFlowFile: false,
                }
            : {}
        );
      }),
      remoteMethod,
      onDownload(obj: AnyObject) {
        FileSaver.saveAs(obj.reportFileStore, getURLName(obj.reportName));
      },
      onSeach() {
        table.value?.reset();
      },
      onDel() {
        ElMessageBox.confirm("删除后不可恢复，是否继续?", "删除提醒", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          state.loading = true;
          ipark_servicepark_delFinancialStatements({
            statementsId: state.statementsId,
          })
            .then(() => {
              state.show = false;
              table.value?.reset();
            })
            .finally(() => {
              state.loading = false;
            });
        });
      },
      onSubmit() {
        form.value?.validate().then((data: IForm) => {
          state.loading = true;
          return ipark_servicepark_saveFinancialStatements({
            yearmonth: route.query?.yearmonth,
            taxpayerId: data.enterpriseCode,
            file0: data.onlyFile instanceof File ? data.onlyFile : null,
            file1:
              data.asstesLiabilitiesFile instanceof File
                ? data.asstesLiabilitiesFile
                : null,
            file2:
              data.profitLossFile instanceof File ? data.profitLossFile : null,
            file3: data.cashFlowFile instanceof File ? data.cashFlowFile : null,
            businessIncome: data.income,
          })
            .then(() => {
              state.show = false;
              table.value?.reset();
            })
            .finally(() => {
              state.loading = false;
            });
        });
      },
      onDetail(row: AnyObject) {
        ipark_servicepark_getFinancialStatements({
          yearmonth: route.query?.yearmonth,
          taxpayerId: row.taxpayerId,
        }).then(({ data }) => {
          state.statementsId = data.statementsId;
          if (data.statementsList.length > 1) {
            const file1 =
              data.statementsList.filter(
                (val: AnyObject) => val.reportId === "1"
              )[0] || {};
            const file2 =
              data.statementsList.filter(
                (val: AnyObject) => val.reportId === "2"
              )[0] || {};
            const file3 =
              data.statementsList.filter(
                (val: AnyObject) => val.reportId === "3"
              )[0] || {};
            state.form = getNewFormData({
              enterpriseName: row.taxpayerName,
              enterpriseCode: row.taxpayerId,
              income: data.businessIncome,
              reportType: data.statementsList.length > 1 ? "2" : "1",
              asstesLiabilitiesFile: file1.reportFileStore,
              asstesLiabilitiesFileLabel: file1.reportName,
              profitLossFile: file2.reportFileStore,
              profitLossFileLabel: file2.reportName,
              cashFlowFile: file3.reportFileStore,
              cashFlowFileLabel: file3.reportName,
            });
          } else {
            state.form = getNewFormData({
              enterpriseName: row.taxpayerName,
              enterpriseCode: row.taxpayerId,
              income: data.businessIncome,
              reportType: data.statementsList.length > 1 ? "2" : "1",
              onlyFile: data.statementsList[0].reportFileStore,
              onlyFileLabel: data.statementsList[0].reportName,
            });
          }
          state.isEdit = true;
          state.show = true;
        });
      },
      onUpload(row: AnyObject) {
        state.form = getNewFormData({
          enterpriseName: row.taxpayerName,
          enterpriseCode: row.taxpayerId,
        });
        state.statementsId = "";
        state.isEdit = false;
        state.show = true;
      },
      getData(param: Iparam) {
        return ipark_servicepark_queryEnterpriseFinancialStatementsListByTaxPeriod(
          {
            keyword: state.dataFilter.keyword,
            yearmonth: route.query?.yearmonth,
            page: param.index,
            per_page: param.size,
          }
        ).then(({ data }) => {
          return {
            data: data.rows,
            total: data.totalRows,
          };
        });
      },
    };
  },
});
