
import {
  defineComponent,
  reactive,
  ref,
  PropType,
  watch,
  watchEffect,
} from "vue";
import { ElForm } from "element-plus";
import { getNewFormData, getNewFormRule, IRule } from "./utils";

export default defineComponent({
  props: {
    data: {
      default: () => ({}),
    },
    lock: {
      type: Boolean,
      default: false,
    },
    rule: {
      type: Object as PropType<Partial<IRule>>,
      default: () => ({}),
    },
  },
  emits: ["filechange"],
  setup(props, { expose, emit }) {
    const formRef = ref<typeof ElForm | null>(null);
    const state = reactive({
      ocr: false,
      warningTips: "",
      industryLoading: false,
      enterpriseType: [{ label: "全部", value: "全部" }],
      industryOptions: [{ label: "全部", value: "全部" }],
      form: getNewFormData(props.data),
    });
    const rule = getNewFormRule(props.rule);
    function validator() {
      return new Promise((reslove, reject) => {
        formRef.value?.validate((isValid: boolean) => {
          if (isValid) {
            reslove(state.form);
          } else {
            reject();
            return false;
          }
        });
      });
    }
    watch(
      () => props.data,
      () => {
        state.form = {
          ...state.form,
          ...props.data,
        };
      },
      {
        immediate: true,
      }
    );
    watchEffect(() => {
      emit("filechange", state.form.enterprisefile);
    });
    expose({
      validator,
    });
    return {
      state,
      formRef,
      remoteMethod() {
        return true;
      },
    };
  },
});
