
import { defineComponent, reactive, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import FileSaver from "file-saver";
import { ElSelect, ElForm } from "element-plus";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
// import Layer from "@/components/Layer/Index.vue";
// import Upload from "@/components/Upload/Index.vue";
import { InternalRuleItem } from "async-validator";
import { Iparam } from "@/components/DataTable/utils";
import FileSelect from "@/components/FileSelect/Index.vue";
import { AnyObject } from "@/utils";
import { downloadMap } from "./utils";
import {
  ipark_servicepark_queryTaxRewardList,
  servicepark_enterpriseSearch,
  ipark_servicepark_downloadTaxSumData,
  ipark_servicepark_importTaxRewardData,
} from "@/api";
import { getPercentage } from "@/api/utils";

export default defineComponent({
  components: {
    Page,
    // Layer,
    // Upload,
    FileSelect,
    DataTable,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      dataFilter: {
        keyword: "",
      },
      form: {
        taxPeriod: "",
        taxTypes: [],
      },
      form2: {
        taxPeriod: "",
        file: "",
      },
      percentage: 0,
      page: 0,
      status: "",
      loading: false,
      enterprises: [] as AnyObject[],
      showDownload: false,
      showUpload: false,
    });
    const table = ref<typeof DataTable | null>(null);
    const select = ref<typeof ElSelect | null>(null);
    const formRef = ref<typeof ElForm | null>(null);
    const formRef2 = ref<typeof ElForm | null>(null);

    function onSeach() {
      select.value?.blur();
      nextTick(() => {
        table.value?.reset();
      });
    }
    return {
      state,
      table,
      select,
      downloadMap,
      formRef,
      formRef2,
      rules: {
        taxPeriod: [
          {
            required: true,
            message: "请选择税款所属期",
            trigger: "change",
          },
        ],
        taxTypes: [
          {
            required: true,
            message: "请选择可奖励税种",
            trigger: "change",
          },
        ],
      },
      rules2: {
        taxPeriod: [
          {
            required: true,
            message: "请选择税款所属期",
            trigger: "change",
          },
        ],
        file: [
          {
            required: true,
            validator: function (
              rule: InternalRuleItem,
              value: File | string,
              callback: (arg?: Error) => void
            ) {
              if (!rule.required) {
                return callback();
              }
              if (value) {
                if (typeof value === "string") return callback();
                const result = /\.[\w]*$/.exec(value.name);
                if (
                  result &&
                  ".xls;.xlsx"
                    .toLowerCase()
                    .indexOf(result[0].toLowerCase()) !== -1
                ) {
                  callback();
                } else {
                  callback(new Error("请选择Excel文件"));
                }
              } else {
                callback(new Error("请选择文件"));
              }
            },
            trigger: "change",
          },
        ],
      },
      request(options: AnyObject) {
        console.log(options);
        debugger;
      },
      onSeach,
      onUpload() {
        formRef2.value?.resetFields();
        state.showUpload = true;
      },
      onDownload() {
        formRef2.value?.resetFields();
        state.showDownload = true;
      },
      onSubmit2() {
        // const ws = new WebSocket(
        //   host + "/ipark/webmsg.do?token=" + sessionStorage.token
        // );
        // ws.addEventListener("error", function () {
        //   state.status = "error";
        // });

        // // Listen for messages
        // ws.addEventListener("message", function (event) {
        //   console.log("Message from server ", event.data);
        // });
        formRef2.value?.validate().then((valid: boolean) => {
          if (valid) {
            // const ws = new WebSocket(
            //   websocketHost + "/ipark/webmsg.do?token=" + sessionStorage.token
            // );
            // ws.addEventListener("error", function () {
            //   state.status = "error";
            // });

            // ws.addEventListener("message", function (event) {
            //   if (event.data === "success") {
            //     ws.close();
            //     state.status = "success";
            //     setTimeout(() => {
            //       state.showUpload = false;
            //       state.loading = false;
            //     }, 1000);
            //     onSeach();
            //   } else {
            //     state.percentage = parseInt(event.data);
            //   }
            // });
            // ws.addEventListener("open", function () {
            //   const date = state.form2.taxPeriod;
            //   ipark_servicepark_importTaxRewardData({
            //     taxPeriodStart:
            //       date && Array.isArray(date) && date.length > 1 ? date[0] : "",
            //     taxPeriodEnd:
            //       date && Array.isArray(date) && date.length > 1 ? date[1] : "",
            //     file: state.form2.file,
            //   }).catch(() => {
            //     state.loading = false;
            //   });
            // });
            getPercentage(
              function (ws) {
                const date = state.form2.taxPeriod;
                state.loading = true;
                state.status = "";
                state.percentage = 0;
                return ipark_servicepark_importTaxRewardData({
                  taxPeriodStart:
                    date && Array.isArray(date) && date.length > 1
                      ? date[0]
                      : "",
                  taxPeriodEnd:
                    date && Array.isArray(date) && date.length > 1
                      ? date[1]
                      : "",
                  file: state.form2.file,
                })
                  .then(
                    () => {
                      state.showUpload = false;
                      onSeach();
                    },
                    () => {
                      ws.close();
                    }
                  )
                  .finally(() => {
                    state.loading = false;
                  });
              },
              function (data) {
                if (data.type === "status") {
                  state.status = data.msg;
                } else {
                  state.percentage = data.msg;
                }
              }
            );
          } else {
            return false;
          }
        });
      },
      onSubmit() {
        formRef.value?.validate().then((valid: boolean) => {
          if (valid) {
            const date = state.form.taxPeriod;
            state.loading = true;
            ipark_servicepark_downloadTaxSumData({
              taxPeriodStart:
                date && Array.isArray(date) && date.length > 1 ? date[0] : "",
              taxPeriodEnd:
                date && Array.isArray(date) && date.length > 1 ? date[1] : "",
              taxTypes: state.form.taxTypes.join(),
            })
              .then(({ data, msg }) => {
                FileSaver.saveAs(data, msg);
                state.showDownload = false;
              })
              .finally(() => {
                state.loading = false;
              });
          } else {
            return false;
          }
        });
      },
      onDetail(row: AnyObject) {
        router.push({
          path: "/financialincentive-auditdetail",
          query: {
            taxPeriodStart: row.taxPeriodStart,
            taxPeriodEnd: row.taxPeriodEnd,
            keyword: state.dataFilter.keyword,
          },
        });
      },
      remoteMethod(keyword: string) {
        state.loading = true;
        servicepark_enterpriseSearch({
          keyword,
        })
          .then(({ data }) => {
            state.enterprises = data;
          })
          .finally(() => {
            state.loading = false;
          });
      },
      getData(param: Iparam) {
        state.page = param.index;
        return ipark_servicepark_queryTaxRewardList({
          keyword: state.dataFilter.keyword,
          page: param.index,
          per_page: param.size,
        }).then(({ data }) => {
          return {
            data: data.rows,
            total: data.totalRows,
          };
        });
      },
    };
  },
});
