
import {
  defineComponent,
  reactive,
  ref,
  watchEffect,
  watch,
  PropType,
} from "vue";
import { useRoute } from "vue-router";
import { Picture } from "@element-plus/icons-vue";
import { ipark_servicepark_getBrochure } from "@/api";
import vuePictureCropper, { cropper } from "vue-picture-cropper";
import { AnyObject } from "@/utils";
interface Result {
  dataURL: string;
  blobURL: string;
}
export default defineComponent({
  components: {
    Picture,
    vuePictureCropper,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object as PropType<AnyObject>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    // const route = useRoute();
    const state = reactive({
      isShowDialog: false,
      currentPage: 1,
      form: {
        cover: props.modelValue.cover,
        file: null as Blob | null,
        title: props.modelValue.title,
        summary: props.modelValue.summary,
      } as AnyObject,
    });
    const rules = reactive({
      cover: [
        {
          required: true,
          message: "请选择宣传册封面图片",
          trigger: "change",
        },
      ],
      title: [
        {
          required: true,
          message: "请输入宣传册标题",
          trigger: "blur",
        },
      ],
    });
    const uploadInput = ref<HTMLInputElement | null>(null);
    const pic = ref<string>("");
    const result: Result = reactive({
      dataURL: "",
      blobURL: "",
    });
    /**
     * 选择图片
     */
    const uploadImg = (e: Event): void => {
      // 重置上一次的结果
      state.form.cover = "";
      result.dataURL = "";
      result.blobURL = "";
      // 如果有多个裁剪框，也需要重置掉裁剪目标的值，避免使用同一张图片无法触发watch
      pic.value = "";
      // 获取选取的文件
      const target = e.target as HTMLInputElement;
      const { files } = target;
      if (!files) return;
      const file: File = files[0];
      // 转换为base64传给裁切组件
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        // 更新裁切弹窗的图片源
        pic.value = String(reader.result);
        // 显示裁切弹窗
        state.isShowDialog = true;
        // 清空已选择的文件
        if (!uploadInput.value) return;
        uploadInput.value.value = "";
      };
    };
    /**
     * 获取裁切结果
     */
    const getResult = async (): Promise<void> => {
      console.log(cropper);
      // 获取生成的base64图片地址
      const base64: string = cropper.getDataURL();
      // 获取生成的blob文件信息
      const blob: Blob = await cropper.getBlob();
      // 获取生成的file文件信息
      const file = await cropper.getFile({
        fileName: "测试文件名，可不传",
      });
      console.log({ base64, blob, file });
      // 把base64赋给结果展示区
      result.dataURL = base64;
      try {
        result.blobURL = URL.createObjectURL(blob);
      } catch (e) {
        result.blobURL = "";
      }
      state.form.cover = result.blobURL;
      state.form.file = blob;
      // 隐藏裁切弹窗
      state.isShowDialog = false;
    };
    /**
     * 清除裁切框
     */
    const clear = (): void => {
      cropper.clear();
    };
    /**
     * 重置默认的裁切区域
     */
    const reset = (): void => {
      cropper.reset();
    };

    watch(
      () => props.modelValue,
      (val: AnyObject) => {
        state.form = val;
      }
    );
    watchEffect(() => {
      emit("update:modelValue", state.form);
    });
    return {
      state,
      rules,
      // 数据
      uploadInput,
      pic,
      result,
      // 方法
      uploadImg,
      getResult,
      clear,
      reset,
    };
  },
});
