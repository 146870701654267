import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45e9fb22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "industrialPark-brochure-detail-publish" }
const _hoisted_2 = { class: "img" }
const _hoisted_3 = { class: "image-slot" }
const _hoisted_4 = { class: "description" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Picture = _resolveComponent("Picture")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, { "body-style": { padding: '0px' } }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_image, {
            src: _ctx.cover,
            fit: "contain"
          }, {
            error: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Picture)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }, 8, ["src"])
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.summary), 1)
    ])
  ]))
}