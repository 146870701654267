
import { defineComponent, reactive, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import FileSaver from "file-saver";
import { ElSelect, ElForm, ElMessageBox } from "element-plus";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
// import Layer from "@/components/Layer/Index.vue";
// import Upload from "@/components/Upload/Index.vue";
import { InternalRuleItem } from "async-validator";
import { Iparam } from "@/components/DataTable/utils";
// import FileSelect from "@/components/FileSelect/Index.vue";
import Upload from "@/components/Upload/Index.vue";
import { AnyObject } from "@/utils";
import {
  ipark_servicepark_queryEnterpriseRewardList,
  servicepark_enterpriseSearch,
  ipark_servicepark_importEnterpriseRewardData,
  ipark_servicepark_downLoadEnterpriseRewardTemplate,
} from "@/api";
import { getPercentage } from "@/api/utils";
import { downloadMap } from "./utils";

export default defineComponent({
  components: {
    Page,
    // Layer,
    // Upload,
    // FileSelect,
    Upload,
    DataTable,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      dataFilter: {
        keyword: "",
      },
      form: {
        taxPeriod: [] as number[],
        taxTypes: [] as string[],
        file: "",
      },
      percentage: 0,
      page: 0,
      status: "",
      loading: false,
      enterprises: [] as AnyObject[],
      showUpload: false,
      showDownload: false,
    });
    const table = ref<typeof DataTable | null>(null);
    const select = ref<typeof ElSelect | null>(null);
    const formRef = ref<typeof ElForm | null>(null);

    function onSeach() {
      select.value?.blur();
      nextTick(() => {
        table.value?.reset();
      });
    }
    return {
      state,
      table,
      select,
      formRef,
      downloadMap,
      rules: {
        taxPeriod: [
          {
            required: true,
            message: "请选择税款所属期",
            trigger: "change",
          },
        ],
        taxTypes: [
          {
            required: true,
            message: "请选择返还企业税种",
            trigger: "change",
          },
        ],
        // file: [
        //   {
        //     required: true,
        //     validator: function (
        //       rule: InternalRuleItem,
        //       value: File | string,
        //       callback: (arg?: Error) => void
        //     ) {
        //       if (!rule.required) {
        //         return callback();
        //       }
        //       if (value) {
        //         if (typeof value === "string") return callback();
        //         const result = /\.[\w]*$/.exec(value.name);
        //         if (
        //           result &&
        //           ".xls;.xlsx"
        //             .toLowerCase()
        //             .indexOf(result[0].toLowerCase()) !== -1
        //         ) {
        //           callback();
        //         } else {
        //           callback(new Error("请选择Excel文件"));
        //         }
        //       } else {
        //         callback(new Error("请选择文件"));
        //       }
        //     },
        //     trigger: "change",
        //   },
        // ],
      },
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
      onSubmit() {
        formRef.value?.validate().then((valid: boolean) => {
          if (valid) {
            const date = state.form.taxPeriod;
            state.loading = true;
            ipark_servicepark_downLoadEnterpriseRewardTemplate({
              taxPeriodStart:
                date && Array.isArray(date) && date.length > 1 ? date[0] : "",
              taxPeriodEnd:
                date && Array.isArray(date) && date.length > 1 ? date[1] : "",
              taxTypes: state.form.taxTypes.join(),
            })
              .then(({ data, msg }) => {
                FileSaver.saveAs(data, msg);
                state.showDownload = false;
              })
              .finally(() => {
                state.loading = false;
              });
          } else {
            return false;
          }
        });
      },
      request(options: AnyObject) {
        // if (!state.form.taxPeriod || state.form.taxPeriod.length < 1) {
        //   return ElMessageBox.alert("请选择所属批次", "出错", {
        //     type: "error",
        //     confirmButtonText: "确定",
        //   });
        // }
        getPercentage(
          function (ws) {
            state.loading = true;
            state.status = "";
            state.percentage = 0;
            return ipark_servicepark_importEnterpriseRewardData({
              taxPeriodStart: state.form.taxPeriod[0],
              taxPeriodEnd: state.form.taxPeriod[1],
              file: options.file,
            })
              .then(
                () => {
                  state.showUpload = false;
                  router.push({
                    path: "/financialincentive-enterprisedatadetail",
                    query: {
                      taxPeriodStart: state.form.taxPeriod[0],
                      taxPeriodEnd: state.form.taxPeriod[1],
                      keyword: state.dataFilter.keyword,
                    },
                  });
                },
                () => {
                  ws.close();
                }
              )
              .finally(() => {
                state.loading = false;
              });
          },
          function (data) {
            if (data.type === "status") {
              state.status = data.msg;
            } else {
              state.percentage = data.msg;
            }
          }
        );
      },
      onDownload() {
        state.showDownload = true;
        // ipark_servicepark_downLoadEnterpriseRewardTemplate({
        //   taxPeriodStart: state.form.taxPeriod[0],
        //   taxPeriodEnd: state.form.taxPeriod[1],
        // }).then(({ data, msg }) => {
        //   FileSaver.saveAs(data, msg);
        // });
      },
      onSeach,
      onDetail(row: AnyObject) {
        router.push({
          path: "/financialincentive-enterprisedatadetail",
          query: {
            taxPeriodStart: row.taxPeriodStart,
            taxPeriodEnd: row.taxPeriodEnd,
            keyword: state.dataFilter.keyword,
          },
        });
      },
      onUpload(row: AnyObject | undefined) {
        state.showUpload = true;
        state.form.taxPeriod = row
          ? [row.taxPeriodStart, row.taxPeriodEnd]
          : [];
      },
      remoteMethod(keyword: string) {
        state.loading = true;
        servicepark_enterpriseSearch({
          keyword,
        })
          .then(({ data }) => {
            state.enterprises = data;
          })
          .finally(() => {
            state.loading = false;
          });
      },
      getData(param: Iparam) {
        state.page = param.index;
        return ipark_servicepark_queryEnterpriseRewardList({
          keyword: state.dataFilter.keyword,
          page: param.index,
          per_page: param.size,
        }).then(({ data }) => {
          return {
            data: data.rows,
            total: data.totalRows,
          };
        });
      },
    };
  },
});
