
import { defineComponent, reactive, ref, onMounted } from "vue";
import FileSaver from "file-saver";
import Page from "@/components/Page/Index.vue";
import ModifyBusinesslicense from "@/components/ModifyBusinessLicense/Index.vue";
import { getNewFormData } from "@/components/ModifyBusinessLicense/utils";
import DataTable from "@/components/DataTable/Index.vue";
import ImageView from "@/components/ImageView/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import { AnyObject, isAbsHttp } from "@/utils";
import {
  ipark_dic_enterprisetypeList,
  ipark_dic_industrycategoryList,
  ipark_servicepark_enterpriseList,
  ipark_servicepark_enterpriseRegistInfo,
  servicepark_enterpriseSearch,
  ipark_servicepark_enterpriseList_download,
} from "@/api";
import { rsHost } from "@/api/utils";

export default defineComponent({
  components: {
    Page,
    DataTable,
    ImageView,
    ModifyBusinesslicense,
  },
  setup() {
    const state = reactive({
      dataFilter: {
        keyword: "",
        enterpriseType: "",
        enterpriseBusiness: "",
        enterpriseState: "",
        enterpriseRegistrationDate: [],
      },
      enterprisesType: [] as AnyObject[],
      enterprisesBusiness: {
        freqList: [
          { industryCode: "code", industryName: "name" },
        ] as AnyObject[],
        totalList: [
          { industryCode: "code", industryName: "name" },
        ] as AnyObject[],
      },
      form: getNewFormData(),
      show: false,
      showImage: false,
      isNew: false,
      title: "营业执照",
      enterprises: [] as AnyObject[],
      loading: false,
      image: "",
    });
    onMounted(() => {
      Promise.all([
        ipark_dic_enterprisetypeList(),
        ipark_dic_industrycategoryList(),
      ]).then(([enterprisetype, industrycategory]) => {
        state.enterprisesType = enterprisetype.data.result;
        state.enterprisesBusiness = industrycategory.data;
      });
    });
    const table = ref<typeof DataTable | null>(null);
    return {
      table,
      state,
      remoteMethod(keyword: string) {
        state.loading = true;
        servicepark_enterpriseSearch({
          keyword,
        })
          .then(({ data }) => {
            state.enterprises = data;
          })
          .finally(() => {
            state.loading = false;
          });
      },
      onAdd() {
        state.form = getNewFormData();
        state.isNew = true;
        state.show = true;
      },
      onDetail(row: AnyObject) {
        state.title = row.taxpayerName;
        ipark_servicepark_enterpriseRegistInfo({
          taxpayerId: row.taxpayerId,
        }).then(({ data }) => {
          state.form = getNewFormData({
            enterprisefile: isAbsHttp(data.licenseFilestore)
              ? data.licenseFilestore
              : rsHost + data.licenseFilestore,
            enterprisename: data.taxpayerName,
            enterprisecode: data.taxpayerId,
            enterprisetype: data.enterpriseTypeCode,
            enterprisebusiness: data.industryCode,
            enterprisescope: data.activitiesScope,
            enterpriseaddress: data.registAddress,
            enterpriseregistrationDate: data.registionDate,
            establishmentDate: data.establishmentDate,
            legalpersonName: data.legalRepresentativeName,
            legalpersonNo: data.legalRepresentativeIdNo,
            legalpersonMobile: data.legalRepresentativeMobile,
            contactsName: data.enterpriseLiaisonName,
            contactsMobile: data.enterpriseLiaisonMobile,
            attractpartner: data.partnerId,
            parkaccesser: data.serviceParkLiaisonMobile,
          });
          state.isNew = false;
          state.show = true;
        });
      },
      onSeach() {
        table.value?.reset();
      },
      onDownload() {
        const date = state.dataFilter.enterpriseRegistrationDate;
        return ipark_servicepark_enterpriseList_download({
          enterpriseTypeCode: state.dataFilter.enterpriseType,
          industryCode: state.dataFilter.enterpriseBusiness,
          operateStatus: state.dataFilter.enterpriseState,
          beginDate:
            date && Array.isArray(date) && date.length > 1 ? date[0] : "",
          endDate:
            date && Array.isArray(date) && date.length > 1 ? date[1] : "",
          keyword: state.dataFilter.keyword,
        }).then(({ data, msg }) => {
          FileSaver.saveAs(data, msg);
        });
      },
      onShowImage(row: AnyObject) {
        state.image = row.licenseFilestore;
        state.showImage = true;
      },
      getData(param: Iparam) {
        const date = state.dataFilter.enterpriseRegistrationDate;
        return ipark_servicepark_enterpriseList({
          enterpriseTypeCode: state.dataFilter.enterpriseType,
          industryCode: state.dataFilter.enterpriseBusiness,
          operateStatus: state.dataFilter.enterpriseState,
          beginDate:
            date && Array.isArray(date) && date.length > 1 ? date[0] : "",
          endDate:
            date && Array.isArray(date) && date.length > 1 ? date[1] : "",
          keyword: state.dataFilter.keyword,
          page: param.index,
          per_page: param.size,
        }).then(({ data }) => {
          return {
            data: data.rows,
            total: data.totalRows,
          };
        });
      },
    };
  },
});
