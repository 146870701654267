
import { defineComponent, reactive, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import { ElSelect } from "element-plus";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
import Layer from "@/components/Layer/Index.vue";
import Upload from "@/components/Upload/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import { AnyObject } from "@/utils";
import {
  ipark_servicepark_queryTaxRewardList,
  servicepark_enterpriseSearch,
} from "@/api";

export default defineComponent({
  components: {
    Page,
    DataTable,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      dataFilter: {
        keyword: "",
      },
      form: {
        migrateEnterprise: [],
        checked1: "",
      },
      tableHeight: 0,
      page: 0,
      loading: false,
      enterprises: [] as AnyObject[],
      showDownload: false,
      showUpload: false,
    });
    const table = ref<typeof DataTable | null>(null);
    const select = ref<typeof ElSelect | null>(null);
    return {
      state,
      table,
      select,
      shortcuts: [
        {
          text: "本月",
          value: [new Date(), new Date()],
        },
      ],
      onSeach() {
        select.value?.blur();
        nextTick(() => {
          table.value?.reset();
        });
      },
      request(options: AnyObject) {
        console.log(options);
        debugger;
      },
      onSubmit(options: AnyObject) {
        state.showDownload = false;
      },
      onDetail(row: AnyObject) {
        router.push({
          path: "/financialincentive-auditdetail",
          query: {
            from: "financeDataQuery",
            taxPeriodStart: row.taxPeriodStart,
            taxPeriodEnd: row.taxPeriodEnd,
            keyword: state.dataFilter.keyword,
          },
        });
      },
      remoteMethod(keyword: string) {
        state.loading = true;
        servicepark_enterpriseSearch({
          keyword,
        })
          .then(({ data }) => {
            state.enterprises = data;
          })
          .finally(() => {
            state.loading = false;
          });
      },
      getData(param: Iparam) {
        state.page = param.index;
        return ipark_servicepark_queryTaxRewardList({
          keyword: state.dataFilter.keyword,
          page: param.index,
          per_page: param.size,
        }).then(({ data }) => {
          state.tableHeight = window.innerHeight - 245;
          return {
            data: data.rows,
            total: data.totalRows,
          };
        });
      },
    };
  },
});
