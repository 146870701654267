
import {
  defineComponent,
  inject,
  computed,
  reactive,
  ref,
  watch,
  Prop,
} from "vue";
import {
  ElFormContext,
  ElFormItemContext,
  elFormKey,
  elFormItemKey,
} from "element-plus";
import FileSaver from "file-saver";
import { UUIDGenerator, getURLName } from "@/utils";
// const fileType = {
//   "application/pdf": /\.pdf/i,
//   "image/png": /\.png/i,
//   "image/jpeg": /\.jpe?g/i,
// };

// function validateMulti(type: string){
//   if(!/[^\/]\/\*$/.test(type)) return false;
//   const prefix = type.replace("/*", "");
//   for(const key in fileType){

//   }
// }
export default defineComponent({
  name: "file-select",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "image/*",
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [File, String],
    },
    placeholder: {
      type: String,
      default: "请选择文件",
    },
    validateEvent: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const state = reactive({
      hovering: false,
      fileKey: UUIDGenerator(),
    });
    const file = ref<HTMLInputElement | undefined>(undefined);
    const elForm = inject(elFormKey, {} as ElFormContext);
    const elFormItem = inject(elFormItemKey, {} as ElFormItemContext);
    const displayName = computed(() => {
      if (props.modelValue) {
        if (typeof props.modelValue === "string") {
          if (props.label) {
            return props.label;
          } else {
            const tmp = props.modelValue.split("/");
            return tmp[tmp.length - 1];
          }
        } else {
          return props.modelValue.name;
        }
      } else {
        return "";
      }
    });
    watch(
      () => props.modelValue,
      () => {
        state.fileKey = UUIDGenerator();
      }
    );
    const inputDisabled = computed(() => props.disabled || elForm.disabled);
    const validateState = computed(() => elFormItem.validateState || "");

    return {
      state,
      file,
      showClear: computed(
        () => displayName.value && state.hovering && !props.disabled
      ),
      fileID: computed(() => "fileSelect" + state.fileKey),
      displayName,
      inputDisabled,
      onClick() {
        if (
          !props.disabled &&
          props.modelValue &&
          typeof props.modelValue === "string"
        ) {
          FileSaver.saveAs(props.modelValue, getURLName(props.modelValue));
        }
      },
      change() {
        const files = file.value?.files;
        if (files && files.length > 0) {
          emit("update:modelValue", files[0]);
          if (props.validateEvent && validateState) {
            elFormItem.validate?.("change");
          }
        } else {
          state.fileKey = UUIDGenerator();
        }
      },
      clear() {
        state.fileKey = UUIDGenerator();
        emit("update:modelValue", undefined);
        if (props.validateEvent && validateState) {
          elFormItem.validate?.("change");
        }
      },
    };
  },
});
