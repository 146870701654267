import { clone } from "@/utils";

export interface IForm {
  enterprisefile: string | File; // 营业执照（文件）
  enterprisename: string; // 企业名称
  enterprisecode: string; // 企业税号
  verifycode: string; // 验证税号
  enterprisetype: string; // 企业类型
  enterprisebusiness: string; // 主营行业
  enterprisescope: string; // 经营范围
  enterpriseaddress: string; // 注册地址
  enterpriseregistrationDate: string; // 注册日期
  establishmentDate: string; // 成立日期
  legalpersonName: string; // 法人姓名
  legalpersonNo: string; // 法人身份证
  legalpersonMobile: string; // 法人手机
  contactsName: string; // 企业联系人姓名
  contactsMobile: string; // 企业联系人手机
  attractpartner: string; // 招商合伙人
  parkaccesser: string; // 园区对接人
  attractprotocol: string | File; // 招商入驻协议(文件)
}

const form: IForm = {
  enterprisefile: "", // 营业执照（文件）
  enterprisename: "", // 企业名称
  enterprisecode: "", // 企业税号
  verifycode: "", // 验证税号
  enterprisetype: "", // 企业类型
  enterprisebusiness: "", // 主营行业
  enterprisescope: "", // 经营范围
  enterpriseaddress: "", // 注册地址
  enterpriseregistrationDate: "", // 注册日期
  establishmentDate: "", // 成立日期
  legalpersonName: "", // 法人姓名
  legalpersonNo: "", // 法人身份证
  legalpersonMobile: "", // 法人手机
  contactsName: "", // 企业联系人姓名
  contactsMobile: "", // 企业联系人手机
  attractpartner: "", // 招商合伙人
  parkaccesser: "", // 园区对接人
  attractprotocol: "", // 招商入驻协议(文件)
};

export type IRule = {
  [P in keyof IForm]: boolean;
};

const rule: IRule = {
  enterprisefile: true, // 营业执照（文件）
  enterprisename: true, // 企业名称
  enterprisecode: true, // 企业税号
  verifycode: true, // 验证税号
  enterprisetype: true, // 企业类型
  enterprisebusiness: true, // 主营行业
  enterprisescope: true, // 经营范围
  enterpriseaddress: true, // 注册地址
  enterpriseregistrationDate: true, // 注册日期
  establishmentDate: true, // 成立日期
  legalpersonName: true, // 法人姓名
  legalpersonNo: true, // 法人身份证
  legalpersonMobile: true, // 法人手机
  contactsName: true, // 企业联系人姓名
  contactsMobile: true, // 企业联系人手机
  attractpartner: true, // 招商合伙人
  parkaccesser: true, // 园区对接人
  attractprotocol: false, // 招商入驻协议(文件)
};

export function getNewFormData(obj: Partial<IForm> = {}): IForm {
  return {
    ...clone(form),
    ...obj,
  };
}

export function getNewFormRule(obj: Partial<IRule> = {}): IRule {
  return {
    ...clone(rule),
    ...obj,
  };
}
