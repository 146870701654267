
import { defineComponent, reactive, ref, PropType, computed } from "vue";
import ImageView from "../ImageView/Content.vue";
import Content from "./Content.vue";
import RContent from "./readContent.vue";
import { IForm, IRule, getNewFormRule } from "./utils";

export default defineComponent({
  components: {
    ImageView,
    MContent: Content,
    RContent: RContent,
  },
  props: {
    data: {
      type: Object as PropType<Partial<IForm>>,
      default: () => ({}),
    },
    readData: {
      type: Object,
      default: () => ({}),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    lock: {
      type: Boolean,
      default: false,
    },
    leftHeight: {
      type: String,
      default: "350px",
    },
    rightHeight: {
      type: String,
      default: "350px",
    },
    isNew: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { expose }) {
    const content = ref<typeof Content | null>(null);
    const RContent = ref<typeof Content | null>(null);
    const image = ref<typeof ImageView | null>(null);

    const state = reactive({
      angel: 0,
      innersrc: "",
      src: "",
    });
    function validator() {
      return content.value?.validator();
    }
    expose({ validator, image });
    function getRUL(file: string | File | undefined): string {
      if (file) {
        if (typeof file === "string") {
          return file;
        } else {
          return URL.createObjectURL(file);
        }
      } else {
        return "";
      }
    }
    const src = computed(() => {
      if (src.value) {
        URL.revokeObjectURL(src.value);
      }
      return props.readOnly
        ? getRUL(props.readData.licenseFilestore)
        : getRUL(props.data.enterprisefile);
    });
    return {
      content,
      RContent,
      image,
      state,
      rule: computed(() => {
        return getNewFormRule(
          props.isNew
            ? {}
            : {
                enterprisefile: false, // 营业执照（文件）
                // enterprisename: false, // 企业名称
                // enterprisecode: false, // 企业税号
                verifycode: false, // 税号验证
                // enterprisetype: false, // 企业类型
                // enterprisebusiness: false, // 主营行业
                // enterprisescope: false, // 经营范围
                // enterpriseaddress: false, // 注册地址
                // enterpriseregistrationDate: false, // 成立日期
                // legalpersonName: false, // 法人姓名
                // legalpersonNo: false, // 法人身份证
                // legalpersonMobile: false, // 法人手机
                // contactsName: false, // 企业联系人姓名
                // contactsMobile: false, // 企业联系人手机
                // attractpartner: false, // 招商合伙人
                // parkaccesser: false, // 园区对接人
                attractprotocol: false, // 招商入驻协议(文件)
              }
        );
      }),
      src,
      onFilechange(file: File) {
        if (state.innersrc) {
          URL.revokeObjectURL(state.innersrc);
        }
        state.innersrc = getRUL(file);
      },
    };
  },
});
