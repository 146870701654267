
import { defineComponent, reactive } from "vue";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import { AnyObject } from "@/utils";

const initials = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"];

export default defineComponent({
  components: {
    Page,
    DataTable,
  },
  setup() {
    const state = reactive({
      dataFilter: {
        keyword: "",
        enterpriseType: "",
        enterpriseBusiness: "",
        enterpriseState: "",
        enterpriseRegistrationDate: "",
      },
      options: Array.from({ length: 100 }).map((_, idx) => ({
        value: `Option ${idx + 1}`,
        label: `${initials[idx % 10]}${idx}`,
      })),
      form: {
        migrateEnterprise: [],
      },
      show: true,
      showProgress: true,
    });
    return {
      state,
      onAdd() {
        state.show = true;
      },
      onSeach() {
        state.show = true;
      },

      onSubmit(options: AnyObject) {
        console.log(options);
        debugger;
      },
      getData(param: Iparam) {
        console.log(param, "=============");
        return new Promise((reslove, reject) => {
          setTimeout(() => {
            reslove({
              data: [
                {
                  date: "2016-05-03",
                  name: "Tom",
                  address: "No. 189, Grove St, Los Angeles",
                },
                {
                  date: "2016-05-02",
                  name: "Tom",
                  address: "No. 189, Grove St, Los Angeles",
                },
                {
                  date: "2016-05-04",
                  name: "Tom",
                  address: "No. 189, Grove St, Los Angeles",
                },
                {
                  date: "2016-05-01",
                  name: "Tom",
                  address: "No. 189, Grove St, Los Angeles",
                },
                {
                  date: "2016-05-01",
                  name: "Tom",
                  address: "No. 189, Grove St, Los Angeles",
                },
                {
                  date: "2016-05-01",
                  name: "Tom",
                  address: "No. 189, Grove St, Los Angeles",
                },
              ],
              total: 20,
            });
          }, 1000);
        });
      },
    };
  },
});
