
import { defineComponent, onUnmounted, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { AnyObject, clone, UUIDGenerator } from "@/utils";

const list = [
  {
    icon: "icon-shouye",
    name: "首页",
    code: "com.hive.ipark.homePage",
    path: "/",
    onlyMenuItem: true, //仅单个菜单
    children: [],
  },
  {
    icon: "icon-yuanqufuwu",
    name: "园区招商",
    code: "园区招商",
    path: "",
    onlyMenuItem: false,
    children: [
      {
        icon: "",
        name: "招商宣传册",
        code: "com.hive.ipark.industrialParkBrochure.PC",
        path: "/industrialParkBrochure",
      },
      {
        icon: "",
        name: "招商伙伴",
        code: "com.hive.ipark.partnerInvitationForPark.PC",
        path: "/investment-partners",
      },
      {
        icon: "",
        name: "服务热线",
        code: "com.hive.ipark.customerService.PC",
        path: "/service-hotline",
      },
    ],
  },
  {
    icon: "icon-build",
    name: "工商服务",
    code: "工商服务",
    path: "",
    onlyMenuItem: false,
    children: [
      {
        icon: "",
        name: "入园企业登记/变更",
        code: "com.hive.ipark.enterpriseRegistion.PC",
        path: "/financetax-register",
      },
      {
        icon: "",
        name: "园区企业迁出/注销",
        code: "com.hive.ipark.enterpriseCancellation.PC",
        path: "/financetax-writeoff",
      },
      {
        icon: "",
        name: "自然人纳税人登记",
        code: "com.hive.ipark.personRegistion.PC",
        path: "/financetax-naturalRegistion",
      },
    ],
  },
  {
    icon: "icon-shuiwu",
    name: "财税服务",
    code: "财税服务",
    path: "",
    onlyMenuItem: false,
    children: [
      {
        icon: "",
        name: "上传企业财务报表",
        code: "com.hive.ipark.importEnterpriseStatements.PC",
        path: "/financetax-financeUpload",
      },
      // {
      //   icon: "",
      //   name: "上传企业缴税数据",
      //   code: "com.hive.ipark.importEnterpriseTax.PC",
      //   path: "/financetax-paymentUpload",
      // },
      // {
      //   icon: "",
      //   name: "注销企业登记",
      //   code: "注销企业登记",
      //   path: "/financetax-migrate",
      // },
    ],
  },
  {
    icon: "icon-jiangli",
    name: "财政奖励",
    code: "财政奖励",
    path: "",
    onlyMenuItem: false,
    children: [
      // {
      //   icon: "",
      //   name: "核对财政奖励基数",
      //   code: "com.hive.ipark.financeDataReconciliation.PC",
      //   path: "/financialincentive-audit",
      // },
      {
        icon: "",
        name: "企业缴税数据",
        code: "com.hive.ipark.importEnterpriseTax.PC",
        path: "/financialincentive-enterprisepayment",
      },
      {
        icon: "",
        name: "上传企业扶持数据",
        code: "com.hive.ipark.importEnterpriseReward.PC",
        path: "/financialincentive-uploadenterprisedata",
      },
      {
        icon: "",
        name: "招商奖励数据",
        code: "com.hive.ipark.importPartnerReward.PC",
        path: "/financialincentive-uploadincentivedata",
      },
    ],
  },
  {
    icon: "icon-tongji",
    name: "统计查询",
    code: "统计查询",
    path: "",
    onlyMenuItem: false,
    children: [
      {
        icon: "",
        name: "入园企业统计",
        code: "com.hive.ipark.enterpriseListForPark.PC",
        path: "/enterpriseListForPark",
      },
      {
        icon: "",
        name: "园区产税统计",
        code: "com.hive.ipark.taxListForPark.PC",
        path: "/taxListForPark",
      },
      // {
      //   icon: "",
      //   name: "奖励基数查询",
      //   code: "com.hive.ipark.financeDataQuery.PC",
      //   path: "/financeDataQuery",
      // },
      {
        icon: "",
        name: "扶持企业统计",
        code: "com.hive.ipark.enterpriseRewardListForPark.PC",
        path: "/enterpriseRewardListForPark",
      },
      {
        icon: "",
        name: "招商奖励统计",
        code: "com.hive.ipark.partnerRewardListForPark.PC",
        path: "/partnerRewardListForPark",
      },
    ],
  },
  {
    icon: "icon-shezhi",
    name: "设置",
    code: "设置",
    path: "",
    onlyMenuItem: false,
    children: [
      {
        icon: "",
        name: "产业园设置",
        code: "com.hive.ipark.serviceParkSettings.PC",
        path: "/set-setting",
      },
    ],
  },
];

function changePos(arr: AnyObject[]): AnyObject[] {
  const tmp: AnyObject[] = [];
  arr.forEach((val) => {
    if (val.children && val.children.length > 0) {
      const v = clone(val);
      const c = v.children;
      delete v.children;
      v.isTitle = true;
      tmp.push(v);
      Array.prototype.splice.apply(tmp, [tmp.length, 0, ...changePos(c)]);
    } else {
      const v = clone(val);
      v.isIcon = val.onlyMenuItem;
      tmp.push(v);
    }
  });
  return tmp;
}
export default defineComponent({
  setup(/* props, context */) {
    function gen(list: AnyObject[]): AnyObject[] {
      if (sessionStorage.menu) {
        const menu = JSON.parse(sessionStorage.menu);
        const menuMap: AnyObject = {};
        menu.forEach((val: AnyObject) => {
          menuMap[val.functionId] = val;
        });
        const chunk = Object.keys(menuMap).join(";");
        const retain = list
          .map((val) => {
            return {
              ...val,
              children: val.children.filter(
                (val: AnyObject) => chunk.indexOf(val.code) !== -1
              ),
            };
          })
          .filter(
            (val: any) => val.children.length !== 0 || val.path.trim() != ""
          )
          .map((val) => {
            return {
              ...val,
              children: val.children.map((v: AnyObject) => {
                const tmp = menuMap[v.code] || {};
                return {
                  ...v,
                  icon: tmp.iconName,
                  name: tmp.functionNameCH,
                };
              }),
            };
          });
        console.log(retain);
        return retain;
      } else {
        return [];
      }
    }
    const route = useRoute();
    let menus: AnyObject[] = [];
    if (sessionStorage.withpathmenus) {
      menus = JSON.parse(sessionStorage.withpathmenus);
    } else {
      menus = changePos(gen(list));
      sessionStorage.withpathmenus = JSON.stringify(menus);
    }
    // window.__withpathmenus = menus;
    const state = reactive({
      menus,
      key: UUIDGenerator(),
    });
    function buildMenu() {
      state.menus = changePos(gen(list));
      state.key = UUIDGenerator();
    }
    window.addEventListener("buildmenu", buildMenu, false);
    onUnmounted(() => {
      window.removeEventListener("buildmenu", buildMenu);
    });
    return {
      defaultActive: (() => {
        let defaultActive = "weiyi0";
        for (let i = 0; i < state.menus.length; i++) {
          if (state.menus[i].path === route.path) {
            defaultActive = "weiyi" + i;
            break;
          }
        }
        return defaultActive;
      })(),
      state,
    };
  },
});
