import { createApp, nextTick } from "vue";
import ElementPlus, { iconProps } from "element-plus";
import dayjs from "dayjs";
import locale from "element-plus/lib/locale/lang/zh-cn";
import App from "./App.vue";
import "./registerServiceWorker";
import { currencyFormat } from "@/utils/index";
import router from "./router";
// import store from "./store";

import "element-plus/dist/index.css";

const app = createApp(App);
app /* .use(store) */
  .use(router)
  .use(ElementPlus, { locale, zIndex: 2000 });

app.config.errorHandler = (err, vm, info) => {
  console.error(err);
};
app.directive("inputcurrency", {
  mounted(el) {
    if (
      !el.__vueParentComponent ||
      !el.__vueParentComponent.ctx ||
      el.__vueParentComponent.ctx.modelValue === undefined ||
      !el.__vueParentComponent.ctx.input
    )
      return;
    const input: HTMLInputElement = el.__vueParentComponent.ctx.input;

    if (
      typeof el.__vueParentComponent.ctx.modelValue !== "string" &&
      typeof el.__vueParentComponent.ctx.modelValue !== "number"
    )
      return;
    function format() {
      if (!el.__vueParentComponent.ctx.modelValue) return;
      const value = String(el.__vueParentComponent.ctx.modelValue)
        .replace(/[^\d.]/g, "")
        .replace(/\./, "點")
        .replace(/\./g, "")
        .replace("點", ".");
      input.value = currencyFormat.format(parseFloat(value));
    }
    function focus() {
      input.setSelectionRange(0, input.value.length);
    }
    if (!el.__bind_blur) {
      input.addEventListener("blur", format);
      el.__bind_blur = input;
      el.__bind_blur_handle = format;
    }
    if (!el.__bind_focus) {
      input.addEventListener("focus", focus);
      el.__bind_focus = input;
      el.__bind_focus_handle = focus;
    }
    if (el.__vueParentComponent.ctx.modelValue) {
      setTimeout(() => {
        format();
      }, 10);
    }
  },
  beforeUnmount(el) {
    if (el.__bind_blur) {
      el.__bind_blur.removeEventListener("blur", el.__bind_blur_handle);
    }
    if (el.__bind_focus) {
      el.__bind_focus.removeEventListener("focus", el.__bind_focus_handle);
    }
  },
});

app.directive("currency", (el) => {
  if (el.props) return;
  el.textContent = currencyFormat.format(el.textContent);
});
app.directive("date", (el, binding) => {
  if (el.props) return;
  nextTick(() => {
    const date = dayjs(
      (binding.value.val || el.textContent).trim(),
      String(binding.value.from || "YYYYMMDD").trim()
    );
    el.textContent = date.isValid()
      ? date.format(String(binding.value.to || "YYYY年MM月DD日").trim())
      : "";
  });
});

app.mount("#app");
