
import { defineComponent, reactive, ref, onMounted, nextTick } from "vue";
import * as echarts from "echarts";
import DataTable from "@/components/DataTable/Index.vue";
import { AnyObject, toNum } from "@/utils";
import Page from "@/components/Page/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import { ElSelect } from "element-plus";
import {
  ipark_servicepark_getPartnerRewardYearList,
  ipark_servicepark_partnerRewardListByPeroid,
  ipark_servicepark_partnerRewardListByPartner,
  ipark_servicepark_partnerRewardDetailByPeroid,
  ipark_servicepark_partnerRewardDetailByPartner,
  ipark_servicepark_partnerRewardSource,
  ipark_servicepark_partnerInvitationSigned,
} from "@/api/index";
export default defineComponent({
  emits: ["keyup"],
  components: {
    DataTable,
    Page,
  },
  methods: {
    // 扶持企业按批次统计图表
    canvasFirst(mycharData: any, elChart: HTMLElement | null) {
      if (!elChart) return;
      echarts.init(elChart).resize();
      echarts.init(elChart).setOption(this.FirstOption(mycharData, this.state));
    },
    FirstOption(data: any[], params: any) {
      data = data.reverse();
      return {
        title: {
          text: this.state.partnerName,
          left: "center",
          textStyle: {
            fontSize: 14,
          },
          padding: [20, 20],
        },
        grid: {
          containLabel: true,
          top: 60,
          left: 20,
          right: 20,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        xAxis: [
          {
            type: "category",
            data: data.map((val) => val.taxPeriodStr),
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
          },
          {
            type: "value",
            name: "单位：万元",
            offset: -20,
          },
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            barWidth: "60%",
            data: data.map((val) => toNum(val.rewardAmount)),
          },
        ],
      };
    },
    SecondOption(data: any[], params: any) {
      data = data.reverse();
      const tmp = data.map((val) => {
        return parseFloat(val.rewardAmount);
      });
      const total = tmp.reduce((r, e) => r + e, 0);
      return {
        title: {
          text: "招商奖励名单",
          subtext: params.taxPeriodStr,
          left: "center",
          textStyle: {
            fontSize: 14,
          },
          padding: [20, 20],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        grid: {
          containLabel: true,
          top: 60,
          left: 0,
          right: 20,
          bottom: 20,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: [
          {
            type: "category",
            data: data.map((val) =>
              val.partnerName.length > 10
                ? val.partnerName.substr(0, 8) + "..."
                : val.partnerName
            ),
            nameTextStyle: {
              fontSize: 10,
            },
          },
          {
            type: "value",
            name: "单位：万元",
            offset: -20,
          },
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            data: tmp.map((val) => {
              return {
                value: toNum(val),
                label: {
                  show: true,
                  position: val > total / 4 ? "insideRight" : "right",
                },
              };
            }),
          },
        ],
      };
    },
    canvasSecond(mycharData: any, elChart: HTMLElement | null) {
      if (!elChart) return;
      echarts.init(elChart).resize();
      echarts
        .init(elChart)
        .setOption(this.SecondOption(mycharData, this.state));
    },
    getDataPeroid(param: Iparam) {
      this.state.lastestPeroid = param.index;
      return ipark_servicepark_partnerRewardListByPeroid({
        year: this.formInline.registionYear || "",
        keyword: this.formInline.searchKeyWord || "",
        page: param.index,
        per_page: param.size,
      }).then((obj) => {
        this.state.sumRow = obj.data.sumRow || {};
        this.state.partnerName = "招商奖励按批次统计";
        this.state.tableHeight1 = Number(
          this.tableHeightNum("table1", "data-table__header", 446)
        );
        this.canvasFirst(
          obj.data.chat,
          document.getElementById("mychartPeroid")
        );
        return {
          data: obj.data.rows,
          total: obj.data.totalRows,
        };
      });
    },
    getDataPartner(param: Iparam) {
      if (this.state.activeName == "partner") {
        return ipark_servicepark_partnerRewardListByPartner({
          year: this.formInline.registionYearSecond || "",
          page: param.index,
          per_page: param.size,
        }).then((obj) => {
          this.state.sumRowSecond = obj.data.sumRow || {};
          this.state.tableHeight2 = Number(
            this.tableHeightNum("table2", "data-table__header", 446)
          );
          this.canvasSecond(
            obj.data.chat,
            document.getElementById("mychartPartner")
          );
          return {
            data: obj.data.rows,
            total: obj.data.totalRows,
          };
        });
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    },
    getSummaries(param: { columns: any; data: any }) {
      const sums = ["合计"];
      param.columns.map((obj: any, index: number) => {
        if (index > 0) {
          sums.push(this.state.sumRow[obj.property + "Sum"]);
        }
      });
      return sums;
    },
    getSummariesPartner(param: { columns: any; data: any }) {
      const sums = ["合计"];
      param.columns.map((obj: any, index: number) => {
        if (index > 0) {
          sums.push(this.state.sumRowSecond[obj.property + "Sum"]);
        }
      });
      return sums;
    },
    getDataDialogPeroid(param: Iparam) {
      if (this.state.dialogVisiblePeroid) {
        return ipark_servicepark_partnerRewardDetailByPeroid({
          keyword: this.formInline.searchKeyWord,
          taxPeriodStart: this.state.taxPeriodStart,
          taxPeriodEnd: this.state.taxPeriodEnd,
          page: param.index,
          per_page: param.size,
        }).then((obj) => {
          this.state.sumRowDialog = obj.data.sumRow || {};
          this.canvasSecond(
            obj.data.chat,
            document.getElementById("mychartPeroidDialog")
          );
          return {
            data: obj.data.rows,
            total: obj.data.totalRows,
          };
        });
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    },
    getSummariesDialogPeroid(param: { columns: any; data: any }) {
      const sums = ["合计"];
      param.columns.map((obj: any, index: number) => {
        if (index > 0) {
          sums.push(this.state.sumRowDialog[obj.property + "Sum"]);
        }
      });
      return sums;
    },
    getSummariesPartnerRewardSource(param: { columns: any; data: any }) {
      const sums = ["合计"];
      param.columns.map((obj: any, index: number) => {
        if (index > 0) {
          sums.push(this.summaryData.sumRow[obj.property]);
        }
      });
      return sums;
    },
    getDataDialogPartner(param: Iparam) {
      this.state.lastestPartner = param.index;
      if (this.state.dialogVisiblePartner) {
        return ipark_servicepark_partnerRewardDetailByPartner({
          year: this.formInline.registionYear || "",
          partnerId: this.state.partnerId || "",
          page: param.index,
          per_page: param.size,
        }).then((obj) => {
          this.state.sumRowSecondDialog = obj.data.sumRow || {};
          this.canvasFirst(
            obj.data.chat,
            document.getElementById("mychartPartnerDialog")
          );
          return {
            data: obj.data.rows,
            total: obj.data.totalRows,
          };
        });
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    },
    getDataDialog(param: Iparam) {
      if (this.state.partnerRewardSourceVisible) {
        return ipark_servicepark_partnerRewardSource({
          taxPeriodStart: this.state.taxPeriodStart || "",
          taxPeriodEnd: this.state.taxPeriodEnd || "",
          partnerId: this.state.partnerId || "",
          page: param.index,
          per_page: param.size,
        }).then((obj) => {
          this.summaryData.sumRow = obj.data.sumRow || {};
          this.summaryData.partnerName = this.state.partnerName;
          this.summaryData.totalTaxAmount = obj.data.summary.totalRewardAmount;
          this.summaryData.prop = obj.data.summary.prop;
          this.summaryData.totalRewardAmount = obj.data.summary.totalTaxAmount;
          return {
            data: obj.data.rows,
            total: obj.data.totalRows,
          };
        });
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    },
    getSummariesDialogPartner(param: { columns: any; data: any }) {
      const sums = ["合计"];
      param.columns.map((obj: any, index: number) => {
        if (index > 0) {
          sums.push(this.state.sumRowSecondDialog[obj.property + "Sum"]);
        }
      });
      return sums;
    },
  },
  setup() {
    const TablePeroid = ref<typeof DataTable | null>(null);
    const TablePartner = ref<typeof DataTable | null>(null);
    const TableDialog = ref<typeof DataTable | null>(null);
    const TableDialogPeroid = ref<typeof DataTable | null>(null);
    const TableDialogPartner = ref<typeof DataTable | null>(null);
    const select = ref<typeof ElSelect | null>(null);
    const state = reactive({
      tableHeight1: 0,
      tableHeight2: 0,
      lastestPeroid: 0,
      lastestPartner: 0,
      loadingFirstChart: false,
      loadingSecondChart: false,
      dialogVisiblePartner: false,
      partnerName: "",
      loading: false,
      partners: [] as AnyObject[],
      dialogTitlePartner: "扶持企业统计",
      dialogVisiblePeroid: false,
      dialogTitlePeroid: "扶持企业统计",
      partnerRewardSourceVisible: false,
      partnerRewardSourceTitle: "扶持企业统计",
      taxPeriodStart: "",
      taxPeriodEnd: "",
      partnerId: "",
      sumRow: {} as AnyObject,
      sumRowSecond: {} as AnyObject,
      sumRowDialog: {} as AnyObject,
      sumRowSecondDialog: {} as AnyObject,
      activeName: "peroid",
      registionYearType: [{ value: "", label: "全部年份" }],
    });
    const summaryData = reactive({
      sumRow: "",
      partnerName: "",
      totalTaxAmount: "",
      prop: "",
      totalRewardAmount: "",
    });
    const formInline = reactive({
      taxPeriodStr: "",
      registionYear: "",
      registionYearSecond: "",
      taxPeriodStart: "",
      taxPeriodEnd: "",
      searchKeyWord: "",
    });
    function onSearch(obj: any) {
      select.value?.blur();
      nextTick(() => {
        TablePeroid.value?.reset();
      });
    }
    function onSearchPartner(obj: any) {
      TablePartner.value?.reset();
    }
    function remoteMethod(keyword: string) {
      state.loading = true;
      ipark_servicepark_partnerInvitationSigned({
        keyword,
      })
        .then(({ data }) => {
          state.partners = data.registedList;
        })
        .finally(() => {
          state.loading = false;
        });
    }
    function handleClick(obj: any) {
      switch (state.activeName) {
        case "peroid":
          TablePeroid.value?.reset();
          break;
        case "partner":
          TablePartner.value?.reset();
          break;
        default:
          break;
      }
    }
    function todoPeroid(row: any, param: any) {
      state.dialogVisiblePeroid = true;
      state.taxPeriodStart = param.taxPeriodStart || "";
      state.taxPeriodEnd = param.taxPeriodEnd || "";
      TableDialogPeroid.value?.reset();
    }
    function todoPartner(row: any, param: any) {
      state.dialogVisiblePartner = true;
      state.partnerName = param.partnerName || "";
      state.partnerId = param.partnerId;
      TableDialogPartner.value?.reset();
    }
    function todoDialog(row: any, param: any, name: string) {
      state.partnerRewardSourceVisible = true;
      switch (name) {
        case "peroid":
          state.partnerId = param.partnerId;
          state.partnerName = param.partnerName;
          break;
        case "partner":
          state.taxPeriodStart = param.taxPeriodStart;
          state.taxPeriodEnd = param.taxPeriodEnd;
          summaryData.partnerName = state.partnerName;
          break;
        default:
          break;
      }
      TableDialog.value?.reset();
    }
    function tableHeightNum(
      tableClass: string,
      tableHeaderName: string,
      chartHeight: number
    ) {
      if (document.getElementsByClassName(tableClass).length > 0) {
        return (
          window.innerHeight -
          document
            .getElementsByClassName(tableClass)[0]
            .getElementsByClassName(tableHeaderName)[0].scrollHeight -
          chartHeight
        );
      }
    }
    onMounted(() => {
      // 全部年份
      ipark_servicepark_getPartnerRewardYearList().then((array) => {
        array.data.map((obj: any) => {
          state.registionYearType.push({
            value: obj,
            label: obj,
          });
        });
      });
      window.addEventListener("resize", () => {
        const mychartPeroid = document.getElementById("mychartPeroid");
        const mychartPartner = document.getElementById("mychartPartner");
        const mychartPeroidDialog = document.getElementById(
          "mychartPeroidDialog"
        );
        const mychartPartnerDialog = document.getElementById(
          "mychartPartnerDialog"
        );
        if (state.dialogVisiblePartner) {
          if (!mychartPartnerDialog) return;
          echarts.init(mychartPartnerDialog).resize();
        } else if (state.dialogVisiblePeroid) {
          if (!mychartPeroidDialog) return;
          echarts.init(mychartPeroidDialog).resize();
        } else {
          switch (state.activeName) {
            case "peroid":
              state.tableHeight1 = Number(
                tableHeightNum("table1", "data-table__header", 446)
              );
              if (!mychartPeroid) return;
              echarts.init(mychartPeroid).resize();
              break;
            case "partner":
              state.tableHeight2 = Number(
                tableHeightNum("table2", "data-table__header", 446)
              );
              if (!mychartPartner) return;
              echarts.init(mychartPartner).resize();
              break;

            default:
              break;
          }
        }
      });
    });
    return {
      state,
      summaryData,
      TablePeroid,
      TablePartner,
      TableDialog,
      formInline,
      onSearch,
      onSearchPartner,
      remoteMethod,
      handleClick,
      todoPeroid,
      todoPartner,
      todoDialog,
      tableHeightNum,
    };
  },
});
