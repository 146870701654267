
import {
  defineComponent,
  reactive,
  ref,
  PropType,
  computed,
  watchEffect,
  nextTick,
  onMounted,
} from "vue";
import { ElForm } from "element-plus";
import { UUIDGenerator, AnyObject } from "@/utils";
import {
  ipark_servicepark_partnerInvitationSigned,
} from "@/api";

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<AnyObject>,
      default: () => ({}),
    },
    isNew: {
      type: Boolean,
      default: true,
    },
    naturalFormRef: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { expose }) {
    const state = reactive({
      form: props.data,
      loading: false,
      show: false,
      partners: [] as AnyObject[],
      incomeKey: UUIDGenerator(),
    });
    const lock = ref(!props.isNew);
    const formRef = ref<typeof ElForm | null>(null);
    // 身份证号校验
    const checkIdNum = (rule: any, value: any, callback: any) => {
      const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!value) {
        return callback(new Error("身份证号码不能为空"));
      } else if (!reg.test(value)) {
        return callback(new Error("身份证号码不正确"));
      } else {
        callback();
      }
    };
    function getName(val: string){
      let name = "";
      state.partners.forEach(v => {
        if (val === v.partnerId) {
          name = v.partnerName;
        }
      });
      state.form.partnerName = name;
    }
    watchEffect(() => {
      state.form = props.data;
      state.incomeKey = UUIDGenerator();
      getName(state.form.partnerId);
      console.log(state.form, "===================");
      nextTick(() => {
        formRef.value?.clearValidate();
      });
    });

    onMounted(() => {
      ipark_servicepark_partnerInvitationSigned().then(({ data }) => {
        state.partners = data.registedList;
      });
    });
    return {
      state,
      lock,
      formRef,
      onInput(val: string) {
        getName(val);
      },
      rules: computed(() => {
        return {
          idNo: [{ required: true, validator: checkIdNum, trigger: "blur" }],
          fullName: [
            {
              required: true,
              message: "请输入姓名",
              trigger: "blur",
            },
          ],
          mobile: [
            {
              required: true,
              message: "请输入手机号",
              trigger: "blur",
            },
          ],
          partnerId: [
            {
              required: true,
              message: "请选择合伙人",
              trigger: "blur",
            },
          ],
        };
      }),
    };
  },
});
