
import FileSaver from "file-saver";
import printJS from "print-js";
import {
  defineComponent,
  reactive,
  ref,
  computed,
  watch,
  PropType,
  onMounted,
  onUnmounted,
  StyleValue,
} from "vue";
import { getURLName } from "@/utils";

export default defineComponent({
  emits: ["update:rotate"],
  props: {
    zoom: {
      type: Number,
      default: 1,
    },
    canDownload: {
      type: Boolean,
      default: false,
    },
    rotate: {
      type: Number,
      default: 0,
    },
    translate: {
      type: Array as PropType<number[]>,
      default: () => [0, 0],
    },
    src: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const container = ref<HTMLElement | null>(null);
    const state = reactive({
      innerZoom: 1,
      innerTranslate: [0, 0],
      innerSrc: "",
      // imageWidth: 'auto',
      // imageHeight: 'auto',
      imageLeft: 0,
      imageTop: 0,
      showImage: true,
      loading: false,
    });
    watch(
      () => props.translate,
      (val) => {
        state.innerTranslate = val;
      },
      {
        immediate: true,
      }
    );
    watch(
      () => props.zoom,
      (val) => {
        state.innerZoom = val;
      },
      {
        immediate: true,
      }
    );
    watch(
      () => props.src,
      (val) => {
        state.innerSrc = val;
        state.showImage = true;
      },
      {
        immediate: true,
      }
    );
    let moveStart = false,
      offset = [0, 0],
      dragOffset = [0, 0],
      naturalWidth = 0,
      naturalHeight = 0,
      imageWidth = 0,
      imageHeight = 0,
      containerRatio = 0,
      ratio = 0;
    const mousemove = (event: MouseEvent) => {
      if (moveStart) {
        state.innerTranslate.splice(
          0,
          1,
          event.pageX - dragOffset[0] + offset[0]
        );
        state.innerTranslate.splice(
          1,
          1,
          event.pageY - dragOffset[1] + offset[1]
        );
      }
    };
    const mouseup = (event: MouseEvent) => {
      if (moveStart) {
        moveStart = false;
        offset = [
          event.pageX - dragOffset[0] + offset[0],
          event.pageY - dragOffset[1] + offset[1],
        ];
        dragOffset = [0, 0];
      }
    };
    onMounted(() => {
      document.addEventListener("mousemove", mousemove, false);
      document.addEventListener("mouseup", mouseup, false);
    });
    onUnmounted(() => {
      document.removeEventListener("mousemove", mousemove);
      document.removeEventListener("mouseup", mouseup);
    });
    function onLoad(event: Event) {
      if (!container.value) return;
      naturalWidth = event.target
        ? (event.target as HTMLImageElement).naturalWidth
        : 0;
      naturalHeight = event.target
        ? (event.target as HTMLImageElement).naturalHeight
        : 0;
      ratio = naturalWidth / naturalHeight;
      state.imageTop = (container.value.clientHeight - naturalHeight) / 2;
      state.imageLeft = (container.value.clientWidth - naturalWidth) / 2;
      // if (!this.innerZoom) {
      //   this.resize()
      // } else {
      //   this.originalSize()
      // }
      resize();
      state.loading = false;
    }
    function onError(event: Event) {
      // this.$alert('图片加载失败', '失败', {
      //   confirmButtonText: '确定',
      //   callback: action => {
      //   }
      // })
      state.showImage = false;
      state.loading = false;
    }
    /* 重新计算位置 */
    function rePostion() {
      if (!container.value) return;
      state.imageTop = (container.value.clientHeight - naturalHeight) / 2;
      state.imageLeft = (container.value.clientWidth - naturalWidth) / 2;
    }
    /* 居中 */
    function center() {
      state.innerTranslate = [0, 0];
      offset = [0, 0];
      rePostion();
    }
    /* 图片原来大小 */
    function originalSize() {
      // this.imageWidth = 'auto'
      // this.imageHeight = 'auto'
      state.innerZoom = 1;
      center();
    }
    /* 图片下载 */
    function download() {
      FileSaver.saveAs(props.src, getURLName(props.src));
    }
    // 图片打印
    function print() {
      printJS(props.src, "image");
    }
    /* 图片大小适应窗口 */
    function resize() {
      if (!container.value) return;
      containerRatio =
        container.value.clientWidth / container.value.clientHeight;
      imageHeight = container.value.clientHeight - 30;
      imageWidth = container.value.clientWidth - 30;
      if (containerRatio > ratio) {
        // this.imageWidth = 'auto'
        // this.imageTop = 15
        // this.imageLeft = (container.value.clientWidth - this.ratio * this.imageHeight) / 2
        if ((props.rotate / 90) % 2 === 0) {
          state.innerZoom = imageHeight / naturalHeight;
        } else {
          state.innerZoom = imageWidth / naturalHeight;
        }
      } else {
        // this.imageHeight = 'auto'
        // this.imageLeft = (container.value.clientHeight - this.ratio * this.imageWidth) / 2
        if ((props.rotate / 90) % 2 === 0) {
          state.innerZoom = imageWidth / naturalWidth;
        } else {
          state.innerZoom = imageHeight / naturalWidth;
        }
      }
      center();
    }
    function onDown(event: MouseEvent) {
      event.preventDefault();
      moveStart = true;
      dragOffset = [event.pageX, event.pageY];
    }
    function onWheel(event: WheelEvent) {
      if (!state.showImage) return;
      event.preventDefault();
      if (event.deltaY <= 0) {
        if (state.innerZoom < 10) {
          state.innerZoom += 0.1;
        }
      } else {
        if (state.innerZoom > 0.2) {
          state.innerZoom -= 0.1;
        }
      }
    }
    return {
      state,
      container,
      style: computed(() => {
        const cosVal = parseFloat(
            new Number(Math.cos((props.rotate * Math.PI) / 180)).toFixed(6)
          ),
          sinVal = parseFloat(
            new Number(Math.sin((props.rotate * Math.PI) / 180)).toFixed(6)
          );
        const css: StyleValue = {
          transform: `matrix(${state.innerZoom * cosVal}, ${
            state.innerZoom * sinVal
          }, ${state.innerZoom * (-1 * sinVal)}, ${state.innerZoom * cosVal}, ${
            state.innerTranslate[0]
          }, ${state.innerTranslate[1]})`,
          // width: typeof this.imageWidth === 'string' ? this.imageWidth : `${this.imageWidth}px`,
          // height: typeof this.imageHeight === 'string' ? this.imageHeight : `${this.imageHeight}px`,
          left:
            typeof state.imageLeft === "string"
              ? state.imageLeft
              : `${state.imageLeft}px`,
          top:
            typeof state.imageTop === "string"
              ? state.imageTop
              : `${state.imageTop}px`,
          cursor: "grabbing",
        };
        return css;
      }),
      onLoad,
      onError,
      rePostion,
      center,
      originalSize,
      download,
      print,
      resize,
      onDown,
      onWheel,
    };
  },
});
