import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bcab366e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image_content = _resolveComponent("image-content")!
  const _component_layer = _resolveComponent("layer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_layer, {
      size: _ctx.size,
      resize: "",
      maxmin: "",
      onResize: _ctx.onResize,
      title: "营业执照",
      modelValue: _ctx.show,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.show) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_image_content, {
          class: "image-view__container",
          ref: "content",
          src: _ctx.src,
          rotate: _ctx.rotate,
          "onUpdate:rotate": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rotate) = $event)),
          "can-download": _ctx.download
        }, null, 8, ["src", "rotate", "can-download"])
      ]),
      _: 1
    }, 8, ["size", "onResize", "modelValue"])
  ]))
}