
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  PropType,
  watchEffect,
  getCurrentInstance,
  nextTick,
} from "vue";

export default defineComponent({
  props: {
    size: {
      type: Array as PropType<string[]>,
      default: () => ["1300px", "650px"],
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    shade: {
      type: Array,
      default: () => [0.5, "#000", true],
    },
    zIndex: {
      type: Number,
      default: 9999,
    },
    resize: {
      type: Boolean,
      default: false,
    },
    maxmin: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "resize"],
  setup(props, { emit, expose }) {
    const container = ref<HTMLElement | null>(null);
    const self = getCurrentInstance();

    let lay: number;
    function open() {
      if (!container.value) return;
      lay = layer.open({
        title: props.title,
        content: $(container.value),
        shade: props.shade,
        maxmin: props.maxmin,
        area: props.size,
        resize: props.resize,
        btn: [],
        type: 1,
        zIndex: props.zIndex,
        resizing() {
          emit("resize");
        },
        full() {
          emit("resize");
        },
        restore() {
          emit("resize");
        },
        success() {
          emit("resize");
        },
        end() {
          if (!container.value) return;
          emit("update:modelValue", false);
        },
      });
    }
    function close() {
      if (lay) {
        // debugger
        layer.close(lay);
        nextTick(() => {
          emit("update:modelValue", false);
        });
        // container.value.remove();
      }
    }
    expose({ close });
    watchEffect(() => {
      if (props.modelValue && self && self.isMounted) {
        nextTick(() => {
          open();
          emit("resize");
        });
      }
      // if (!props.modelValue) {
      //   nextTick(() => {
      //     close();
      //   });
      // }
    });
    onMounted(() => {
      if (props.modelValue) {
        open();
      }
    });
    onUnmounted(close);
    return {
      container,
    };
  },
});
