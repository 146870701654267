
import { defineComponent, reactive, ref, nextTick } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import FileSaver from "file-saver";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
import naturalForm from "./naturalForm.vue";
import { Iparam } from "@/components/DataTable/utils";
import { AnyObject } from "@/utils";
import type { ElForm } from "element-plus";

import {
  ipark_servicepark_savePerson,
  ipark_servicepark_downloadPersonList,
  ipark_servicepark_queryPersonList,
  ipark_servicepark_deletePerson,
} from "@/api";

export default defineComponent({
  components: {
    Page,
    DataTable,
    naturalForm,
  },
  setup() {
    const dialog = reactive({
      title: "",
      disabled: false,
      show: false,
      isNew: false,
      isEdit: false,
      idNo: "",
      form: {
        idNo: "",
        fullName: "",
        mobile: "",
        partnerId: "",
        partnerName: "",
        remark: "",
      },
    });
    const state = reactive({
      keyword: "",
      loading: false,
      enterprises: [] as AnyObject[],
    });
    // onMounted(() => {});
    const formDetail = (obj: any) => {
      dialog.show = true;
      switch (obj) {
        case "add":
          dialog.disabled = false;
          dialog.isNew = true;
          dialog.isEdit = false;
          break;
        case "edit":
          dialog.disabled = true;
          dialog.isNew = false;
          dialog.isEdit = true;
          break;
        default:
          break;
      }
    };
    const table = ref<typeof DataTable | null>(null);
    const formRef = ref<typeof ElForm | null>(null);
    return {
      table,
      formRef,
      state,
      dialog,
      formDetail,
      onSubmit() {
        formRef.value?.$refs.formRef.validate().then((valid: boolean) => {
          if (valid) {
            ipark_servicepark_savePerson(dialog.form).then((obj) => {
              if (obj.code == "SUCCESS") {
                ElMessage("保存成功！");
                table.value?.reset();
              }
              dialog.show = false;
            });
          } else {
            return false;
          }
        });
      },
      onEdit() {
        dialog.disabled = false;
        dialog.isNew = true;
        dialog.isEdit = true;
      },
      onDel() {
        ElMessageBox.confirm("删除后不可恢复，是否继续?", "删除提醒", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          ipark_servicepark_deletePerson({ idNo: dialog.idNo }).then((obj) => {
            if (obj.code == "SUCCESS") {
              ElMessage("已经删除成功！");
              dialog.show = false;
              table.value?.reset();
            }
            dialog.show = false;
          });
        });
      },
      onAdd() {
        dialog.title = "新增自然人纳税人";
        dialog.form = {
          idNo: "",
          fullName: "",
          mobile: "",
          partnerId: "",
          partnerName: "",
          remark: "",
        };
        dialog.isNew = true;
        dialog.isEdit = false;
        formDetail("add");
      },
      onDetail(obj: {
        idNo: string;
        fullName: string;
        mobile: string;
        partnerId: string;
        partnerName: string;
        remark: string;
      }) {
        dialog.title = "自然人纳税人详情";
        dialog.form = obj;
        dialog.idNo = obj.idNo;
        formDetail("edit");
      },
      onSeach() {
        nextTick(() => {
          table.value?.reset();
        });
      },
      onDownload() {
        return ipark_servicepark_downloadPersonList({}).then(
          ({ data, msg }) => {
            FileSaver.saveAs(data, msg);
          }
        );
      },
      getData(param: Iparam) {
        return ipark_servicepark_queryPersonList({
          searchKeyword: state.keyword,
          page: param.index,
          per_page: param.size,
        }).then(({ data }) => {
          return {
            data: data.rows,
            total: data.totalRows,
          };
        });
      },
    };
  },
});
