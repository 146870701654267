
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  PropType,
  watch,
  watchEffect,
  DebuggerEvent,
  computed,
  StyleValue,
} from "vue";
import { ElTable } from "element-plus";
import { Refresh, Loading } from "@element-plus/icons-vue";
import { AnyObject } from "@/utils";
import { Iparam, IData } from "./utils";

type InvalidateCbRegistrator = (invalidate: () => void) => void;

export default defineComponent({
  inheritAttrs: false,
  props: {
    getData: {
      type: Function as PropType<(arg: Iparam) => Promise<IData>>,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    useHeader: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { expose, attrs }) {
    const state = reactive<{
      data: AnyObject[];
      total: number;
      loading: boolean;
      pageSize: number;
      currentPage: number;
      innerPageSize: number;
    }>({
      data: [],
      total: 10,
      loading: false,
      pageSize: props.pageSize,
      currentPage: 1,
      innerPageSize: 10,
    });
    const tableRef = ref<typeof ElTable | null>(null);
    function fetch(param: Iparam) {
      if (!props.getData) return;
      state.loading = true;
      props
        ?.getData(param)
        .then(({ data, total }) => {
          state.data = data;
          state.total = total;
        })
        .finally(() => {
          state.loading = false;
        });
    }
    function onRefresh() {
      fetch({ index: state.currentPage, size: state.innerPageSize });
    }
    function reset() {
      if (state.currentPage === 1) {
        onRefresh();
      } else {
        state.currentPage = 1;
      }
    }
    expose({
      reset,
      tableRef,
    });
    watchEffect(
      () => {
        state.innerPageSize = props.pageSize;
      },
      // {
      //   onTrack(event: DebuggerEvent) {
      //     console.log(event);
      //   },
      //   onTrigger(event: DebuggerEvent) {
      //     console.log(event);
      //   },
      // }
    );
    let timer: number;
    watch(
      () => state.innerPageSize,
      (val, oval, onInvalidate: InvalidateCbRegistrator) => {
        const index = state.currentPage;
        if (index <= Math.ceil(state.total / val)) {
          timer = window.setTimeout(() => {
            fetch({ index, size: val });
          }, 100);
        } else {
          state.currentPage = 1;
        }
        onInvalidate(() => {
          window.clearTimeout(timer);
        });
      }
    );
    watch(
      () => [state.currentPage, state.pageSize],
      () => {
        onRefresh();
      }
    );
    onMounted(() => {
      onRefresh();
    });
    return {
      state,
      Refresh,
      Loading,
      tableRef,
      pageSizes: [10, 20, 30, 40, 50, 100, 200],
      tableAttrs: computed<typeof attrs>(() => {
        return {
          ...attrs,
          class: "",
          style: "",
        };
      }),
      style: computed(() => {
        return attrs.style as StyleValue;
      }),
      onRefresh,
    };
  },
});
