
import { defineComponent, reactive, ref, nextTick, computed } from "vue";
import { ElMessage, ElMessageBox, ElForm } from "element-plus";
import { InternalRuleItem } from "async-validator";
import { mobileRegex, idCard, chRegex, AnyObject } from "@/utils";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import {
  ipark_servicepark_addPartner,
  ipark_servicepark_queryPartnerList,
  ipark_servicepark_deletePartner,
} from "@/api";

export default defineComponent({
  components: {
    Page,
    DataTable,
  },
  setup() {
    const dialog = reactive({
      title: "添加招商伙伴",
      disabled: false,
      show: false,
      loading: false,
      isDetail: false,
      form: {
        name: "",
        mobile: "",
      },
    });
    const state = reactive({
      keyword: "",
      loading: false,
      enterprises: [] as AnyObject[],
    });
    const table = ref<typeof DataTable | null>(null);
    const formRef = ref<typeof ElForm | null>(null);

    return {
      table,
      state,
      formRef,
      dialog,
      rules: computed(() => {
        return {
          name: [
            {
              required: true,
              validator: function (
                rule: InternalRuleItem,
                value: string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  if (chRegex.test(value)) {
                    callback();
                  } else {
                    callback(new Error("姓名格式不正确！"));
                  }
                } else {
                  callback(new Error("请输入招商伙伴姓名"));
                }
              },
              trigger: "blur",
            },
          ],
          no: [
            {
              required: false,
              validator: function (
                rule: InternalRuleItem,
                value: string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  if (idCard.test(value)) {
                    callback();
                  } else {
                    callback(new Error("身份证号格式不正确！"));
                  }
                } else {
                  callback(new Error("请输入招商伙伴身份证号"));
                }
              },
              trigger: "blur",
            },
          ],
          mobile: [
            {
              required: true,
              validator: function (
                rule: InternalRuleItem,
                value: string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  if (mobileRegex.test(value)) {
                    callback();
                  } else {
                    callback(new Error("手机号格式不正确！"));
                  }
                } else {
                  callback(new Error("请输入招商伙伴手机号"));
                }
              },
              trigger: "blur",
            },
          ],
        };
      }),
      onSubmit() {
        formRef.value?.validate().then((valid: boolean) => {
          if (valid) {
            ipark_servicepark_addPartner(dialog.form).then(() => {
              table.value?.reset();
              dialog.show = false;
            });
          } else {
            return false;
          }
        });
      },
      onEdit() {
        dialog.disabled = false;
      },
      onDel() {
        ElMessageBox.confirm("删除后不可恢复，是否继续?", "删除提醒", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          ipark_servicepark_deletePartner({ mobile: dialog.form.mobile }).then(
            () => {
              ElMessage.success("删除成功！");
              dialog.show = false;
              table.value?.reset();
            }
          );
        });
      },
      onAdd() {
        dialog.title = "添加招商伙伴";
        dialog.form = {
          name: "",
          mobile: "",
        };
        dialog.show = true;
        dialog.disabled = false;
        dialog.isDetail = false;
      },
      onDetail(obj: AnyObject) {
        dialog.title = "招商伙伴详情";
        dialog.form = {
          name: obj.partnerName,
          mobile: obj.partnerId,
        };
        dialog.show = true;
        dialog.disabled = true;
        dialog.isDetail = true;
      },
      onSeach() {
        nextTick(() => {
          table.value?.reset();
        });
      },
      getData(param: Iparam) {
        return ipark_servicepark_queryPartnerList({
          searchKeyword: state.keyword,
          page: param.index,
          per_page: param.size,
        }).then(({ data }) => {
          return {
            data: data.rows,
            total: data.totalRows,
          };
        });
      },
    };
  },
});
