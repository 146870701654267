import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    class: "financetax-financeFrom",
    ref: "formRef",
    model: _ctx.state.form,
    rules: _ctx.rules,
    "is-new": _ctx.isNew,
    disabled: _ctx.disabled,
    "label-width": "120px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: "身份证：",
        prop: "idNo"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.state.form.idNo,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.form.idNo) = $event)),
            placeholder: "请输入身份证",
            clearable: ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "姓名：",
        prop: "fullName"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.state.form.fullName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.form.fullName) = $event)),
            placeholder: "请输入姓名",
            clearable: ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "手机：",
        prop: "mobile"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.state.form.mobile,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.form.mobile) = $event)),
            placeholder: "请输入手机号",
            clearable: ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "招商合伙人：",
        prop: "partnerId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            placeholder: "请选择招商合伙人",
            modelValue: _ctx.state.form.partnerId,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.form.partnerId) = $event)),
            onChange: _ctx.onInput,
            filterable: "",
            clearable: "",
            style: {"display":"block"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.partners, (val) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: val.partnerId,
                  label: val.partnerName,
                  value: val.partnerId
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "备注：",
        prop: "remark"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.state.form.remark,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.form.remark) = $event)),
            type: "textarea"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules", "is-new", "disabled"]))
}