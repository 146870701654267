
import {
  defineComponent,
  reactive,
  ref,
  PropType,
  computed,
  watchEffect,
  nextTick,
} from "vue";
import { InternalRuleItem } from "async-validator";
import FileSelect from "@/components/FileSelect/Index.vue";
import { ElForm } from "element-plus";
import { UUIDGenerator } from "@/utils";
import { getNewFormData, getNewFormRule, IForm, IRule } from "./utils";

export default defineComponent({
  components: {
    FileSelect,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    rule: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { expose }) {
    const state = reactive({
      form: getNewFormData(props.data),
      incomeKey: UUIDGenerator(),
    });
    const formRef = ref<typeof ElForm | null>(null);
    watchEffect(() => {
      state.form = getNewFormData(props.data);
      state.incomeKey = UUIDGenerator();
      nextTick(() => {
        formRef.value?.clearValidate();
      });
    });
    function validate() {
      return new Promise((resolve, reject) => {
        formRef.value?.validate((valid: boolean) => {
          if (valid) {
            resolve(state.form);
          } else {
            reject();
            return false;
          }
        });
      });
    }
    expose({
      validate,
    });
    return {
      state,
      formRef,
      rules: computed(() => {
        const rule = getNewFormRule(props.rule);
        return {
          enterpriseName: [
            {
              required: rule.enterpriseName,
              message: "请输入企业名称",
              trigger: "blur",
            },
          ],
          enterpriseCode: [
            {
              required: rule.enterpriseCode,
              message: "请输入企业统一社会信用代码",
              trigger: "blur",
            },
          ],
          income: [
            {
              required: rule.income,
              message: "请输入营业收入",
              trigger: "blur",
            },
          ],
          reportType: [
            {
              required: rule.reportType,
              message: "请选择报表类型",
              trigger: "blur",
            },
          ],
          onlyFile: [
            {
              required: state.form.reportType === "1" ? rule.onlyFile : false,
              validator: function (
                rule: InternalRuleItem,
                value: File | string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  if (typeof value === "string") return callback();
                  const result = /\.[\w]*$/.exec(value.name);
                  if (
                    result &&
                    ".pdf".toLowerCase().indexOf(result[0].toLowerCase()) !== -1
                  ) {
                    callback();
                  } else {
                    callback(new Error("请选择PDF格式文件"));
                  }
                } else {
                  callback(new Error("请选择文件"));
                }
              },
              trigger: "change",
            },
          ],
          asstesLiabilitiesFile: [
            {
              required:
                state.form.reportType === "2"
                  ? rule.asstesLiabilitiesFile
                  : false,
              validator: function (
                rule: InternalRuleItem,
                value: File | string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  if (typeof value === "string") return callback();
                  const result = /\.[\w]*$/.exec(value.name);
                  if (
                    result &&
                    ".pdf".toLowerCase().indexOf(result[0].toLowerCase()) !== -1
                  ) {
                    callback();
                  } else {
                    callback(new Error("请选择PDF格式文件"));
                  }
                } else {
                  callback(new Error("请选择资产负债表"));
                }
              },
              trigger: "change",
            },
          ],
          profitLossFile: [
            {
              required:
                state.form.reportType === "2" ? rule.profitLossFile : false,
              validator: function (
                rule: InternalRuleItem,
                value: File | string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  if (typeof value === "string") return callback();
                  const result = /\.[\w]*$/.exec(value.name);
                  if (
                    result &&
                    ".pdf".toLowerCase().indexOf(result[0].toLowerCase()) !== -1
                  ) {
                    callback();
                  } else {
                    callback(new Error("请选择PDF格式文件"));
                  }
                } else {
                  callback(new Error("请选择损益表"));
                }
              },
              trigger: "change",
            },
          ],
          cashFlowFile: [
            {
              required:
                state.form.reportType === "2" ? rule.cashFlowFile : false,
              validator: function (
                rule: InternalRuleItem,
                value: File | string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  if (typeof value === "string") return callback();
                  const result = /\.[\w]*$/.exec(value.name);
                  if (
                    result &&
                    ".pdf".toLowerCase().indexOf(result[0].toLowerCase()) !== -1
                  ) {
                    callback();
                  } else {
                    callback(new Error("请选择PDF格式文件"));
                  }
                } else {
                  callback(new Error("请选择现金流量表"));
                }
              },
              trigger: "change",
            },
          ],
        };
      }),
    };
  },
});
