
import { defineComponent, reactive, ref } from "vue";
import { Delete } from "@element-plus/icons-vue";
import { ElMessageBox } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import { AnyObject } from "@/utils";
import {
  ipark_servicepark_queryCorpTaxRewardDetailByBatch,
  ipark_servicepark_queryIndividualTaxRewardDetailByBatch,
  ipark_servicepark_delTaxRewardByBatch,
  servicepark_enterpriseSearch,
} from "@/api";
import { fundMap } from "./utils";

export default defineComponent({
  components: {
    Page,
    DataTable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      dataFilter: {
        keyword: route.query?.keyword as string,
      },
      activeName: "企业",
      loading: false,
      enterprises: [] as AnyObject[],
      showColumnsEnterprise: [] as string[],
      showColumnsIndividual: [] as string[],
    });
    const tableEnterprise = ref<typeof DataTable | null>(null);
    const tableIndividual = ref<typeof DataTable | null>(null);
    function remoteMethod(keyword: string) {
      state.loading = true;
      servicepark_enterpriseSearch({
        keyword,
      })
        .then(({ data }) => {
          state.enterprises = data;
        })
        .finally(() => {
          state.loading = false;
        });
    }
    remoteMethod(route.query?.keyword as string);
    return {
      state,
      route,
      fundMap,
      tableEnterprise,
      tableIndividual,
      Delete,
      onSeach() {
        tableEnterprise.value?.reset();
        tableIndividual.value?.reset();
      },
      onDownload() {
        console.log("xiazai");
      },
      remoteMethod,
      onDel() {
        ElMessageBox.confirm("删除后不可恢复，是否继续?", "删除提醒", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          state.loading = true;
          ipark_servicepark_delTaxRewardByBatch({
            taxPeriodStart: route.query?.taxPeriodStart,
            taxPeriodEnd: route.query?.taxPeriodEnd,
          })
            .then(() => {
              router.back();
            })
            .finally(() => {
              state.loading = false;
            });
        });
      },
      getDataEnterprise(param: Iparam) {
        return ipark_servicepark_queryCorpTaxRewardDetailByBatch({
          keyword: state.dataFilter.keyword,
          taxPeriodStart: route.query?.taxPeriodStart,
          taxPeriodEnd: route.query?.taxPeriodEnd,
          page: param.index,
          per_page: param.size,
        }).then(({ data }) => {
          state.showColumnsEnterprise = data.showColumns;
          return {
            data: data.rows,
            total: data.totalRows,
          };
        });
      },
      getDataIndividual(param: Iparam) {
        return ipark_servicepark_queryIndividualTaxRewardDetailByBatch({
          keyword: state.dataFilter.keyword,
          taxPeriodStart: route.query?.taxPeriodStart,
          taxPeriodEnd: route.query?.taxPeriodEnd,
          page: param.index,
          per_page: param.size,
        }).then(({ data }) => {
          state.showColumnsIndividual = data.showColumns;
          return {
            data: data.rows,
            total: data.totalRows,
          };
        });
      },
    };
  },
});
