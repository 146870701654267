
import { defineComponent, reactive, ref } from "vue";
import { Delete } from "@element-plus/icons-vue";
import { ElMessageBox } from "element-plus";
import FileSaver from "file-saver";
import { useRoute, useRouter } from "vue-router";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import { AnyObject } from "@/utils";
import {
  ipark_servicepark_queryPartnerRewardDetail,
  ipark_servicepark_deletePartnerRewardDetail,
  servicepark_enterpriseSearch,
  ipark_servicepark_queryPartnerRewardDetail_download,
} from "@/api";

export default defineComponent({
  components: {
    Page,
    DataTable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      dataFilter: {
        keyword: route.query?.keyword as string,
      },
      loading: false,
      enterprises: [] as AnyObject[],
    });
    const tableRef = ref<typeof DataTable | null>(null);
    function remoteMethod(keyword: string) {
      state.loading = true;
      servicepark_enterpriseSearch({
        keyword,
      })
        .then(({ data }) => {
          state.enterprises = data;
        })
        .finally(() => {
          state.loading = false;
        });
    }
    remoteMethod(route.query?.keyword as string);
    let sumRow: AnyObject = {};
    return {
      state,
      route,
      tableRef,
      Delete,
      onSeach() {
        tableRef.value?.reset();
      },
      onDownload() {
        return ipark_servicepark_queryPartnerRewardDetail_download({
          taxPeriodStart: route.query?.taxPeriodStart,
          taxPeriodEnd: route.query?.taxPeriodEnd,
        }).then(({ data, msg }) => {
          FileSaver.saveAs(data, msg);
        });
      },
      remoteMethod,
      getSummaries() {
        const sum: string[] = [
          sumRow.title,
          sumRow.taxAmount,
          sumRow.rewardAmount,
        ];
        return sum;
      },
      onDel() {
        ElMessageBox.confirm("删除后不可恢复，是否继续?", "删除提醒", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          state.loading = true;
          ipark_servicepark_deletePartnerRewardDetail({
            taxPeriodStart: route.query?.taxPeriodStart,
            taxPeriodEnd: route.query?.taxPeriodEnd,
          })
            .then(() => {
              router.back();
            })
            .finally(() => {
              state.loading = false;
            });
        });
      },
      getData(param: Iparam) {
        return ipark_servicepark_queryPartnerRewardDetail({
          keyword: state.dataFilter.keyword,
          taxPeriodStart: route.query?.taxPeriodStart,
          taxPeriodEnd: route.query?.taxPeriodEnd,
          page: param.index,
          per_page: param.size,
        }).then(({ data }) => {
          sumRow = data.sumRow;
          return {
            data: data.rows,
            total: data.totalRows,
          };
        });
      },
    };
  },
});
