import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13295489"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "industrialPark-brochure-detail-content" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_layout = _resolveComponent("layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_layout, null, {
      left: _withCtx(() => [
        _createElementVNode("div", {
          class: "industrialPark-brochure-detail-content__preview",
          innerHTML: _ctx.modelValue
        }, null, 8, _hoisted_2)
      ]),
      right: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
          id: "uecontainer",
          name: "content",
          type: "text/plain"
        }))
      ]),
      _: 1
    })
  ]))
}