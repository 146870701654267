
import { defineComponent, onMounted, reactive, ref, nextTick } from "vue";
import * as echarts from "echarts";
import companyDetailThirdTab from "./../companyDetail.vue";
import DataTable from "@/components/DataTable/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import bg from "@/assets/image/bg.jpg";
import {
  ipark_servicepark_enterpriseRewardList,
  ipark_servicepark_getEnterpriseRewardYearList,
  ipark_servicepark_enterpriseRewardBatch,
  servicepark_enterpriseSearch,
} from "@/api/index";
import { ElSelect } from "element-plus";
import { AnyObject, toNum } from "@/utils";
import Page from "@/components/Page/Index.vue";
export default defineComponent({
  emits: ["keyup"],
  components: {
    companyDetailThirdTab,
    DataTable,
    Page,
  },
  methods: {
    // option(canvasSeries: {
    //   radius: string | string[] | number[];
    //   roseType?: string;
    // }) {
    //   return {
    //     title: {
    //       text: "Referer of a Website",
    //       subtext: "Fake Data",
    //       left: "center",
    //     },
    //     tooltip: {
    //       trigger: "item",
    //     },
    //     legend: {
    //       orient: "vertical",
    //       left: "left",
    //     },
    //     series: [
    //       {
    //         name: "Access From",
    //         type: "pie",
    //         radius: canvasSeries.radius,
    //         roseType: canvasSeries.roseType,
    //         avoidLabelOverlap: false,
    //         data: [
    //           { value: 1048, name: "Search Engine" },
    //           { value: 735, name: "Direct" },
    //           { value: 580, name: "Email" },
    //           { value: 484, name: "Union Ads" },
    //           { value: 300, name: "Video Ads" },
    //         ],
    //         emphasis: {
    //           itemStyle: {
    //             shadowBlur: 10,
    //             shadowOffsetX: 0,
    //             shadowColor: "rgba(0, 0, 0, 0.5)",
    //           },
    //         },
    //       },
    //     ],
    //   };
    // },
    // 扶持企业按批次统计图表
    canvasFirst(mycharData: any) {
      const elChart1 = document.getElementById("mychart1");
      if (!elChart1) return;
      echarts
        .init(elChart1)
        .setOption(this.FirstOption(mycharData, this.state));
    },
    // 扶持企业名单图表
    canvasDialog(mycharData: any) {
      const dialogChart = document.getElementById("dialogChart");
      if (!dialogChart) return;
      echarts
        .init(dialogChart)
        .setOption(this.DialogOption(mycharData, this.formInline));
    },
    FirstOption(data: any[], params: any) {
      data = data.reverse();
      console.log(data);
      return {
        title: {
          text: "扶持企业按批次统计（近十二期数据）",
          left: "center",
          textStyle: {
            fontSize: 14,
          },
          padding: [20, 20],
        },
        grid: {
          containLabel: true,
          top: 80,
          left: 20,
          right: 20,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        xAxis: [
          {
            type: "category",
            data: data.map((val) => val.taxPeriodStr),
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
          },
          {
            type: "value",
            name: "单位：万元",
            offset: -20,
          },
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            barWidth: "60%",
            data: data.map((val) => toNum(val.rewardAmount)),
          },
        ],
      };
    },
    DialogOption(data: any[], params: any) {
      data = data.reverse();
      const tmp = data.map((val) => {
        return parseFloat(val.rewardAmount);
      });
      const total = tmp.reduce((r, e) => r + e, 0);
      return {
        title: {
          text: "扶持企业名单",
          subtext: params.taxPeriodStr,
          left: "center",
          textStyle: {
            fontSize: 14,
          },
          padding: [20, 20],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        grid: {
          containLabel: true,
          top: 80,
          left: 0,
          right: 20,
          bottom: 20,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: [
          {
            type: "category",
            data: data.map((val) =>
              val.taxpayerName.length > 10
                ? val.taxpayerName.substr(0, 8) + "..."
                : val.taxpayerName
            ),
            nameTextStyle: {
              fontSize: 10,
            },
          },
          {
            type: "value",
            name: "单位：万元",
            offset: -20,
          },
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            data: tmp.map((val) => {
              return {
                value: toNum(val),
                label: {
                  show: true,
                  position: val > total / 4 ? "insideRight" : "right",
                },
              };
            }),
          },
        ],
      };
    },
    getData(param: Iparam) {
      this.state.lastest = param.index;
      return ipark_servicepark_enterpriseRewardList({
        year: this.formInline.registionYear || "",
        keyword: this.formInline.searchKeyWord || "",
        page: param.index,
        per_page: param.size,
      }).then((obj) => {
        this.state.sumRow = obj.data.sumRow || {};
        this.canvasFirst(obj.data.chat);
        this.state.tableHeight = Number(
          this.tableHeightNum("table", "data-table__header", 484)
        );
        return {
          data: obj.data.rows,
          total: obj.data.totalRows,
        };
      });
    },
    getDataDialog(param: Iparam) {
      if (this.state.dialogVisible) {
        return ipark_servicepark_enterpriseRewardBatch({
          keyword: this.formInline.searchKeyWord,
          taxPeriodStart: this.formInline.taxPeriodStart || "",
          taxPeriodEnd: this.formInline.taxPeriodEnd || "",
          page: param.index,
          per_page: param.size,
        }).then((obj) => {
          this.state.sumRow = obj.data.sumRow || {};
          this.canvasDialog(obj.data.chat);
          return {
            data: obj.data.rows,
            total: obj.data.totalRows,
          };
        });
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    },
    getSummaries(param: { columns: any; data: any }) {
      const sums = ["合计", ""];
      param.columns.map((obj: any, index: number) => {
        if (index > 1) {
          sums.push(this.state.sumRow[obj.property]);
        }
      });
      return sums;
    },
  },
  // name: "Home",
  setup() {
    const autoInput0 = ref<typeof DataTable | null>(null);
    const Table = ref<typeof DataTable | null>(null);
    const dialogTable = ref<typeof DataTable | null>(null);
    const select = ref<typeof ElSelect | null>(null);
    // const businesslicenseInner = ref<
    //   typeof ModifyBusinesslicenseInnerContent | null
    // >(null);
    const dialogThirdCompany = reactive({
      fourthShow: true,
      dialogTableVisible: false,
      title: "",
      information: {
        taxpayerName: "",
        taxpayerId: "",
      },
    });
    const state = reactive({
      lastest: 0,
      loadingTwoChart: true,
      loadingDialogChart: true,
      loadingThirdChart: true,
      loadingFourthChart: true,
      sumRow: {} as AnyObject,
      searchKeyWord: "",
      loading: false,
      enterprises: [] as AnyObject[],
      // activeName: "first",
      dialogTitle: "",
      dialogVisible: false,
      title: "",
      registionYearType: [{ value: "", label: "全部" }],
      tableHeight: 0,
      // dialogTabVisible: false,
      // dialogTabTitle: "",
      // dialogLastVisible: false,
      // dialogLastTitle: "",
    });
    const formInline = reactive({
      taxPeriodStr: "",
      registionYear: "",
      taxPeriodStart: "",
      taxPeriodEnd: "",
      searchKeyWord: "",
    });
    function onSearch() {
      select.value?.blur();
      nextTick(() => {
        Table.value?.reset();
      });
    }
    function remoteMethod(keyword: string) {
      state.loading = true;
      servicepark_enterpriseSearch({
        keyword,
      })
        .then(({ data }) => {
          state.enterprises = data;
        })
        .finally(() => {
          state.loading = false;
        });
    }
    function todo(index: any, row: any) {
      state.dialogTitle = "扶持企业统计 / " + row.taxPeriodStr;
      formInline.taxPeriodStart = row.taxPeriodStart;
      formInline.taxPeriodEnd = row.taxPeriodEnd;
      formInline.taxPeriodStr = row.taxPeriodStr;
      nextTick(() => {
        dialogTable.value?.reset();
        state.dialogVisible = true;
      });
    }
    // function dialogTabTodo(row: any, index: { date: string }) {
    //   state.dialogLastVisible = true;
    //   state.dialogLastTitle = state.title + " / " + index.date + "缴税详情";
    // }
    function dialogTodo(row: any, index: any) {
      dialogThirdCompany.dialogTableVisible = true;
      dialogThirdCompany.title = index.taxpayerName;
      return (dialogThirdCompany.information = index);
      // state.dialogTabVisible = true;
      // state.dialogTabTitle = index.date;
      // setTimeout(() => {
      //   const dialogChart = document.getElementById("dialogTabChart");
      //   if (!dialogChart) return;
      //   ipark_servicepark_enterpriseRewardList().then((obj) => {
      //     echarts.init(dialogChart).setOption(lineOption(obj.data.chat));
      //   });
      // }, 100);
    }
    function tableHeightNum(
      tableClass: string,
      tableHeaderName: string,
      chartHeight: number
    ) {
      if (document.getElementsByClassName(tableClass).length > 0) {
        return (
          window.innerHeight -
          document
            .getElementsByClassName(tableClass)[0]
            .getElementsByClassName(tableHeaderName)[0].scrollHeight -
          chartHeight
        );
      }
    }
    onMounted(() => {
      // 全部年份
      ipark_servicepark_getEnterpriseRewardYearList().then((array) => {
        array.data.map((obj: any) => {
          state.registionYearType.push({
            value: obj,
            label: obj,
          });
        });
      });
      window.addEventListener("resize", () => {
        state.tableHeight = Number(
          tableHeightNum("table", "data-table__header", 484)
        );
        if (state.dialogVisible) {
          const dialogChart = document.getElementById("dialogChart");
          if (!dialogChart) return;
          echarts.init(dialogChart).resize();
        } else {
          const elChart1 = document.getElementById("mychart1");
          if (!elChart1) return;
          echarts.init(elChart1).resize();
        }
      });
    });
    return {
      bg,
      state,
      dialogThirdCompany,
      formInline,
      autoInput0,
      Table,
      onSearch,
      remoteMethod,
      // lineOption,
      todo,
      dialogTodo,
      tableHeightNum,
    };
  },
});
