
import {
  defineComponent,
  reactive,
  ref,
  nextTick,
  computed,
  watchEffect,
} from "vue";
import { ElMessage, ElMessageBox, ElForm } from "element-plus";
import { InternalRuleItem } from "async-validator";
import {
  mobileRegex,
  emailRegex,
  chRegex,
  qqRegex,
  AnyObject,
  clone,
} from "@/utils";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import {
  ipark_servicepark_saveServiceParkCustomerService,
  ipark_servicepark_serviceParkCustomerServiceList,
  ipark_servicepark_deleteServiceParkCustomerService,
} from "@/api";

export default defineComponent({
  components: {
    Page,
    DataTable,
  },
  setup() {
    const dialog = reactive({
      title: "添加招商伙伴",
      disabled: false,
      show: false,
      loading: false,
      isDetail: false,
      form: {
        title: "",
        liaison: "",
        QQ: "",
      } as AnyObject,
    });
    const state = reactive({
      keyword: "",
      loading: false,
      enterprises: [] as AnyObject[],
    });
    const prekeyword = "title;liaison;";
    let dyField = ref(
      (() => {
        const tmp = [];
        for (let key in dialog.form) {
          if (prekeyword.indexOf(key + ";") == -1) {
            tmp.push({
              key,
              value: dialog.form[key],
            });
          }
        }
        return tmp;
      })()
    );
    watchEffect(() => {
      if (dialog.show && dialog.isDetail) {
        dialog.disabled = true;
      }
    });
    const table = ref<typeof DataTable | null>(null);
    const formRef = ref<typeof ElForm | null>(null);
    return {
      table,
      state,
      dialog,
      dyField,
      formRef,
      options: [
        {
          value: "电话",
          label: "电话",
        },
        {
          value: "手机",
          label: "手机",
        },
        {
          value: "邮箱",
          label: "邮箱",
        },
        {
          value: "QQ",
          label: "QQ",
        },
        {
          value: "微信",
          label: "微信",
        },
      ],
      rules: computed(() => {
        return {
          title: [
            {
              required: true,
              message: "请输入主题",
              trigger: "blur",
            },
          ],
          liaison: [
            {
              required: true,
              validator: function (
                rule: InternalRuleItem,
                value: string,
                callback: (arg?: Error) => void
              ) {
                if (!rule.required) {
                  return callback();
                }
                if (value) {
                  if (chRegex.test(value)) {
                    callback();
                  } else {
                    callback(new Error("姓名格式不正确！"));
                  }
                } else {
                  callback(new Error("请输入法人姓名"));
                }
              },
              trigger: "blur",
            },
          ],
        };
      }),
      displayliaison(obj: AnyObject) {
        const tmp = [];
        for (let key in obj) {
          switch (key) {
            case "title":
              continue;
            case "liaison":
              tmp.unshift("联系人：" + obj[key]);
              break;
            default:
              tmp.push(key + "：" + obj[key]);
          }
        }
        return tmp.join("，");
      },
      optrule(key: string) {
        const rule = {
          手机: {
            required: true,
            validator: function (
              rule: InternalRuleItem,
              value: string,
              callback: (arg?: Error) => void
            ) {
              if (!rule.required) {
                return callback();
              }
              if (value) {
                if (mobileRegex.test(value)) {
                  callback();
                } else {
                  callback(new Error("手机号格式不正确！"));
                }
              } else {
                callback(new Error("请输入法人手机号"));
              }
            },
            trigger: "blur",
          },
          邮箱: {
            required: true,
            validator: function (
              rule: InternalRuleItem,
              value: string,
              callback: (arg?: Error) => void
            ) {
              if (!rule.required) {
                return callback();
              }
              if (value) {
                if (emailRegex.test(value)) {
                  callback();
                } else {
                  callback(new Error("邮箱格式不正确！"));
                }
              } else {
                callback(new Error("请输入邮箱"));
              }
            },
            trigger: "blur",
          },
          QQ: {
            required: true,
            validator: function (
              rule: InternalRuleItem,
              value: string,
              callback: (arg?: Error) => void
            ) {
              if (!rule.required) {
                return callback();
              }
              if (value) {
                if (qqRegex.test(value)) {
                  callback();
                } else {
                  callback(new Error("QQ号格式不正确！"));
                }
              } else {
                callback(new Error("请输入QQ号"));
              }
            },
            trigger: "blur",
          },
        };
        const keys = Object.keys(rule);
        return keys.indexOf(key) === -1
          ? {
              required: true,
              message: "请输入联系方式",
              trigger: "blur",
            }
          : rule[key as "手机" | "邮箱" | "QQ"];
      },
      onSelect(field: string, item: AnyObject) {
        if (field && prekeyword.indexOf(field + ";") !== -1) {
          ElMessage.error(field + "，为预留字段~");
          return;
        }
        item.key = field;
      },
      onDelField(key: string, idx: number) {
        // delete dialog.form[key];
        dyField.value.splice(idx, 1);
      },
      onAddField() {
        const len = Object.keys(dialog.form).filter(
          (val) => val.indexOf("QQ") !== -1
        ).length;
        const key = "QQ" + len;
        dialog.form[key] = "";
        console.log(dialog.form);
        dyField.value.push({
          key,
          value: "",
        });
      },
      onSubmit() {
        formRef.value?.validate().then((valid: boolean) => {
          if (valid) {
            const tmp: AnyObject = {};
            for (let key in dialog.form) {
              if (dialog.form[key]) {
                tmp[key] = dialog.form[key];
              }
            }
            ipark_servicepark_saveServiceParkCustomerService({
              jsonStr: JSON.stringify(tmp),
            }).then(() => {
              table.value?.reset();
              dialog.show = false;
            });
          } else {
            return false;
          }
        });
      },
      onCancel() {
        if (!dialog.disabled && dialog.isDetail) {
          dialog.disabled = true;
        } else {
          dialog.show = false;
        }
      },
      onEdit() {
        dialog.disabled = false;
      },
      onDel() {
        ElMessageBox.confirm("删除后不可恢复，是否继续?", "删除提醒", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          ipark_servicepark_deleteServiceParkCustomerService({
            title: dialog.form.title,
          }).then(() => {
            table.value?.reset();
            dialog.show = false;
          });
        });
      },
      onAdd() {
        dialog.title = "添加招商伙伴";
        dialog.form = {
          title: "",
          liaison: "",
          QQ: "",
        };
        dyField.value = (() => {
          const tmp = [];
          for (let key in dialog.form) {
            if (prekeyword.indexOf(key + ";") == -1) {
              tmp.push({
                key,
                value: dialog.form[key],
              });
            }
          }
          return tmp;
        })();
        dialog.isDetail = false;
        dialog.disabled = false;
        nextTick(() => {
          dialog.show = true;
        });
      },
      onDetail(obj: AnyObject) {
        dialog.title = "招商伙伴详情";
        dialog.form = {
          ...obj,
        };
        dyField.value = (() => {
          const tmp = [];
          for (let key in obj) {
            if (prekeyword.indexOf(key + ";") == -1) {
              tmp.push({
                key,
                value: obj[key],
              });
            }
          }
          return tmp;
        })();
        dialog.disabled = true;
        dialog.isDetail = true;
        nextTick(() => {
          dialog.show = true;
        });
      },
      onSeach() {
        nextTick(() => {
          table.value?.reset();
        });
      },
      getData(param: Iparam) {
        return ipark_servicepark_serviceParkCustomerServiceList().then(
          ({ data }) => {
            return {
              data: data.rows,
              total: data.totalRows,
            };
          }
        );
      },
    };
  },
});
