
import { defineComponent, reactive, ref, onMounted, nextTick } from "vue";
import * as echarts from "echarts";
import ModifyBusinesslicense from "@/components/ModifyBusinessLicense/Index.vue";
// import ModifyBusinesslicenseInnerContent from "@/components/ModifyBusinessLicense/InnerContent.vue";
import { getNewFormData } from "@/components/ModifyBusinessLicense/utils";
import DataTable from "@/components/DataTable/Index.vue";
import ImageView from "@/components/ImageView/Index.vue";
import companyDetailThirdTab from "./../companyDetail.vue";
import { AnyObject, toNum, isAbsHttp } from "@/utils";
import Page from "@/components/Page/Index.vue";
import { ElSelect } from "element-plus";
import { Iparam } from "@/components/DataTable/utils";
import FileSaver from "file-saver";
import { rsHost } from "@/api/utils";
import {
  servicepark_enterpriseRegistInfo,
  ipark_servicepark_growthtrend,
  servicepark_enterpriseList,
  ipark_servicepark_enterprisetypeList,
  ipark_dic_industrycategoryList,
  ipark_servicepark_notaxEnterprise,
  servicepark_enterpriseSearch,
  ipark_servicepark_enterpriseList_download,
} from "@/api/index";
export default defineComponent({
  emits: ["keyup"],
  components: {
    ImageView,
    ModifyBusinesslicense,
    // ModifyBusinesslicenseInnerContent,
    DataTable,
    companyDetailThirdTab,
    Page,
  },
  methods: {
    todo(row: AnyObject) {
      this.dialogCompanyImg.dialogVisible = true;
      this.dialogCompanyImg.title = row.taxpayerName;
      // 获取工商登记信息
      servicepark_enterpriseRegistInfo({
        taxpayerId: row.taxpayerId,
      }).then((row) => {
        this.dialogCompanyImg.form = getNewFormData({
          enterprisefile: isAbsHttp(row.data.licenseFilestore)
            ? row.data.licenseFilestore
            : rsHost + row.data.licenseFilestore,
          enterprisename: row.data.taxpayerName,
          enterprisecode: row.data.taxpayerId,
          enterprisetype: row.data.enterpriseTypeCode,
          enterprisebusiness: row.data.industryCode,
          enterprisescope: row.data.activitiesScope,
          enterpriseaddress: row.data.registAddress,
          enterpriseregistrationDate: row.data.registionDate,
          establishmentDate: row.data.establishmentDate,
          legalpersonName: row.data.legalRepresentativeName,
          legalpersonNo: row.data.legalRepresentativeIdNo,
          legalpersonMobile: row.data.legalRepresentativeMobile,
          contactsName: row.data.enterpriseLiaisonName,
          contactsMobile: row.data.enterpriseLiaisonMobile,
          attractpartner: row.data.partnerId,
          parkaccesser: row.data.serviceParkLiaisonMobile,
        });
      });
    },
    detail(row: any, index: any) {
      this.dialogThirdCompany.dialogTableVisible = true;
      this.dialogThirdCompany.title = index.taxpayerName;
      return (this.dialogThirdCompany.information = index);
    },
    card(row: any, index: { date: string; licenseFilestore: string }) {
      this.state.show = true;
      this.state.bg = index.licenseFilestore;
    },
  },
  setup() {
    const autoInput0 = ref<typeof DataTable | null>(null);
    const firstTable = ref<typeof DataTable | null>(null);
    const secondTable = ref<typeof DataTable | null>(null);
    const thirdTable = ref<typeof DataTable | null>(null);
    const fourthTable = ref<typeof DataTable | null>(null);
    const select = ref<typeof ElSelect | null>(null);
    // const businesslicenseInner = ref<
    //   typeof ModifyBusinesslicenseInnerContent | null
    // >(null);
    const dialogCompanyImg = reactive({
      dialogVisible: false,
      title: "",
      form: getNewFormData(),
    });
    const dialogThirdCompany = reactive({
      dialogTableVisible: false,
      title: "",
      information: {
        taxpayerName: "",
        taxpayerId: "",
      },
    });
    const state = reactive({
      echart2: {},
      echart3: {},
      echart4: {},
      tableThird: [],
      tableFourth: [],
      condition: "6M-",
      currentPage: 1,
      pageSize: 10,
      bg: "",
      show: false,
      tableHeight1: 0,
      tableHeight2: 0,
      tableHeight3: 0,
      tableHeight4: 0,
      enterpriseType: [{ value: "", label: "全部" }],
      industryType: [] as AnyObject[],
      loading: false,
      enterprises: [] as AnyObject[],
      searchKeyWord: "",
      activeName: "first",
      dialogTitle: "",
      loadingSecondChart: true,
      loadingThirdChart: true,
      loadingFourthChart: true,
    });
    const formInline = reactive({
      enterpriseTypeCode: "",
      operateStatus: "",
      value: "",
      formDate: ref([]),
    });

    const onSearch = (obj: any) => {
      select.value?.blur();
      nextTick(() => {
        firstTable.value?.reset();
      });
    };
    const getEchart = (dataEchart: any, elChartName: any) => {
      const chat = dataEchart;
      const chartData = [];
      let xAxis: number[] = [],
        legend: string[] = [];
      for (let key in chat) {
        if (key === "yearOnYearMapList") continue;
        chat[key].forEach((val: { yearStr: string }) => {
          const yearStr = parseInt(val.yearStr);
          if (xAxis.indexOf(yearStr) === -1) {
            xAxis.push(yearStr);
          }
        });
      }
      xAxis.sort((p, n) => p - n);
      for (let key in chat) {
        if (key === "growthTrendList") continue;
        chartData.push({
          name: (chat[key][0] || {}).enterpriseTypeName,
          type: "line",
          // stack: "总量",
          data: xAxis.map((val) => {
            const obj =
              chat[key].filter(
                (v: { yearStr: string }) => val === parseInt(v.yearStr)
              )[0] || {};
            return obj.retainedCount;
          }),
        });
        legend.push((chat[key][0] || {}).enterpriseTypeName);
      }
      chat.growthTrendList.sort(
        (p: { yearStr: string }, n: { yearStr: string }) =>
          parseInt(p.yearStr) - parseInt(n.yearStr)
      );
      chartData.push({
        name: (chat.growthTrendList[0] || {}).enterpriseTypeName,
        type: "bar",
        barWidth: "60%",
        color: "#BCD2EE",
        data: chat.growthTrendList.map((val: { retainedCount: any }) => {
          return val.retainedCount;
        }),
      });
      legend.push((chat.growthTrendList[0] || {}).enterpriseTypeName);
      const option = {
        grid: {
          containLabel: true,
          top: 20,
          left: 30,
          right: 30,
          bottom: 50,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: legend,
          bottom: 10,
          textStyle: {
            fontSize: 12,
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xAxis,
        },
        yAxis: {
          type: "value",
        },
        series: chartData,
      };
      echarts.init(elChartName).setOption(option);
    };
    const optionPIE = (data: any, type: string) => {
      let text = "";
      let dataNew = {};
      switch (type) {
        case "echart3":
          text = "入园企业分企业类型统计";
          dataNew = data.map((val: any) => {
            return {
              name: val.enterpriseTypeName + val.count + "户，\n" + val.prop,
              value: toNum(val.count),
            };
          });
          break;

        case "echart4":
          text = "入园企业无税户统计";
          dataNew = [
            {
              name:
                "无税户：" +
                data.noTaxAccountCount +
                "户，\n占比：" +
                data.noTaxAccountProp,
              value: data.noTaxAccountCount,
            },
            {
              name:
                "产税户：" +
                data.taxAccountCount +
                "户，\n占比：" +
                data.taxAccountCountProp,
              value: data.taxAccountCount,
            },
          ];
          break;

        default:
          break;
      }
      return {
        title: {
          text: text,
          left: "left",
          textStyle: {
            fontSize: 16,
          },
          padding: [20, 20],
        },
        tooltip: {
          trigger: "item",
          formatter: '{b0}'
        },
        grid: {
          containLabel: true,
          top: "10%",
          left: 20,
          right: 20,
          bottom: 20,
        },
        series: {
          type: "pie",
          radius: "50%",
          data: dataNew,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      };
    };
    const remoteMethod = (keyword: string) => {
      state.loading = true;
      servicepark_enterpriseSearch({
        keyword,
      })
        .then(({ data }) => {
          state.enterprises = data;
        })
        .finally(() => {
          state.loading = false;
        });
    };
    // 弹框切换
    const handleClick = () => {
      switch (state.activeName) {
        case "first":
          firstTable.value?.reset();
          break;
        case "second":
          state.loadingSecondChart = false;
          secondTable.value?.reset();
          break;
        case "third":
          state.loadingThirdChart = false;
          thirdTable.value?.reset();
          break;
        case "fourth":
          state.loadingFourthChart = false;
          fourthTable.value?.reset();
          break;
        default:
          break;
      }
    };
    const getData1 = (param: Iparam) => {
      if (state.activeName == "first") {
        return servicepark_enterpriseList({
          enterpriseTypeCode: formInline.enterpriseTypeCode
            ? formInline.enterpriseTypeCode
            : "",
          industryCode: formInline.value ? formInline.value : "",
          operateStatus: formInline.operateStatus
            ? formInline.operateStatus
            : "",
          beginDate:
            formInline.formDate.length > 0 ? formInline.formDate[0] : "",
          endDate: formInline.formDate.length > 0 ? formInline.formDate[1] : "",
          keyword: state.searchKeyWord ? state.searchKeyWord : "",
          page: param.index,
          per_page: param.size,
        }).then((obj) => {
          state.tableHeight1 = Number(
            tableHeightNum("table1", "data-table__header", 315)
          );
          return {
            data: obj.data.rows,
            total: obj.data.totalRows,
          };
        });
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    };
    const getData2 = () => {
      const elChart2 = document.getElementById("mychart2");
      if (state.activeName == "second") {
        return ipark_servicepark_growthtrend().then((obj) => {
          state.echart2 = obj.data.chat;
          state.tableHeight2 = Number(
            tableHeightNum("table2", "el-table__header", 400)
          );
          if (!elChart2) return;
          echarts.init(elChart2).resize();
          getEchart(state.echart2, elChart2);
          return {
            data: obj.data.rows,
            total: obj.data.totalRows,
          };
        });
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    };
    function tableHeightNum(
      tableClass: string,
      tableHeaderName: string,
      chartHeight: number
    ) {
      if (document.getElementsByClassName(tableClass).length > 0) {
        return (
          window.innerHeight -
          document
            .getElementsByClassName(tableClass)[0]
            .getElementsByClassName(tableHeaderName)[0].scrollHeight -
          chartHeight
        );
      }
    }
    const getData3 = () => {
      const elChart3 = document.getElementById("mychart3");
      if (state.activeName == "third") {
        // 企业类型
        return ipark_servicepark_enterprisetypeList().then((array) => {
          state.echart3 = array.data.chat;
          state.tableHeight3 = Number(
            tableHeightNum("table3", "el-table__header", 230)
          );
          if (!elChart3) return;
          echarts.init(elChart3).resize();
          echarts.init(elChart3).setOption(optionPIE(state.echart3, "echart3"));
          return {
            data: array.data.rows,
            total: array.data.totalRows,
          };
        });
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    };
    const getData4 = (param: Iparam) => {
      const elChart4 = document.getElementById("mychart4");
      if (state.activeName == "fourth") {
        return ipark_servicepark_notaxEnterprise({
          condition: state.condition,
          page: param.index,
          per_page: param.size,
        }).then((obj) => {
          state.echart4 = obj.data.chat;
          state.tableHeight4 = Number(
            tableHeightNum("table4", "el-table__header", 260)
          );
          if (!elChart4) return;
          echarts.init(elChart4).resize();
          echarts.init(elChart4).setOption(optionPIE(state.echart4, "echart4"));
          return {
            data: obj.data.rows,
            total: obj.data.totalRows,
          };
        });
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    };
    const changeGroup = () => {
      fourthTable.value?.reset();
    };
    onMounted(() => {
      // 经营行业
      ipark_dic_industrycategoryList().then((array) => {
        let option1: { value: any; label: any }[] = [];
        let option2: { value: any; label: any }[] = [];
        let resulte = [];
        array.data.freqList.map((obj: any) => {
          option1.push({
            value: obj.industryCode,
            label: obj.industryName,
          });
        });
        resulte.push({
          label: "------常用行业------",
          options: option1,
        });
        array.data.totalList.map((obj: any) => {
          option2.push({
            value: obj.industryCode,
            label: obj.industryName,
          });
        });
        resulte.push({
          label: "------所有行业------",
          options: option2 ? option2 : [],
        });
        state.industryType = resulte;
      });

      // 企业类型
      ipark_servicepark_enterprisetypeList().then((array) => {
        state.tableThird = array.data.rows;
        array.data.rows.map((obj: any) => {
          state.enterpriseType.push({
            value: obj.enterpriseTypeCode,
            label: obj.enterpriseTypeName,
          });
        });
      });

      window.addEventListener("resize", () => {
        const elChart2 = document.getElementById("mychart2");
        const elChart3 = document.getElementById("mychart3");
        const elChart4 = document.getElementById("mychart4");
        switch (state.activeName) {
          case "first":
            state.tableHeight1 = Number(
              tableHeightNum("table1", "data-table__header", 315)
            );
            break;
          case "second":
            state.tableHeight2 = Number(
              tableHeightNum("table2", "el-table__header", 400)
            );
            if (!elChart2) return;
            echarts.init(elChart2).resize();
            break;
          case "third":
            state.tableHeight3 = Number(
              tableHeightNum("table3", "el-table__header", 230)
            );
            if (!elChart3) return;
            echarts.init(elChart3).resize();
            break;
          case "fourth":
            state.tableHeight4 = Number(
              tableHeightNum("table4", "el-table__header", 260)
            );
            if (!elChart4) return;
            echarts.init(elChart4).resize();
            break;
          default:
            break;
        }
      });
    });
    return {
      state,
      formInline,
      autoInput0,
      firstTable,
      secondTable,
      thirdTable,
      fourthTable,
      dialogThirdCompany,
      dialogCompanyImg,
      // businesslicenseInner,
      onSearch,
      getData1,
      getData2,
      getData3,
      getData4,
      tableHeightNum,
      changeGroup,
      handleClick,
      remoteMethod,
      download() {
        return ipark_servicepark_enterpriseList_download({
          enterpriseTypeCode: formInline.enterpriseTypeCode
            ? formInline.enterpriseTypeCode
            : "",
          industryCode: formInline.value ? formInline.value : "",
          operateStatus: formInline.operateStatus
            ? formInline.operateStatus
            : "",
          beginDate:
            formInline.formDate.length > 0 ? formInline.formDate[0] : "",
          endDate: formInline.formDate.length > 0 ? formInline.formDate[1] : "",
          keyword: state.searchKeyWord ? state.searchKeyWord : "",
        }).then(({ data, msg }) => {
          FileSaver.saveAs(data, msg);
        });
      },
    };
  },
});
