
import { defineComponent } from "vue";
// import { useRouter } from "vue-router";
import errGif from "@/assets/401_images/401.gif";
import suibiankan from "@/assets/401_images/007ef517-bafd-4066-aae4-6883632d9646.gif";

export default defineComponent({
  name: "page401",
  // setup(props, { emit }) {
  //   const router = useRouter();
  //   return {
  //     goBack() {
  //       router.back();
  //     },
  //   };
  // },
  data() {
    return {
      errGif,
      suibiankan,
      dialogVisible: false,
      homeURL: "/",
    };
  },
  methods: {
    back() {
      if (this.$route.query.noGoBack) {
        this.$router.push({ path: "/" });
      } else {
        this.$router.go(-1);
      }
    },
  },
});
