
import { defineComponent, reactive, ref, onMounted, computed } from "vue";
import { ElForm } from "element-plus";
import FileSaver from "file-saver";
import dayjs from "dayjs";
import Page from "@/components/Page/Index.vue";
import DataTable from "@/components/DataTable/Index.vue";
import FileSelect from "@/components/FileSelect/Index.vue";
import ModifyBusinesslicense from "@/components/ModifyBusinessLicense/Index.vue";
import { getNewFormData } from "@/components/ModifyBusinessLicense/utils";
import ImageView from "@/components/ImageView/Index.vue";
import { Iparam } from "@/components/DataTable/utils";
import { AnyObject, isAbsHttp } from "@/utils";
import {
  ipark_servicepark_cancelEnterpriseList,
  ipark_dic_enterprisetypeList,
  ipark_dic_industrycategoryList,
  ipark_servicepark_enterpriseRegistInfo,
  ipark_servicepark_addCancelEnterprise,
  servicepark_enterpriseSearch,
  ipark_servicepark_cancelEnterpriseList_download,
} from "@/api";
import { rsHost } from "@/api/utils";

const initials = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"];

export default defineComponent({
  components: {
    Page,
    FileSelect,
    ImageView,
    DataTable,
    ModifyBusinesslicense,
  },
  setup() {
    const state = reactive({
      dataFilter: {
        keyword: "",
        file: "",
        enterpriseType: "",
        enterpriseBusiness: "",
        enterpriseState: "",
        enterpriseRegistrationDate: [],
      },
      options: Array.from({ length: 100 }).map((_, idx) => ({
        value: `Option ${idx + 1}`,
        label: `${initials[idx % 10]}${idx}`,
      })),
      enterprises: [] as AnyObject[],
      enterprisesType: [] as AnyObject[],
      enterprisesBusiness: {
        freqList: [
          { industryCode: "code", industryName: "name" },
        ] as AnyObject[],
        totalList: [
          { industryCode: "code", industryName: "name" },
        ] as AnyObject[],
      },
      form: {
        operateStatus: "X",
        taxpayerId: "",
        cancelDate: "",
        file: "" as any,
      },
      detail: getNewFormData(),
      title: "营业执照",
      showImage: false,
      image: "",
      show: false,
      loading: false,
      enterpriseloading: false,
      showDetail: false,
    });
    onMounted(() => {
      Promise.all([
        ipark_dic_enterprisetypeList(),
        ipark_dic_industrycategoryList(),
      ]).then(([enterprisetype, industrycategory]) => {
        state.enterprisesType = enterprisetype.data.result;
        state.enterprisesBusiness = industrycategory.data;
      });
    });
    const table = ref<typeof DataTable | null>(null);
    const form = ref<typeof ElForm | null>(null);
    const registionDate = computed(() => {
      const date = state.enterprises.filter(
        (val) => val.taxpayerId === state.form.taxpayerId
      )[0];
      return date
        ? dayjs(date.registionDate, "YYYY-MM-DD").toDate()
        : dayjs().subtract(1, "year").toDate();
    });
    return {
      state,
      table,
      form,
      rules: computed(() => {
        return {
          operateStatus: [
            {
              required: true,
              message: "请选择处理方式",
              trigger: "change",
            },
          ],
          taxpayerId: [
            {
              required: true,
              message: "请选择企业",
              trigger: "change",
            },
          ],
          cancelDate: [
            {
              required: true,
              message: "请选择日期",
              trigger: "change",
            },
          ],
          // file: [
          //   {
          //     required: state.form.reportType === "1" ? rule.onlyFile : false,
          //     validator: function (
          //       rule: InternalRuleItem,
          //       value: File | string,
          //       callback: (arg?: Error) => void
          //     ) {
          //       if (!rule.required) {
          //         return callback();
          //       }
          //       if (value) {
          //         if (typeof value === "string") return callback();
          //         const result = /\.[\w]*$/.exec(value.name);
          //         if (
          //           result &&
          //           ".pdf".toLowerCase().indexOf(result[0].toLowerCase()) !== -1
          //         ) {
          //           callback();
          //         } else {
          //           callback(new Error("请选择PDF格式文件"));
          //         }
          //       } else {
          //         callback(new Error("请选择文件"));
          //       }
          //     },
          //     trigger: "change",
          //   },
          // ],
        };
      }),
      onAdd() {
        state.show = true;
      },
      onSeach() {
        table.value?.reset();
      },
      disabledDate(time: Date) {
        return time.getTime() <= registionDate.value.getTime();
      },
      remoteMethod(keyword: string) {
        state.enterpriseloading = true;
        servicepark_enterpriseSearch({
          keyword,
        })
          .then(({ data }) => {
            state.enterprises = data;
          })
          .finally(() => {
            state.enterpriseloading = false;
          });
      },
      onDetail(row: AnyObject) {
        state.title = row.taxpayerName;
        ipark_servicepark_enterpriseRegistInfo({
          taxpayerId: row.taxpayerId,
        }).then(({ data }) => {
          state.detail = getNewFormData({
            enterprisefile: isAbsHttp(data.licenseFilestore)
              ? data.licenseFilestore
              : rsHost + data.licenseFilestore,
            enterprisename: data.taxpayerName,
            enterprisecode: data.taxpayerId,
            enterprisetype: data.enterpriseTypeCode,
            enterprisebusiness: data.industryCode,
            enterprisescope: data.activitiesScope,
            enterpriseaddress: data.registAddress,
            enterpriseregistrationDate: data.registionDate,
            establishmentDate: data.establishmentDate,
            legalpersonName: data.legalRepresentativeName,
            legalpersonNo: data.legalRepresentativeIdNo,
            legalpersonMobile: data.legalRepresentativeMobile,
            contactsName: data.enterpriseLiaisonName,
            contactsMobile: data.enterpriseLiaisonMobile,
            attractpartner: data.partnerId,
            parkaccesser: data.serviceParkLiaisonMobile,
          });
          state.showDetail = true;
        });
      },
      onShowImage(row: AnyObject) {
        state.image = row.licenseFilestore;
        state.showImage = true;
      },
      onSubmit() {
        form.value?.validate().then(() => {
          state.loading = true;
          return ipark_servicepark_addCancelEnterprise({
            taxpayerId: state.form.taxpayerId,
            operateStatus: state.form.operateStatus,
            cancelDate: state.form.cancelDate,
            file: state.form.file instanceof File ? state.form.file : null,
          })
            .then(() => {
              state.show = false;
              table.value?.reset();
            })
            .finally(() => {
              state.loading = false;
            });
        });
      },
      onDownload() {
        const date = state.dataFilter.enterpriseRegistrationDate;
        return ipark_servicepark_cancelEnterpriseList_download({
          enterpriseTypeCode: state.dataFilter.enterpriseType,
          industryCode: state.dataFilter.enterpriseBusiness,
          beginDate:
            date && Array.isArray(date) && date.length > 1 ? date[0] : "",
          endDate:
            date && Array.isArray(date) && date.length > 1 ? date[1] : "",
          keyword: state.dataFilter.keyword,
        }).then(({ data, msg }) => {
          FileSaver.saveAs(data, msg);
        });
      },
      getData(param: Iparam) {
        const date = state.dataFilter.enterpriseRegistrationDate;
        return ipark_servicepark_cancelEnterpriseList({
          keyword: state.dataFilter.keyword,
          beginDate:
            date && Array.isArray(date) && date.length > 1 ? date[0] : "",
          endDate:
            date && Array.isArray(date) && date.length > 1 ? date[1] : "",
          industryCode: state.dataFilter.enterpriseBusiness,
          enterpriseTypeCode: state.dataFilter.enterpriseType,
          page: param.index,
          per_page: param.size,
        }).then(({ data }) => {
          return {
            data: data.rows,
            total: data.totalRows,
          };
        });
      },
    };
  },
});
