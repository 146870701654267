
import { defineComponent, provide } from "vue";
import {
  uias_getUser,
  uias_switchToCorp,
  uias_getOrgUserFunctionList,
} from "@/api/index";
import { AnyObject } from "./utils";
import { loginKey } from "./const";

export default defineComponent({
  setup() {
    function loginOrg(): Promise<any> {
      return uias_switchToCorp({ orgId: sessionStorage.activeOrg }).then(() => {
        return Promise.all([
          uias_getOrgUserFunctionList({
            appid: "com.hive.ipark",
            terminal: "PC",
          }),
          uias_getUser(),
        ]).then(([{ data }, { data: userInfo }]) => {
          const { functionList } = data;
          // state.personal = userInfo.userInfo;
          sessionStorage.homeCard = JSON.stringify(
            functionList.filter((val: AnyObject) => val.displayStyle === "Card")
          );
          sessionStorage.menu = JSON.stringify(
            functionList.filter((val: AnyObject) => val.displayStyle === "Menu")
          );
          window.dispatchEvent(new Event("buildmenu"));
          return userInfo.userInfo;
        });
      });
    }
    provide(loginKey, loginOrg);
  },
});
