
import { defineComponent } from "vue";
import { Picture } from "@element-plus/icons-vue";

export default defineComponent({
  components: {
    Picture,
  },
  props: {
    cover: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    summary: {
      type: String,
      default: "",
    },
  },
});
